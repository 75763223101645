import classNames from 'classnames';

import styles from './OverlaySpinner.module.scss';

type Props = {
  className?: string;
  enabled: boolean;
  message?: string;
};

export function OverlaySpinner(props: Props) {
  const { className, enabled, message } = props;

  return (
    <div className={classNames(styles.root, { [styles.enabled]: enabled }, className)}>
      <div className={styles.spinner}>
        <div className={styles.bounce1} />
        <div className={styles.bounce2} />
        <div className={styles.bounce3} />
      </div>
      {message && <div className={styles.text}>{message}</div>}
    </div>
  );
}
