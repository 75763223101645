import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';

import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { ValidationError } from '../ValidationError/ValidationError';
import { Suburb, ValidationErrors } from '../../types';
import * as api from '../../services/api';
import styles from './AddressInput.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  enableLookup?: boolean;
  errors: ValidationErrors;
  hasBorder?: boolean;
  icon?: string;
  inverted?: boolean;
  label?: React.ReactNode;
  layout?: 'vertical' | 'horizontal';
  min?: number;
  name: string;
  onPostcodeChange: (value: string) => void;
  onStateChange: (value: string) => void;
  onSuburbChange: (value: string) => void;
  placeholder?: string;
  postcode?: string;
  size?: 'regular' | 'large';
  state?: string;
  suburb?: string;
  id?: string;
  type?: 'text' | 'number';
}

const geoOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function getByType(
  addressComponents: { long_name: string; short_name: string; types: string[] }[],
  type: string
): string | null {
  const component = addressComponents.find((addressComponent) => {
    return addressComponent.types.includes(type);
  });

  if (component) {
    return component.long_name;
  } else {
    return null;
  }
}

function labelFromSuburb(suburb: Suburb) {
  return `${suburb.name}, ${suburb.postcode} (${suburb.state})`;
}

export function AddressInput(props: Props) {
  const {
    className,
    enableLookup = false,
    errors,
    icon,
    inverted = false,
    hasBorder = true,
    label = '',
    layout = 'vertical',
    name,
    onPostcodeChange,
    onStateChange,
    onSuburbChange,
    placeholder,
    postcode = '',
    suburb = '',
    state = '',
    id,
  } = props;

  const [dirty, setDirty] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');
  const [data, setData] = useState<Suburb[]>([]);
  const [defaultData, setDefaultData] = useState<Suburb[]>([]);
  const [selectedSuburb, setSelectedSuburb] = useState<Suburb | null>(null);

  function useCurrentLocation() {
    function success(pos: any) {
      const { latitude: lat, longitude: lng } = pos.coords;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const { address_components } = results[0];
          const suburb =
            getByType(address_components, 'locality') ??
            getByType(address_components, 'administrative_area_level_2') ??
            '';

          setFocus(true);
          setValue(suburb.toUpperCase());
        }
      });
    }
    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}. This is fine, its optional to enable`);
    }
    navigator.geolocation.getCurrentPosition(success, error, geoOptions);
  }

  useEffect(() => {
    if (suburb?.length > 0 && postcode?.length > 0 && state?.length > 0) {
      setSelectedSuburb({ name: suburb, postcode, state });
    }
  }, [suburb, postcode, state, setSelectedSuburb]);

 

  useDebounce(
    async () => {
      if (!focus || value.trim().length === 0) {
        return; // avoid triggering on first render || let default data fill in
      }
      try {
        //Fetch suburbs from local storage
        const suburbs = localStorage.getItem("suburbs");
        const parsedSuburbs = suburbs ? JSON.parse(suburbs) || "" : "";
        const results = [];
        const regex = new RegExp(value.trim(), 'i'); // 'i' flag for case-insensitive matching

        for (const record of parsedSuburbs) {
          for (const key in record) {
            if (typeof record[key] === 'string' && regex.test(record[key])) {
              results.push(record);
            }
          }
        }
        setData(results);
      } catch (e) {}
    },
    300,
    [value, focus]
  );

  const onFocus = () => {
    setSelectedSuburb(null);
    setValue('');
    onPostcodeChange('');
    onStateChange('');
    onSuburbChange('');
    setDirty(true);
    setFocus(true);
    setData(defaultData);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const handleSelect = async (suburb: Suburb) => {
    (document.activeElement as any)?.blur(); // a bit cheeky but saves setting up forwarding refs...
    setFocus(false);
    onPostcodeChange(suburb.postcode);
    onStateChange(suburb.state);
    onSuburbChange(suburb.name);
    setSelectedSuburb(suburb);
    setValue('');
    setData([]);
  };

  const onChange = (value: string) => {
    setData([]);
    setValue(value);
  };

  return (
    <div className={classNames(styles.root, { [styles.hasIcon]: icon }, styles[layout], className)} style={{marginTop: inverted === false ? '10px' : '0px'}}>
      <div className={styles.wrapper}>
        <Input
          value={selectedSuburb ? labelFromSuburb(selectedSuburb) : value}
          label={label}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          hasBorder={hasBorder}
          name={name}
          errors={{ [name]: !selectedSuburb && !focus && dirty ? ['Please select a suburb'] : [] }}
          className={styles.input}
          placeholder={placeholder}
          inputClassName={classNames({ [styles.inputOpen]: focus && data.length > 0 })}
          inverted={inverted}
          id={id}
        />
        {focus && data.length > 0 && (
          <ul className={classNames(styles.items, 'AddressInput__dropdown')}>
            {data.map((suburb, i) => (
              <li
                className={styles.item}
                key={i}
                onMouseDown={(e) => e.preventDefault()} // Prevent blur, onClick mis-order (!)
                onClick={() => handleSelect(suburb)}
              >
                {labelFromSuburb(suburb)}
              </li>
            ))}
          </ul>
        )}
      </div>
      {enableLookup && (
        <Button size="small" type="link" onClick={useCurrentLocation}>
          Use current location
        </Button>
      )}
      <ValidationError className={styles.error} errors={errors} name={name} />
    </div>
  );
}
