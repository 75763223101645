import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Alert } from '../../components/Alert/Alert';
import { H1 } from '../../components/H1/H1';
import { Header } from '../../components/Header/Header';
import { OrderSummary } from '../../components/OrderSummary/OrderSummary';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Status } from '../../types';
import { trackPurchase, track } from '../../util';
import { useStore } from '../../store';
import * as api from '../../services/api';
import styles from './Payment.module.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '../../components/Stripe/Stripe';
import { Modal, Button } from 'react-bootstrap';
import { MIXPANEL_TOKEN } from '../../config';
const mixpanel = require('mixpanel-browser');

mixpanel.init(MIXPANEL_TOKEN, { debug: false });
const buttons = (window as any).paypal.Buttons;
const PayPalButton = buttons.driver('react', { React, ReactDOM });
const paypalStyle = { shape: 'pill', height: 50, label: 'checkout' };
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
export function Payment() {
  const orderId = useStore((state) => state.orderId);
  const quotePrice = useStore((state) => state.quotePrice);
  const insuranceFee = useStore((state) => state.insuranceFee);
  const pickupEmail = useStore((state) => state.pickupEmail);
  const dropOffPOBox = useStore((state) => state.dropOffPOBox);
  const noPrinter = useStore((state) => state.noPrinter);
  const setCurrentStep = useStore((state) => state.setCurrentStep);
  const payPalPaymentGateway = useStore((state) => state.payPalPaymentGateway);
  const stripePaymentGateway = useStore((state) => state.stripePaymentGateway);
  const coinbasePaymentGateway = useStore((state) => state.coinbasePaymentGateway);
  const reCreateOrder = useStore((state) => state.reCreateOrder);
  const couponDiscountPercentage = useStore((state) => state.reCreateOrder);
  const emails = useStore((state) => state.emails);
  const quoteInsurance = useStore((state) => state.quoteInsurance);
  const courierServiceName = useStore((s) => s.courierServiceName);
  const pickupFirstName = useStore((state) => state.pickupFirstName);
  const pickupLastName = useStore((state) => state.pickupLastName);
  const pickupCompanyName = useStore((state) => state.pickupCompanyName);
  const pickupAddress1 = useStore((state) => state.pickupAddress1);
  const pickupAddress2 = useStore((state) => state.pickupAddress2);
  const pickupSuburb = useStore((state) => state.pickupSuburb);
  const pickupState = useStore((state) => state.pickupState);
  const pickupPostcode = useStore((state) => state.pickupPostcode);
  const pickupPhone = useStore((state) => state.pickupPhone);
  const pickupBuildingType = useStore((state) => state.pickupBuildingType);
  const destinationFirstName = useStore((state) => state.destinationFirstName);
  const destinationLastName = useStore((state) => state.destinationLastName);
  const destinationCompanyName = useStore((state) => state.destinationCompanyName);
  const destinationEmail = useStore((state) => state.destinationEmail);
  const destinationAddress1 = useStore((state) => state.destinationAddress1);
  const destinationAddress2 = useStore((state) => state.destinationAddress2);
  const destinationSuburb = useStore((state) => state.destinationSuburb);
  const destinationState = useStore((state) => state.destinationState);
  const destinationPostcode = useStore((state) => state.destinationPostcode);
  const destinationPhone = useStore((state) => state.destinationPhone);
  const destinationBuildingType = useStore((state) => state.destinationBuildingType);
  const collectionDate = useStore((state) => state.collectionDate);
  const pickupTimeWindow = useStore((state) => state.pickupTimeWindow);
  const parcelContent = useStore((state) => state.parcelContent);
  const insuranceValue = useStore((state) => state.insuranceValue);
  const specialInstructions = useStore((state) => state.specialInstructions);
  const authorityToLeave = useStore((state) => state.authorityToLeave);
  const quoteExcludingGstPrice = useStore((state) => state.quoteExcludingGstPrice);
  const items = useStore((state) => state.items);

  const history = useHistory();
  const [status, setStatus] = useState<Status>('idle');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<string[] | null>(null);
  const revenue: any = couponDiscountPercentage ?
    (
      (((quotePrice || 0) + (noPrinter ? 30 : 0) + Number(insuranceFee.replace('$', '')) - (Number(couponDiscountPercentage) / 100) * ((quotePrice || 0) + (noPrinter ? 30 : 0) + Number(insuranceFee.replace('$', '')))) * 0.1) + ((quotePrice || 0) + (noPrinter ? 30 : 0) + Number(insuranceFee.replace('$', '')) - (Number(couponDiscountPercentage) / 100) * ((quotePrice || 0) + (noPrinter ? 30 : 0) + Number(insuranceFee.replace('$', ''))))
    ).toFixed(2) :
    (
      (((quotePrice || 0) + (noPrinter ? 30 : 0) + Number(insuranceFee.replace('$', ''))) * 0.1) + (quotePrice || 0) + (noPrinter ? 30 : 0) + Number(insuranceFee.replace('$', ''))
    ).toFixed(2);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const setReCreateOrder = useStore((state) => state.setReCreateOrder);
  const setOrderId = useStore((state) => state.setOrderId);
  let stripeCheckout = {
    height: '50px',
    fontSize: '21px',
    background: '#0070BA',
    color: '#fff',
    font: "700 18px/1 'vagroundedstd', sans-serif",
  };
  let coinbaseCheckout = {
    height: '50px',
    fontSize: '21px',
    background: '#ECECEC',
    color: '#1a202c',
    font: "700 18px/1 'vagroundedstd', sans-serif",
  };

  const createOrder = useCallback(async () => {
    setStatus('in-progress');
    try {
      await api.csrf();
      const { paypalOrderId } = await api.patch(`/order/${orderId}/prepare-payment`, {});
      return paypalOrderId;
    } catch (e: any) {
      if (e?.response?.data?.type === 'COURIER_PRECHECK') {
        setErrorMessage(e?.response?.data?.message);
        setErrors(e?.response?.data?.errors);
      }
      setStatus('error');
    }
  }, [orderId]);

  const onApprove = useCallback(async () => {
    try {
      await api.csrf();
      const data = await api.patch<any>(`/order/${orderId}/1/payment`, {});
      // If the endpoint returns a non-null for email, we should pass through the email, first name and last name
      const newUser = data.email ? btoa(JSON.stringify(data)) : '';
      const paypalEmail = data.paypalEmail ? data.paypalEmail : '';
      const jobNumber = data.jobNumber ? data.jobNumber : '';
      const consignmentNumber = data.consignmentNumber ? data.consignmentNumber : '';
      const labelPath = data.labelPath ? data.labelPath : '';
      const invoicePath = data.invoicePath ? data.invoicePath : '';

      history.push(`/order-complete?ref=${orderId}&new=${newUser}&label=${labelPath}&invoice=${invoicePath}`);
      trackPurchase(orderId, revenue, paypalEmail, jobNumber, consignmentNumber, dropOffPOBox, noPrinter);
      let shipment_items: any = await setItemsForMixpanelPurchaseEvent(items);
      //tracking purchase on mixpanel
      mixpanel.track('Puchase', {
        'label': 'puchase',
        'platform_used': 'Website',
        'term_and_conditions': true,
        'payment_status': 'success',
        'payment_method': 'PayPal',
        'priceExcludingGst': quoteExcludingGstPrice,
        'priceIncludingGst': ((quotePrice || 0) + (quotePrice || 0) * 0.1).toFixed(2),
        'insuranceCategory': quoteInsurance,
        'courierServiceName': courierServiceName,
        'pickupAddress1': pickupAddress1,
        'pickupAddress2': pickupAddress2,
        'pickupFirstName': pickupFirstName,
        'pickupLastName': pickupLastName,
        'pickupEmail': pickupEmail,
        'pickupPhone': pickupPhone,
        'pickupSuburb': pickupSuburb,
        'pickupState': pickupState,
        'pickupPostcode': pickupPostcode,
        'pickupBuildingType': pickupBuildingType,
        'destinationAddress1': destinationAddress1,
        'destinationAddress2': destinationAddress2,
        'destinationFirstName': destinationFirstName,
        'destinationLastName': destinationLastName,
        'destinationEmail': destinationEmail,
        'destinationPhone': destinationPhone,
        'destinationSuburb': destinationSuburb,
        'destinationState': destinationState,
        'destinationPostcode': destinationPostcode,
        'destinationBuildingType': destinationBuildingType,
        'poBox': dropOffPOBox ? 'true' : 'false',
        'collectionDate': collectionDate,
        'pickupTimeWindow': pickupTimeWindow,
        'shipmentContents': parcelContent,
        'specialInstructions': specialInstructions,
        "insurance": insuranceValue,
        "authorityToLeave": authorityToLeave ? 'true' : 'false',
        "noLabels": noPrinter ? 'true' : 'false',
        'items': shipment_items,
        'additionalEmails': emails,
        order_id: orderId,
        currency: 'AUD',
        revenue: revenue,
        paypalEmail: paypalEmail,
        jobNumber: jobNumber,
        consignmentNumber: consignmentNumber,
      });
    } catch (e) {
      setStatus('error');
    }
  }, [history, orderId, revenue]);

  const onCancel = () => {
    setStatus('idle');
  };

  const onError = () => {
    setStatus('error');
    setReCreateOrder(true);
  };
  // handle stripe payment
  const handleStripePayment = (
    cardDetails: any,
    billing_details: any,
    stripe: any,
    elements: any,
    company_name: any
  ) => {
    let Modal: any = [];
    let Fade: any = [];
    let stripeError: any = [];
    let modalbody: any = [];
    Modal = document.getElementsByClassName('modal');
    Fade = document.getElementsByClassName('fade');
    stripeError = document.getElementsByClassName('stripe-error');

    if (stripeError[0]) {
      stripeError[0].parentNode.removeChild(stripeError[0]);
      modalbody = document.getElementsByClassName('modal-body');
      modalbody.item(0).style.paddingTop = '16px';

    }

    Modal.item(0).style.zIndex = '199';
    Fade.item(0).style.opacity = '0';

    setStatus('in-progress');

    (async () => {
      try {
        const cardElement = elements.getElement("card");

        await stripe.createToken(cardElement).then(function (result: any) {
          if (result.error) {

          } else {
            (async () => {
              try {
                await api.csrf();
                const data = await api.patch<any>(`/order/${orderId}/2/payment`, {
                  card: result.token.id,
                  email: billing_details.email,
                  name: billing_details.name,
                  company: company_name
                });
                if (!data || data.error) {
                  setStatus('error');
                  setReCreateOrder(true);
                } else {
                  // customer payment
                  pay(data.client_secret, billing_details, cardDetails, stripe, elements, company_name);
                }
              } catch (e) {
                setStatus('error');
                setReCreateOrder(true);
              }
            })();
          }
        });
      } catch (e) {
        setStatus('error');
        setReCreateOrder(true);
      }
    })();
  };

  // confirm payment
  const pay = async (
    clientSecret: any,
    billing_details: any,
    cardDetails: any,
    stripe: any,
    elements: any,
    company_name: any
  ) => {
    if (stripe && elements) {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardDetails,
          billing_details,
        },
      });

      if (payload.paymentIntent && payload.paymentIntent.status === 'succeeded') {
        try {
          await api.csrf();
          const response = await api.post<any>(`/order/${orderId}/2/paymentResponse`, {
            paymentResponse: payload.paymentIntent,
            company_name: company_name,
            email: billing_details.email
          });
          // If the endpoint returns a non-null for email, we should pass through the email, first name and last name
          const newUser = response.email ? btoa(JSON.stringify(response)) : '';
          const paypalEmail = response.paypalEmail ? response.paypalEmail : '';
          const jobNumber = response.jobNumber ? response.jobNumber : '';
          const consignmentNumber = response.consignmentNumber ? response.consignmentNumber : '';
          const labelPath = response.labelPath ? response.labelPath : '';
          const invoicePath = response.invoicePath ? response.invoicePath : '';

          history.push(`/order-complete?ref=${orderId}&new=${newUser}&label=${labelPath}&invoice=${invoicePath}&payment_type=2`);
          trackPurchase(orderId, revenue, paypalEmail, jobNumber, consignmentNumber, dropOffPOBox, noPrinter);
          let shipment_items: any = await setItemsForMixpanelPurchaseEvent(items);
          //tracking purchase on mixpanel
          mixpanel.track('Puchase', {
            'label': 'puchase',
            'platform_used': 'Website',
            'term_and_conditions': true,
            'payment_status': 'success',
            'payment_method': 'Stripe',
            'priceExcludingGst': quoteExcludingGstPrice,
            'priceIncludingGst': quotePrice,
            'insuranceCategory': quoteInsurance,
            'courierServiceName': courierServiceName,
            'pickupAddress1': pickupAddress1,
            'pickupAddress2': pickupAddress2,
            'pickupFirstName': pickupFirstName,
            'pickupLastName': pickupLastName,
            'pickupEmail': pickupEmail,
            'pickupPhone': pickupPhone,
            'pickupSuburb': pickupSuburb,
            'pickupState': pickupState,
            'pickupPostcode': pickupPostcode,
            'pickupBuildingType': pickupBuildingType,
            'destinationAddress1': destinationAddress1,
            'destinationAddress2': destinationAddress2,
            'destinationFirstName': destinationFirstName,
            'destinationLastName': destinationLastName,
            'destinationEmail': destinationEmail,
            'destinationPhone': destinationPhone,
            'destinationSuburb': destinationSuburb,
            'destinationState': destinationState,
            'destinationPostcode': destinationPostcode,
            'destinationBuildingType': destinationBuildingType,
            'poBox': dropOffPOBox ? 'true' : 'false',
            'collectionDate': collectionDate,
            'pickupTimeWindow': pickupTimeWindow,
            'shipmentContents': parcelContent,
            'specialInstructions': specialInstructions,
            "insurance": insuranceValue,
            "authorityToLeave": authorityToLeave ? 'true' : 'false',
            "noLabels": noPrinter ? 'true' : 'false',
            'items': shipment_items,
            'additionalEmails': emails,
            order_id: orderId,
            currency: 'AUD',
            revenue: revenue,
            paypalEmail: paypalEmail,
            jobNumber: jobNumber,
            consignmentNumber: consignmentNumber,
          });
        } catch (e) {
          setStatus('error');
        }
      } else if (payload.error) {
        setReCreateOrder(true);
        try {
          track('app-failed-payment', {
            failedPaymentEmail: billing_details.email,
            errorType: payload.error.code
          });
          await api.csrf();
          const response = await api.post<any>(`/order/${orderId}/2/paymentResponse`, {
            stripeError: true,
            message: payload.error.code,
            email: billing_details.email
          });

          if (payload.error.code) {
            let Modal: any = [];
            let Fade: any = [];
            Modal = document.getElementsByClassName('modal');
            Fade = document.getElementsByClassName('fade');

            let forms: any = [];
            let modalbody: any = [];
            let newDiv = document.createElement('span');
            forms = document.forms;
            modalbody = document.getElementsByClassName('modal-body');
            newDiv.className = `stripe-error`;
            newDiv.style.cssText = `color: #f76b00;font-size: 12px;margin-bottom:10px;`;
            newDiv.innerText = `
              ${payload.error.message}
            `;
            setStatus('idle');
            Modal.item(0).style.zIndex = '1060';
            Fade.item(0).style.opacity = '0.5';
            forms[0]!.parentNode.insertBefore(newDiv, forms[0]);
            forms[0][4].removeAttribute("disabled");
            modalbody.item(0).style.paddingTop = '0px';

          } else {
            setStatus('error');
            setReCreateOrder(true);
          }
        } catch (e) {
          setStatus('error');
          setReCreateOrder(true);
        }
      } else {
        setStatus('error');
        setReCreateOrder(true);
      }
    }
  };

  const handleCoinbasePopup = () => {
    setStatus('in-progress');
    (async () => {
      try {
        await api.csrf();
        const checkoutPageURL = await api.patch<any>(`/order/${orderId}/3/payment`, {});
        if (checkoutPageURL) {
          let coinbaseWindow = window.open(
            checkoutPageURL,
            '_blank',
            'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=400,width=500,height=800'
          );

          checkCoinbasePaymentStatus(coinbaseWindow);
        } else {
          setStatus('error');
          setReCreateOrder(true);
        }
      } catch (e) {
        setStatus('error');
        setReCreateOrder(true);
      }
    })();
  };

  const checkCoinbasePaymentStatus = async (coinbaseWindow: any) => {
    await api
      .patch<any>(`/order/${orderId}/4/payment`, {})
      .then((response) => {
        if (response.status == 1) {
          history.push(`/order-complete?coinbase=payment_initiated&currency=${response.currency}&ref=${orderId}`);
          return;
        } else if (coinbaseWindow.closed !== false && response.status == 1) {
          history.push(`/order-complete?coinbase=payment_initiated&currency=${response.currency}&ref=${orderId}`);
          return;
        } else if (coinbaseWindow.closed !== false && response.status == 0) {
          setStatus('idle');
          return;
        } else if (response.status == 0 && coinbaseWindow.closed == false) {
          checkCoinbasePaymentStatus(coinbaseWindow);
        }
      })
      .catch((error) => {
        coinbaseWindow.close();
        setStatus('error');
        setReCreateOrder(true);
      });
  };

  const setItemsForMixpanelPurchaseEvent = (items: any) => {
    let shipment_packages: any = [];
    items.map((item: any) => {
      shipment_packages.push({
        height: item.height.toString(),
        length: item.length.toString(),
        type: item.type.toString(),
        weight: item.weight.toString(),
        width: item.width.toString(),
        quantity: item.width.toString(),
      });
    });
    return shipment_packages;
  }

  useEffect(() => {
    if (!orderId) {
      history.push('/package-details');
      return;
    }

    setCurrentStep(4);
  }, [history, orderId, setCurrentStep]);

  useEffect(() => {
    return () => {
      buttons().close();
    };
  }, []);

  return (
    <>
      <Header />
      <OverlaySpinner enabled={status === 'in-progress'} />

      <OrderSummary
        className={styles.orderSummary}
        packageDetailsEditEnabled={status !== 'error'}
        quoteEditEnabled={status !== 'error'}
        addressDetailsEditEnabled={status !== 'error'}
      />
      <H1 alignment="center">Check out now</H1>
      {status === 'error' ? (
        errorMessage !== null ? (
          <Alert className={styles.alert} type="warning">
            <p>{errorMessage}</p>
            <ul>
              {errors?.map((message, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          <Alert className={styles.alert} type="error">
            <p>There was an error processing your order. We have notified our tech team of the issue.</p>
            <p>
              If you would like to contact our support team, please reference this number: <code>{orderId}</code>
            </p>
          </Alert>
        )
      ) : (
        <div className={styles.buttonWrapper}>
          <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Pay with Credit Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Elements stripe={stripePromise}>
                <Stripe handleStripePayment={handleStripePayment} />
              </Elements>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} className="border-0" style={{ backgroundColor: '#f9964b' }}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {stripePaymentGateway == true && (
            <>
              <div className={styles.stripeButtonDiv}>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button className="btn" type="button" style={stripeCheckout} onClick={handleShow}>
                    Pay with Debit or Credit Card
                  </button>
                </div>
              </div>
            </>
          )}
          {stripePaymentGateway == true && payPalPaymentGateway == true && (
            <div style={{ width: '100%' }}>
              <div className="text-center d-flex p-4 m-auto" style={{ width: '50%' }}>
                <hr className="flex-grow-1" />
                <span className="px-2 font-weight-lighter small align-self-center">or</span>
                <hr className="flex-grow-1" />
              </div>
            </div>
          )}

          {payPalPaymentGateway == true && (
            <>
              <div className={styles.stripeParentDiv}>
                <div className={styles.stripeChildDiv}>
                  <PayPalButton
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                    onCancel={onCancel}
                    style={paypalStyle}
                  />
                </div>
              </div>
            </>
          )}
          {(payPalPaymentGateway == true && coinbasePaymentGateway == true) ||
            (stripePaymentGateway == true && coinbasePaymentGateway == true) ? (
            <div style={{ width: '100%' }}>
              <div className="text-center d-flex p-4 m-auto" style={{ width: '50%' }}>
                <hr className="flex-grow-1" />
                <span className="px-2 font-weight-lighter small align-self-center">or</span>
                <hr className="flex-grow-1" />
              </div>
            </div>
          ) : (
            ''
          )}

          {coinbasePaymentGateway == true && (
            <>
              <div className={styles.stripeButtonDiv}>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button className="btn" type="button" style={coinbaseCheckout} onClick={handleCoinbasePopup}>
                    Pay with Crypto{' '}
                    <img
                      src="https://assets.coingecko.com/coins/images/1/small/bitcoin.png"
                      alt="btc-logo"
                      height="28"
                      width="28"
                    />
                    <img
                      src="https://assets.coingecko.com/coins/images/279/small/ethereum.png"
                      alt="ethereum-logo"
                      height="28"
                      width="28"
                    />
                    <img
                      src="https://assets.coingecko.com/coins/images/5/small/dogecoin.png"
                      alt="dogecoin-logo"
                      height="28"
                      width="28"
                    />{' '}
                    <img
                      src="https://assets.coingecko.com/coins/images/2/small/litecoin.png"
                      alt="litecoin-logo"
                      height="28"
                      width="28"
                    />{' '}
                    <img
                      src="https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png"
                      alt="usdcoin-logo"
                      height="28"
                      width="28"
                    />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
