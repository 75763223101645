import classNames from 'classnames';
import React from 'react';
import { ValidationErrors } from '../../types';
import styles from './InputGroup.module.scss';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState } from 'react';
import { useStore } from '../../store';

interface Props<T> {
  className?: string;
  disabled?: boolean;
  errors: ValidationErrors;
  icon?: string;
  inputClassName?: string;
  inverted?: boolean;
  hasBorder?: boolean;
  label?: React.ReactNode;
  layout?: 'vertical' | 'horizontal';
  min?: number;
  max?: number;
  name: string;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: number, calculatedValue: number) => void;
  placeholder?: string;
  required?: boolean;
  size?: 'regular' | 'large';
  type?: 'number';
  value: T;
  calculatedValue: number;
}

export function CustomInputGroup<T extends string | number = string>(props: Props<T>) {
  const {
    className,
    disabled = false,
    errors,
    icon,
    inputClassName,
    inverted = false,
    hasBorder = true,
    label = '',
    layout = 'vertical',
    min,
    max,
    name,
    maxLength,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    placeholder = '',
    required = false,
    size = 'regular',
    type = 'number',
    value,
    calculatedValue,
  } = props;
  
  const [valueText, setValueText] = useState((props.calculatedValue === props.value ? ''+props.calculatedValue : ''+props.value));
  const [valueType, setValueType] = useState((props.calculatedValue === props.value ? 'cm' : 'mm'));
  const showPackageDetailsModal = useStore((state) => state.showPackageDetailsModal);

  const handleShow = (title:any) => {
    setValueText(title);
  };

  return (
    <div
        className={classNames(
        styles.root,
        { [styles.hasIcon]: icon, [styles.inverted]: inverted, [styles.hasBorder]: hasBorder },
        styles[size],
        styles[layout],
        className
      )}
    >

        <Form.Label htmlFor="basic-url" className={styles.formLabel}>{label}</Form.Label>
        <InputGroup className={styles.formGroup} >
          <Form.Control
            className={styles.formControl}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={({ currentTarget }) => {
              let value:any;
              let calculatedValue:any;
              if(currentTarget.value === ""){
                value = "";
                calculatedValue = "";
              }else{
                value = Number(currentTarget.value);
                calculatedValue = Number(currentTarget.value);
              }
              
              if(value < 1 && value !== ""){
                value = 1;
              }
              setValueText(value.toString());
              if (valueType === 'mm') {
                calculatedValue = Math.round(calculatedValue / 10);
              }
              onChange(value, calculatedValue);
            }}
            placeholder={placeholder}
            value={value === "" ? "" : value}
            style={{marginTop: placeholder === 'Height' && showPackageDetailsModal ? '5px' : '0px'}}
          />

          <select aria-label="select"
            className={styles.formSelect}
            onChange={({ currentTarget }) => {
              let value = Number(valueText) ?? 1;
              let calculatedValue = Number(valueText) ?? 1;
              if (currentTarget.value === 'mm') {
                calculatedValue = Math.round(Number(valueText) / 10);
                setValueType('mm')
              }else{
                setValueType('cm')
              }
              onChange(value, calculatedValue);
            }}
            style={{marginTop: placeholder === 'Height' && showPackageDetailsModal ? '5px' : '0px'}}>
            <option value="cm" selected={valueType === "cm"}>cm</option>
            <option value="mm" selected={valueType === "mm"}>mm</option>
          </select>
        </InputGroup>
    </div>
  );
}
