import React, { MouseEvent, useEffect, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { H2 } from '../../components/H2/H2';
import { Card } from '../../components/Card/Card';
import { Input } from '../../components/Input/Input';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Text } from '../../components/Text/Text';
import { Panel } from '../../components/Panel/Panel';
import styles from './Rebook.module.scss';
import * as api from '../../services/api';
import { SenderTypes, RebookStatus, ValidationErrors } from '../../types';
import classNames from 'classnames';
import { Form, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import {
  DEFAULT_ADDRESS_CHARACTER_LIMIT,
  COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT,
  STAR_TRACK_ADDRESS_CHARACTER_LIMIT,
  TNT_ADDRESS_CHARACTER_LIMIT,
  TOLL_ADDRESS_CHARACTER_LIMIT,
  ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT,
  TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT,
  BONDS_COURIER_ADDRESS_CHARACTER_LIMIT
} from '../../config';
import Swal from 'sweetalert2';

type Props = {
  className?: string;
};

export function Rebook(props: Props) {
  const { className } = props;
  const [errors, setErrors] = useState<ValidationErrors>({});
  const codeParam = new URLSearchParams(document.location.search).get('code');
  const [codeValue, setCodeValue] = useState<string>('');
  const [postCodeValue, setPostCodeValue] = useState<string>('');
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [jobNumber, setJobNumber] = useState<string>('');
  const [courier, setCourier] = useState<string>('');
  const currentDay = new Date().getDay();

  const [pickupAddress1, setPickupAddress1] = useState<string>('');
  const [pickupAddress2, setPickupAddress2] = useState<string>('');
  const [pickupSuburb, setPickupSuburb] = useState<string>('');
  const [pickupState, setPickupState] = useState<string>('');
  const [pickupPostcode, setPickupPostcode] = useState<string>('');
  const [destinationAddress1, setDestinationAddress1] = useState<string>('');
  const [destinationAddress2, setDestinationAddress2] = useState<string>('');
  const [destinationSuburb, setDestinationSuburb] = useState<string>('');
  const [destinationState, setDestinationState] = useState<string>('');
  const [destinationPostcode, setDestinationPostcode] = useState<string>('');
  const [pickupGoogleAddressSelected, setPickupGoogleAddressSelected] = useState(false);
  const [destinationGoogleAddressSelected, setDestinationGoogleAddressSelected] = useState(false);
  const [pickupStreetName, setPickupStreetName] = useState('');
  const [pickupStreetNumber, setPickupStreetNumber] = useState('');
  const [destinationStreetName, setDestinationStreetName] = useState('');
  const [destinationStreetNumber, setDestinationStreetNumber] = useState('');
  const [collectionDate, setCollectionDate] = useState<string>('');
  const [apiMessage, setApiMessage] = useState<string>('');
  const [collectionMinDate, setCollectionMinDate] = useState('');
  const [publicHolidays, setPublicHolidays] = useState(['']);

  const getOrder = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setStatus('in-progress');
    try {
      const response = await api.get<any>(`/order/${codeValue}/${postCodeValue}`);
      if(response.status){
        await getPublicHolidays();
        setStatus('found');
        setApiMessage('');
        setCollectionDate('');
        setCourier(response.data.courier);
        setPickupSuburb(response.data.pickup_suburb);
        setPickupState(response.data.pickup_state);
        setPickupPostcode(response.data.pickup_postcode);
        if(response.data.courier === "Zoom2u"){
          setPickupAddress1('');
          setPickupAddress2('');
          setDestinationAddress1('');
          setDestinationAddress2('');
        }else{
          setPickupAddress1(response.data.pickup_address_1);
          setPickupAddress2(response.data.pickup_address_2);
          setDestinationAddress1(response.data.destination_address_1);
          setDestinationAddress2(response.data.destination_address_2);
        }
        setDestinationSuburb(response.data.destination_suburb);
        setDestinationState(response.data.destination_state);
        setDestinationPostcode(response.data.destination_postcode);
      }else{
        setStatus('not-found');
        setApiMessage(response.message);
      }
    } catch (e) {
      setStatus('error');
    }
  };

  const getPublicHolidays = async () => {
    try {
      const publicHolidaysList = await api.get<any>('/public-holidays');
      if(publicHolidaysList.status){
        setPublicHolidays(publicHolidaysList.data);
      }
    } catch (e) {
      setStatus('error');
    }
  };

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if(collectionDate){
      if((pickupAddress1 !== "" && destinationAddress1 !== "") && (courier === 'Zoom2u') && (!pickupGoogleAddressSelected || !destinationGoogleAddressSelected)){
        let errorText = "";
          if (!pickupGoogleAddressSelected) {
            errorText = errorText + "Pickup ";
          }
          if (!pickupGoogleAddressSelected && !destinationGoogleAddressSelected) {
            errorText = errorText + " and Destination ";
          } else if (!destinationGoogleAddressSelected) {
            errorText = errorText + "Destination ";
          }
        Swal.fire({
          title: 'Warning',
          text: errorText + "address is invalid. Please select from google suggestion addresses.",
          icon: 'warning',
          confirmButtonColor: '#f76b00',
          confirmButtonText: 'OK'
        })
      }else{
        setStatus('in-progress');
      try {
        await api.csrf();
        const response = await api.post<any>(`/order/${codeValue}/rebookCollection`, {
          collectionDate: collectionDate,
          destinationAddress1: destinationAddress1,
          destinationAddress2: destinationAddress2,
          destinationStreetName: courier === "Zoom2u" ? destinationStreetName : null,
          destinationStreetNumber: courier === "Zoom2u" ? destinationStreetNumber : null,
          pickupAddress1: pickupAddress1,
          pickupAddress2: pickupAddress2,
          pickupStreetName: courier === "Zoom2u" ? pickupStreetName : null,
          pickupStreetNumber: courier === "Zoom2u" ? pickupStreetNumber : null,
          status: 'rebooked_for_collection',
          order_request_timezone: moment.tz.guess(),
          remarks: "Rebook Order",
          from: "website",
          created_by: 0
        });
        if(response.status){
          setStatus('completed');
          if (courier === "Zoom2u" || courier === "Sendle") {
              setJobNumber(response.response.data);
          } else if (courier === 'MRL Global') {
            setJobNumber(response.response.job_number);
          } else {
              setJobNumber(response.response);
          }
          setCodeValue('');
          setPostCodeValue('');
        }else{
          setStatus('idle');
          setApiMessage(response.message);
        }
      } catch (e) {
        setStatus('error');
      }
      }
    }
  };

  const checkSelectedDateIsWeekend = (value: any) => {
    var tollMaxDate = new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000);
    var givenDate = new Date(value);
    var month = '' + (givenDate.getMonth() + 1);
    var day = '' + givenDate.getDate();
    var year = givenDate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    var date = [year, month, day].join('-');
    var selectedDay = givenDate.getDay();

    if (selectedDay === 6 || selectedDay === 0) {
      setErrors({ collectionDate: ['Weekend date is not allowed.'] });
      return;
    } else if (publicHolidays.includes(date)) {
      setErrors({ collectionDate: ['Public holiday date is not allowed.'] });
      return;
    } else if((courier === 'Toll' || courier === 'Toll Palletised Express') && (tollMaxDate < givenDate)){
      setErrors({ collectionDate: ['Date should not be greater than 7 days.'] });
      return;
    } else if (moment(value).isBefore(moment(collectionMinDate))){
      setErrors({ collectionDate: ['Date should not be less than '+collectionMinDate] });
      return;
    }else {
      setErrors({});
    }
    setCollectionDate(value);
  };

  const getTodaydate = () => {
    if(courier === ""){
      return;
    }
    var quotePickupCutOffTime =  "";
    if(courier === 'Toll'){
        quotePickupCutOffTime = '14:30';
    }

    if(courier === 'Star Track'){
        quotePickupCutOffTime = '12:00';
    }

    if(courier === 'Couriers Please'){
        quotePickupCutOffTime = '15:59';
    }

    if(courier === 'TNT'){
        quotePickupCutOffTime = '17:00';
    }

    if(courier === 'Toll Palletised Express' || courier === 'Direct Couriers' || courier === 'Bonds Couriers' || courier === 'Josies Transport' || courier === 'Capital Transport'){
        quotePickupCutOffTime = '14:00';
    }

    if(courier === 'Northline' || courier === 'CTI Logistics Regional Freight'){
      quotePickupCutOffTime = '13:00';
    }

    if(courier === 'Felix Transport'){
      quotePickupCutOffTime = '15:00';
    }

    if((courier === 'Toll' || courier === 'Star Track' || courier === 'Couriers Please' || courier === 'TNT' || courier === 'Toll Palletised Express' || courier === 'Direct Couriers' || courier === 'Bonds Couriers' || courier === 'Zoom2u' || courier === 'Northline' || courier === 'Josies Transport' || courier === 'CTI Logistics Regional Freight' || courier === 'Felix Transport') && (new Date().getDay() !==6 && new Date().getDay() !==0) && (!moment().isAfter(moment(quotePickupCutOffTime,'HH:mm')))){
      today =  new Date(new Date().getTime());
    }else if(new Date().getDay() == 5){
        var today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 3);
    }else if(new Date().getDay() == 6){
        var today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 2);
    }else{
        var today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    }
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + '-' + mm + '-' + dd;
    if(!collectionMinDate){
      setCollectionMinDate(date);
    }
    return date;
  };

  //set address fields length value
  const setMaxLengthOfAddress = () => {
    return courier === 'TNT'
      ? TNT_ADDRESS_CHARACTER_LIMIT
      : courier === 'Toll'
      ? TOLL_ADDRESS_CHARACTER_LIMIT
      : courier === 'Star Track'
      ? STAR_TRACK_ADDRESS_CHARACTER_LIMIT
      : courier === 'Couriers Please'
      ? COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT
      : courier === 'AlliedExpress'
      ? ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT
      : courier === 'Toll Palletised Express'
      ? TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT
      : courier === 'Bonds Couriers'
      ? BONDS_COURIER_ADDRESS_CHARACTER_LIMIT
      : DEFAULT_ADDRESS_CHARACTER_LIMIT;
  };

  async function validateAddress(addressType: string) {
    if (addressType === 'pickup') {
      setPickupGoogleAddressSelected(false);
    } else {
      setDestinationGoogleAddressSelected(false);
    }
    var options = {
      componentRestrictions: { country: "au" }
    };
    var input = document.getElementById(addressType + 'Address1') as HTMLInputElement;
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener("place_changed", () => {
      var place = autocomplete.getPlace();
      const { formatted_address = '' } = place || {};
      var components = place.address_components;
      if (addressType === 'pickup') {
        setPickupAddress1(formatted_address);
      } else {
        setDestinationAddress1(formatted_address);
      }

      if (components) {
        let invalidSuburb = false;
        let invalidPostcode = false;
        let suburb = addressType === 'pickup' ? pickupSuburb : destinationSuburb;
        let postcode = addressType === 'pickup' ? pickupPostcode : destinationPostcode;
        for (var i = 0; i < components.length; i++) {
          if (components[i].types[0] === 'locality') {
            if (components[i]['long_name'].toUpperCase() !== suburb && components[i]['short_name'].toUpperCase() !== suburb) {
              invalidSuburb = true;
            }
          }

          if (components[i].types[0] === 'postal_code') {
            if (components[i]['long_name'] !== postcode) {
              invalidPostcode = true;
            }
          }
          //set street name
          if (components[i].types[0] === 'route') {
            if (addressType === 'pickup') {
              setPickupStreetName(components[i]['long_name']);
            } else {
              setDestinationStreetName(components[i]['long_name']);
            }
          }
          //set street number
          if (components[i].types[0] === 'street_number') {
            if (addressType === 'pickup') {
              setPickupStreetNumber(components[i]['long_name']);
            } else {
              setDestinationStreetNumber(components[i]['long_name']);
            }
          }
        }
        if (invalidSuburb || invalidPostcode) {
          let errorText = "";
          if (invalidSuburb) {
            errorText = errorText + "suburb";
          }
          if (invalidSuburb && invalidPostcode) {
            errorText = errorText + " and postcode";
          } else if (invalidPostcode) {
            errorText = errorText + "postcode";
          }

          Swal.fire({
            title: 'Warning',
            text: "The address selected is not in the same " + errorText + " that you requested a quote for. Please search for a different address.",
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#f76b00',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (addressType === 'pickup') {
              setPickupAddress1('');
            } else {
              setDestinationAddress1('');
            }
          })
        }else{
          if (addressType === 'pickup') {
            setPickupGoogleAddressSelected(true);
          } else {
            setDestinationGoogleAddressSelected(true);
          }
        }
      }
    });
  }
  return (
    <div className={styles.root}>
        <OverlaySpinner enabled={status === 'in-progress'} />
          <Card color="grey">
            <Text color="grey900">
              Enter your reference number and pick up postcode below to request a pick up:
            </Text>

            <div className={styles.itemRow}>
              <div className={styles.cell}>
              <Input label="Reference Number" name="Code" value={codeValue} onChange={setCodeValue} errors={{}}  placeholder="Reference Number"/>
              </div>
              <div className={styles.cell}>
              <Input label="Pickup Postcode" className={styles.postcode} name="postcode" value={postCodeValue} onChange={setPostCodeValue} errors={{}} placeholder="Post Code" /> 
              </div>
              <div className={styles.cellLast}>
              <Button className={styles.button} onClick={getOrder} rounding="roundedSm">Request Pick Up</Button>
              </div>
            </div>
            
          </Card>

        {status === 'error' && (
          <Card className={styles.results} color="grey">
            <Panel>
              <Text className={styles.text}>
                Something went wrong! Please try again.
              </Text>
            </Panel>
          </Card>
        )}

      {apiMessage && status !== "not-found" && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}

        {status === 'not-found' && (
          <Card className={styles.results} color="grey">
            <Panel>
              <Text className={styles.text}>
                <strong>{apiMessage}</strong>
              </Text>
            </Panel>
          </Card>
        )}

      {status === 'completed' && (
        <Card className={styles.results} color="grey">
          <Panel>
            {courier != 'AlliedExpress' && courier != 'Hunter Express' && courier != 'Alpha Freight' && (
            <Text className={styles.text}>
              Rebook collection request submitted successfully!
            </Text>
            )}

            {jobNumber !== '1' && courier !== 'AlliedExpress' && courier !== 'Hunter Express' && courier !== 'Alpha Freight' && courier !== 'Northline' && courier !== 'Northline Express' && courier !== 'Josies Transport' && courier !== 'CTI Logistics Regional Freight' && courier !== 'Felix Transport' && courier !== 'Capital Transport' && (
            <Text className={styles.text}>
              <br />
              <br />
              {courier === "Zoom2u" || courier === "Sendle" ?
              (
                "Reference Number: "+jobNumber
              ) : (
                "Job Number: "+jobNumber
              )}
            </Text>
            )}

            {courier === 'AlliedExpress' && (
              <Text className={styles.text}>
              We have notified customer service about your request.
              <br />
              <br />
              If urgent please reach out to Allied Express Directly via the following numbers:
              <br />
              <br />
              <ul>
                <li>
                  VIC (03) 8698 0700
                </li>
                <li>
                  QLD - (07) 3112 9700
                </li>
                <li>
                  NSW – (02) 8837 8333
                </li>
                <li>
                  All Other states - 13 13 73
                </li>
              </ul>
              <br />
              <i>Note: Remember to quote the Allied Express consignment number found on your labels.</i>
            </Text>
            )}

            {courier === 'Hunter Express' && (
              <Text className={styles.text}>
              We have notified customer service about your request.
              <br />
              <br />
              If urgent please reach out to Hunter Express Directly via the following numbers:
              <br />
              <br />
              <ul>
                <li>
                  (02) 9780 4099
                </li>
              </ul>
              <br />
              <i>Note: Remember to quote the Allied Express consignment number found on your labels.</i>
            </Text>
            )}

            {courier === 'Alpha Freight' && (
              <Text className={styles.text}>
              We have notified customer service about your request.
              <br />
              <br />
              If urgent please reach out to Alpha Freight Directly via the following numbers:
              <br />
              <br />
              <ul>
                <li>
                  (02) 9780 4099
                </li>
              </ul>
              <br />
              <i>Note: Remember to quote the Alpha Freight consignment number found on your labels.</i>
            </Text>
            )}
          </Panel>
        </Card>
      )}

      {status === 'found' && (
        <div>
          <div className={styles.columns}>
            <div className={styles.column}>
              <H2 className={styles.heading}>Collect from</H2>
              <Input
                className={styles.input}
                layout="horizontal"
                label="Address"
                name="pickupAddress1"
                value={pickupAddress1}
                maxLength={setMaxLengthOfAddress()}
                onChange={(e) => {
                  if (courier === 'Zoom2u' || courier === 'Aramex') {
                    setPickupAddress1(e);
                    validateAddress('pickup');
                  } else {
                    setPickupAddress1(e);
                  }
                }}
                id="pickupAddress1"
                placeholder="Address"
                errors={errors}
                required
              />
              {courier !== 'Zoom2u' && courier !== 'Aramex' && (
                <Input
                  className={styles.input}
                  layout="horizontal"
                  label="Address 2"
                  name="pickupAddress2"
                  value={pickupAddress2}
                  maxLength={setMaxLengthOfAddress()}
                  onChange={setPickupAddress2}
                  placeholder="Address2"
                  errors={errors}
                />
              )}
              <Input
                className={styles.input}
                layout="horizontal"
                label="Suburb"
                name="pickupSuburb"
                value={pickupSuburb}
                disabled
                placeholder="Suburb"
                errors={errors}
              />
              <Input
                className={styles.input}
                layout="horizontal"
                label="State"
                name="pickupState"
                value={pickupState}
                disabled
                placeholder="State"
                errors={errors}
              />
              <Input
                className={styles.input}
                layout="horizontal"
                label="Postcode"
                name="pickupPostcode"
                value={pickupPostcode}
                disabled
                placeholder="Postcode"
                errors={errors}
              />
            </div>
            <div className={styles.column}>
              <H2 className={styles.heading}>Deliver to</H2>
              <Input
                className={styles.input}
                layout="horizontal"
                label="Address"
                name="destinationAddress1"
                value={destinationAddress1}
                maxLength={setMaxLengthOfAddress()}
                onChange={(e) => {
                  if (courier === 'Zoom2u' || courier === 'Aramex') {
                    setDestinationAddress1(e);
                    validateAddress('destination');
                  } else {
                    setDestinationAddress1(e);
                  }
                }}
                placeholder="Address"
                id="destinationAddress1"
                errors={errors}
                required
              />
              {courier !== 'Zoom2u' && courier !== 'Aramex' && (
                <Input
                  className={styles.input}
                  layout="horizontal"
                  label="Address 2"
                  name="destinationAddress2"
                  value={destinationAddress2}
                  onChange={setDestinationAddress2}
                  maxLength={setMaxLengthOfAddress()}
                  placeholder="Address2"
                  errors={errors}
                />
              )}
              <Input
                className={styles.input}
                layout="horizontal"
                label="Suburb"
                name="destinationSuburb"
                value={destinationSuburb}
                disabled
                placeholder="Suburb"
                errors={errors}
              />
              <Input
                className={styles.input}
                layout="horizontal"
                label="State"
                name="destinationState"
                value={destinationState}
                disabled
                placeholder="State"
                errors={errors}
              />
              <Input
                className={styles.input}
                layout="horizontal"
                label="Postcode"
                name="destinationPostcode"
                value={destinationPostcode}
                disabled
                placeholder="Postcode"
                errors={errors}
              />
            </div>
          </div>

          <div className={classNames(styles.summary, className)}>
            <div className={styles.collection}>
              <div className={styles.collectionDateTime}>
                <h2 style={{ color: '#1a202c', font: '700 20px/1 "vagroundedstd", sans-serif' }} className={styles.heading}>
                  Collection Date & Time
                </h2>
                <div className={styles.subHeadingText}>When would you like your parcel collected ?</div>
                <div className={styles.collectionDate}>
                  <div className={styles.labelHeading}>Date*</div>
                  <div>
                    <div>
                      <input
                        style={{ color: '#4a5568', outline: 'none', boxShadow: '0 0 10px #fff' }}
                        className={styles.dateInputBox}
                        type="date"
                        name="collectionDate"
                        value={collectionDate}
                        min={getTodaydate()}
                        onChange={(e) => {
                          checkSelectedDateIsWeekend(e.target.value);
                        }}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center'}}>
                    <div></div>
                    <span style={{ textAlign: 'right' }} className={styles.error}>
                      {errors.collectionDate ? errors.collectionDate : ''}
                    </span>
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      )}

      {status === 'found' && (
      <div className={styles.toolbar} >
        <Button type="primary" onClick={submit} disabled={ collectionDate.length == 0}>Submit</Button>
      </div>
      )}

    </div>
  );
}
