import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Card.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  color?: 'white' | 'grey';
};

export function Card(props: Props) {
  const { children, className, color = 'white' } = props;

  return <div className={classNames(styles.root, styles[color], className)}>{children}</div>;
}
