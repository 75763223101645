import React, { MouseEvent, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { Input } from '../../components/Input/Input';
import { Label } from '../../components/Label/Label';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Text } from '../../components/Text/Text';
import { Panel } from '../../components/Panel/Panel';
import styles from './NoTracking.module.scss';
import * as api from '../../services/api';
import { RebookStatus, ApiStatus, ValidationErrors } from '../../types';

type Props = {
  className?: string;
};

export function NoTracking(props: Props) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [apiStatus, setApiStatus] = useState<ApiStatus>('');
  const [consignmentCode, setConsignmentCode] = useState<string>('');
  const [visualDescription, setVisualDescription] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [otherInformation, setOtherInformation] = useState<string>('');
  const [pickupDate, setpickupDate] = useState<string>('');

  const [apiMessage, setApiMessage] = useState<string>('');

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

      setStatus('in-progress');
      try {
        await api.csrf();
        const response = await api.post<any>(`/contact/no-tracking`, {
          consignmentCode: consignmentCode,
          visualDescription: visualDescription,
          contactEmail: contactEmail,
          otherInformation: otherInformation,
          pickupDate: pickupDate,
        });
        setStatus('idle');
        if(response.status){
          setApiStatus('true');
          setConsignmentCode('');
          setVisualDescription('');
          setContactEmail('');
          setOtherInformation('');
          setpickupDate('');
        }
        setApiMessage(response.message);
      } catch (e) {
        setStatus('idle');
        setApiMessage("Something went wrong!");
      }
  };

  const getTodaydate = () => {
    let today = new Date(new Date().getTime());
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let date = yyyy + '-' + mm + '-' + dd;
    return date;
  };

  return (
    <div className={styles.root}>
      <OverlaySpinner enabled={status === 'in-progress'} />

      <Card color="grey" className={styles.card}>
        <Text color="grey900">If the consignment has been confirmed as collected, please lodge an investigation below. You will need a visual description of the items packaging, including dimensions, colour of carton, distinguishing markings on the packaging etc.</Text>            
      </Card>

      {apiMessage && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}

      {status === 'idle' && (
        <div>
          
          <Input
            className={styles.input}
            label="Consignment number / Reference ID"
            name="consignmentCode"
            value={consignmentCode}
            onChange={setConsignmentCode}
            errors={errors}
            required
          />
          
          <Input
            className={styles.input}
            label="Visual Description"
            name="visualDescription"
            value={visualDescription}
            onChange={setVisualDescription}
            errors={errors}
            placeholder="Example: White cardboard carton, black company logo on the sides and a red Fragile sticker on the top"
          />
          
          <Input
            className={styles.input}
            label="Contact email address"
            name="contactEmail"
            value={contactEmail}
            onChange={setContactEmail}
            errors={errors}
            required
          />
          
          <Input
            className={styles.input}
            label="Other Information"
            name="otherInformation"
            value={otherInformation}
            onChange={setOtherInformation}
            errors={errors}
          />

          <Label 
            className={styles.label}
            text="Picked Up Date"
            required
          />
          <input
            className={styles.dateInputBox}
            type="date"
            name="pickupDate"
            value={pickupDate}
            max={getTodaydate()}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onChange={(e) => {
              setpickupDate(e.target.value);
            }}
          />

        </div>
      )}

      {status === 'idle' && (
        <div className={styles.toolbar} >
          <Button type="primary" onClick={submit}>Submit</Button>
        </div>
      )}

    </div>
  );
}
