import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Text } from '../Text/Text';
import styles from './Label.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
  inverted?: boolean;
  required?: boolean;
  text?: React.ReactNode;
  textClassName?: string;
};

export function Label(props: Props) {
  const { children, className, inverted = false, required = false, text, textClassName } = props;

  return (
    <label className={classNames(styles.root, { [styles.hasChildren]: children }, className)} style={{textAlign: 'left'}}>
      {text && (
        <Text
          size="extraSmall"
          color={inverted ? 'white' : 'grey700'}
          className={classNames(styles.text, textClassName)}
          family="brand"
          weight="700"
        >
          {text}
          {required && ' *'}
        </Text>
      )}
      {children}
    </label>
  );
}
