import classNames from 'classnames';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../Button/Button';
import { Link } from '../Link/Link';
import { useStore } from '../../store';
import * as api from '../../services/api';
import styles from './AuthBar.module.scss';
import { CUSTOMER_PORTAL_URL } from '../../config';

type Props = {
  className?: string;
};

export function AuthBar(props: Props) {
  const { className } = props;

  const isLoggedIn = useStore((s) => s.isLoggedIn);
  const history = useHistory();
  const { pathname } = useLocation();
  const orderRefId = useStore((state) => state.orderRefId);
  const logout = () => {
    (async () => {
      await api.logout();
      history.push('/package-details');
    })();
  };

  return (
    <div className={classNames(styles.root, className)}>
      {pathname.startsWith('/account/') ? (
        <Link to="/package-details">Create an order</Link>
      ) : isLoggedIn ? (
        <Button type="link" onClick={logout}>
          Log out
        </Button>
      ) : (
        <div>
          { orderRefId ? (
            <span style={{ paddingRight: '20px' }}>
            <Link href={CUSTOMER_PORTAL_URL + "/sign-up?refId=" + orderRefId}>Register</Link>
          </span>
          ) : (
            <span style={{ paddingRight: '20px' }}>
            <Link href={CUSTOMER_PORTAL_URL + "/sign-up"}>Register</Link>
          </span>
          )}
          <Link href={CUSTOMER_PORTAL_URL + "/login"}>Log in</Link>
        </div>
      )}
    </div>
  );
}
