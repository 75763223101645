import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { Login } from './Login/Login';
import { Register } from './Register/Register';
import { ResetPassword } from './ResetPassword/ResetPassword';

export function Account() {
  return (
    <>
      <Switch>
        <Route exact path="/account/login" component={Login} />
        <Route exact path="/account/register" component={Register} />
        <Route exact path="/account/forgot-password" component={ForgotPassword} />
        <Route exact path="/account/reset-password/:token" component={ResetPassword} />
      </Switch>
    </>
  );
}
