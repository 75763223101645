import React, { MouseEvent, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { Input } from '../../components/Input/Input';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Text } from '../../components/Text/Text';
import { Panel } from '../../components/Panel/Panel';
import styles from './LabelRequest.module.scss';
import * as api from '../../services/api';
import { RebookStatus, ValidationErrors } from '../../types';

type Props = {
  className?: string;
};

export function LabelRequest(props: Props) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [consignmentCode, setConsignmentCode] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');

  const [apiMessage, setApiMessage] = useState<string>('');

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

      setStatus('in-progress');
      try {
        await api.csrf();
        const response = await api.post<any>(`/contact/label-request`, {
          consignmentCode: consignmentCode,
          contactName: contactName,
          contactEmail: contactEmail
        });
        setStatus('idle');
        if(response.status){
          setConsignmentCode('');
          setContactName('');
          setContactEmail('');
        }
        setApiMessage(response.message);
      } catch (e) {
        setStatus('idle');
        setApiMessage("Something went wrong!");
      }
  };

  return (
    <div className={styles.root}>
        <OverlaySpinner enabled={status === 'in-progress'} />
          <Card color="grey" className={styles.card}>
            <Text color="grey900">
            Have you made a booking and not received your labels via email?
There could be a couple of reasons for this.<br/><br/>
1. Please check your email junk folder.<br/>
2. If your booking has been made out of business hours, some bookings get held for review by our staff, so the labels will be issued when the customer service team resumes at 9am AEST Monday to Friday.<br/><br/>
If you have a Fast Courier Member Account your Labels are available for download through the “My Account” area. Labels are generally emailed to the 'Senders' email address. Please do not rebook a new job in the attempt to receive labels, as this will create additional bookings that will require cancellation and may incur charges.<br/>
If you require further assistance, please compete the following to advise a Customer Care Agent that you have not received your labels:
            </Text>            
          </Card>

      {apiMessage && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}

      {status === 'idle' && (
        <div>
          <Input
            className={styles.input}
            label="Consignment number / Reference ID"
            name="consignmentCode"
            value={consignmentCode}
            onChange={setConsignmentCode}
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            label="Contact Name"
            name="contactName"
            value={contactName}
            onChange={setContactName}
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            label="Contact email address"
            name="contactEmail"
            value={contactEmail}
            onChange={setContactEmail}
            errors={errors}
            required
          />
        </div>
      )}

      {status === 'idle' && (
        <div className={styles.toolbar} >
          <Button type="primary" onClick={submit}>Submit</Button>
        </div>
      )}
      
    </div>
  );
}
