import React, { useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import styles from './Stripe.module.scss';

type Props = {
  handleStripePayment: (cardDetails: any, billing_details: any, stripe: any, elements: any, company_name: any) => void;
};
export function Stripe(props: Props) {
  const { handleStripePayment } = props;
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);
  const [consentDisable, setConsentDisable] = useState(true);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const useOptions = () => {
    const options = useMemo(
      () => ({
        hidePostalCode: true,
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      }),
      []
    );

    return options;
  };
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  let cardDetails: any = { token: '' };
  const handleSubmit = async (event: any) => {
    setBtnDisable(true);
    let error = false;

    event.preventDefault();
    // validations check
    if (!event.target.email.value) {
      setEmailError('Email is required.');
      error = true;
    } else {
      setEmailError('');
    }
    if (!isCardComplete) {
      error = true;
      setCardNumberError('Card details are required.');
    }else{
      setCardNumberError('');
    }
    if (!event.target.name.value) {
      setNameError('Name is required.');
      error = true;
    } else {
      setNameError('');
    }

    if (error) {
      return;
    }
    if (elements) {
      cardDetails = elements.getElement(CardElement);
    }

    // billing details
    const billing_details = {
      name: event.target.name.value,
      email: event.target.email.value,
      address: {
        country: 'AU',
      },
    };
    const company_name = event.target.company_name.value;
    //stripe payment
    handleStripePayment(cardDetails, billing_details, stripe, elements, company_name);
  };

  const validate = (e: any) => {
    setBtnDisable(true);
    let error = false;
    if(e.target.name === 'email' && e.target.value){
        setEmailError('');
    }else if(e.target.name === 'email' && !e.target.value){
      setEmailError('Email is required.');
      error = true;
    }else if(e.target.name === 'name' && e.target.value){
      setNameError('');
    }else if(e.target.name === 'name' && !e.target.value){
      setNameError('Name is required.');
      error = true;
    }
    if(error){
      return;
    }else{
      setBtnDisable(false);
    }
  }

  const handleCardChange = (event: any) => {
    if(event.complete){
      setIsCardComplete(true);
    }else{
      setIsCardComplete(false);
    }
  };

  return (
    <>
      <div className={styles.stripeWrapper}>
        <div className={styles.parentDiv}>
          <form onSubmit={handleSubmit}>
            <div className={styles.srComboInputs}>
              <div className={styles.srComboInputsRow}>
                <input type="email" name="email" placeholder="Email" autoComplete="off" className={styles.srInput} onChange={(e) => {
                  validate(e);
                }} />
              </div>
              {emailError && <span className={styles.stripeFormError}>{emailError}</span>}
            </div>

            <div className={styles.srComboInputs}>
              <div className={styles.srComboInputsRow}>
                <CardElement
                  className={styles.stripeCardElement}
                  options={options}
                  onChange={(e) => {
                    handleCardChange(e);
                  }}
                />
              </div>
              {cardNumberError && <span className={styles.stripeFormError}>{cardNumberError}</span>}
            </div>

            <div className={styles.srComboInputs}>
              <div className={styles.srComboInputsRow}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name on Card"
                  autoComplete="off"
                  className={styles.srInput}
                  onChange={(e) => {
                    validate(e);
                  }}
                />
              </div>
              {nameError && <span className={styles.stripeFormError}>{nameError}</span>}
            </div>

            <div className={styles.srComboInputs}>
              <div className={styles.srComboInputsRow}>
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  placeholder="Company Name (Optional)"
                  autoComplete="off"
                  className={styles.srInput}
                />
              </div>
            </div>
            <button
              type="submit"
              style={{
                width: '100%',
                background: 'rgb(249, 150, 75)',
                color: 'white',
                letterSpacing: '3px',
                border: '1px solid transparent',
                padding: '.375rem .75rem',
                fontSize: '20px',
                borderRadius: '8px',
              }}
              disabled={!stripe || btnDisable}
            >
              Pay
            </button>
            <div style={{ marginBottom: '8px' }}>
              <label style={{ fontSize: "13px", color: "grey", paddingTop: "15px", paddingLeft: "10px" }}>By making this purchase, I consent to join Fast Courier Autopay. Fast Courier can auto-charge payments to my card, including adjustments, subject to <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/terms-conditions-2" target="_blank" rel="noreferrer">Fast Courier's Standard Operating Procedure.</a></label>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
