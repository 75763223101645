import { ValidationErrors } from './types';

export function extractError(errors: ValidationErrors, name: string): string | null {
  const error = errors?.[name] ?? [];

  if (error.length === 0) {
    return null;
  }

  return error.join('\n');
}

export function formatMoney(amount: null | number) {
  // eslint-disable-next-line eqeqeq
  if (amount == undefined) {
    amount = 0;
  }

  return Number(amount).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
}

export function labelFromValue(value: string, data: { label: string; value: string }[]) {
  const match = data.find((item) => item.value === value);
  if (match) {
    return match.label;
  }

  console.warn('Couldnot find matching label from value', value);
  return value;
}

export function formatAddress(
  address1: string,
  address2: string,
  suburb: string,
  state: string,
  postcode: string
): string {
  let firstLine = `${address1}${address2.length > 0 ? ` ${address2}` : ''}`.trim();
  firstLine = firstLine !== '' ? `${firstLine},\n` : '';

  return `${firstLine}${suburb}, ${state} ${postcode}`;
}

export function track(name: string, context: any = null) {
  if (!(window as any).dataLayer) {
    console.warn('dataLayer not setup - cannot send', { name, context });
    return;
  }

  (window as any).dataLayer.push({ event: name, ...context });
}

export function trackPurchase(orderId: null | string, quotePrice: null | number, paypalEmail: null | string, jobNumber: null | string | number, consignmentNumber: null | string | number, poBox: null | boolean, noLabels: null | boolean) {
  track('purchase', {
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId,
          currency: 'AUD',
          revenue: quotePrice,
          paypalEmail: paypalEmail,
          jobNumber: jobNumber,
          consignmentNumber: consignmentNumber,
          poBox: poBox,
          noLabels: noLabels,
        },
        products: [
          {
            id: orderId,
            name: 'Order',
            quantity: 1,
            price: quotePrice?.toString(),
          },
        ],
      },
    },
  });
}
