import 'core-js/web/url-search-params';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import { SENTRY_DSN, GTM_ID } from './config';
import App from './App';
import * as api from './services/api';
import './styles/reset.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module';

Sentry.init({ dsn: SENTRY_DSN });

function checkCookieExists(cookieName: any) {
  // Split the cookie string into individual cookies
  var cookies = document.cookie.split(';');

  // Iterate over the cookies and check if the cookieName is found
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();

    // Check if the cookie starts with the provided cookieName
    if (cookie.indexOf(cookieName + '=') === 0) {
      // Cookie found
      return true;
    }
  }

  // Cookie not found
  return false;
}
const tagManagerArgs = {
  gtmId: GTM_ID
}

TagManager.initialize(tagManagerArgs);
//check token exist
var cookieExists = checkCookieExists('XSRF-TOKEN');

async function loadSuburbs(cookieExists: any) {
  //Fetch suburbs data from local storage
  var version = localStorage.getItem("suburbs_version");
  var suburbs = localStorage.getItem("suburbs");

  //Check if version or suburbs not set
  if (!version || !suburbs) {
    version = null;
  }

  //Call token api
  if (!cookieExists) {
    await api.csrf();
  }

  try {
    let response = await api.post<any>(`/suburbs-version`, { version: version });

    if (response.data) {
      //Sets version and suburbs in local storage
      localStorage.setItem("suburbs_version", response.data.version);
      localStorage.setItem("suburbs", JSON.stringify(response.data.suburbs));
    }
  } catch (e) {
    await api.csrf();
    let response = await api.post<any>(`/suburbs-version`, { version: version });

    if (response.data) {
      //Sets version and suburbs in local storage
      localStorage.setItem("suburbs_version", response.data.version);
      localStorage.setItem("suburbs", JSON.stringify(response.data.suburbs));
    }
  }

}
loadSuburbs(cookieExists);

document.querySelectorAll('[data-fast-courier-app]').forEach((element) => {
  const type = (element.getAttribute('data-type') ?? 'horizontal') as 'horizontal' | 'vertical' | 'app';
  const appPageUrl = (element.getAttribute('data-app-page-url') ?? '') as string;

  ReactDOM.render(<App type={type} appPageUrl={appPageUrl} />, element);
});
