import classNames from 'classnames';
import React from 'react';

import styles from './H3.module.scss';

interface Props {
  alignment?: 'left' | 'center';
  children: React.ReactNode;
  className?: string;
  inverted?: boolean;
}

export function H3(props: Props) {
  const { className, children, alignment = 'left', inverted = false } = props;

  return (
    <h3 className={classNames(styles.root, styles[alignment], { [styles.inverted]: inverted }, className)}>
      {children}
    </h3>
  );
}
