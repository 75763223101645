import classNames from 'classnames';
import React from 'react';

import { Button } from '../Button/Button';
import { formatAddress, formatMoney, labelFromValue } from '../../util';
import { H3 } from '../H3/H3';
import { PackageTypes, ContentTypes } from '../../types';
import { Text } from '../Text/Text';
import { useStore } from '../../store';
import styles from './OrderSummary.module.scss';
import { InfoCircle } from '../../icons/InfoCircle/InfoCircle';

type Props = {
  className?: string;
  packageDetailsEditEnabled: boolean;
  quoteEditEnabled: boolean;
  addressDetailsEditEnabled: boolean;
};

export function OrderSummary(props: Props) {
  const { className, packageDetailsEditEnabled, quoteEditEnabled, addressDetailsEditEnabled } = props;

  const items = useStore((s) => s.items);
  const quotePrice = useStore((s) => s.quotePrice);
  const quoteDelivery = useStore((s) => s.quoteDelivery);
  const noteTitle = useStore((s) => s.noteTitle);
  const noteSubTitle = useStore((s) => s.noteSubTitle);
  const noteBody = useStore((s) => s.noteBody);
  const pickupAddress1 = useStore((s) => s.pickupAddress1);
  const pickupAddress2 = useStore((s) => s.pickupAddress2);
  const pickupSuburb = useStore((s) => s.pickupSuburb);
  const pickupState = useStore((s) => s.pickupState);
  const pickupPostcode = useStore((s) => s.pickupPostcode);
  const destinationAddress1 = useStore((s) => s.destinationAddress1);
  const destinationAddress2 = useStore((s) => s.destinationAddress2);
  const destinationSuburb = useStore((s) => s.destinationSuburb);
  const destinationState = useStore((s) => s.destinationState);
  const destinationPostcode = useStore((s) => s.destinationPostcode);
  // const extendedLiability = useStore((s) => s.extendedLiability);
  const insuranceFee = useStore((state) => state.insuranceFee);
  const noPrinter = useStore((state) => state.noPrinter);
  const couponDiscountPercentage = useStore((state) => state.couponDiscountPercentage);
  const courierServiceName = useStore((s) => s.courierServiceName);

  //calculate order net amount
  const calculateNetAmt = () => {
    let quoteAmt = quotePrice || 0;
    let insuranceAmt = insuranceFee ? Number(insuranceFee.replace('$', '')) : 0;
    let manualConnoteAmt = noPrinter ? 30 : 0;
    let subAmt = quoteAmt + insuranceAmt + manualConnoteAmt;
    if(couponDiscountPercentage){
      subAmt = (subAmt) - (subAmt * Number(couponDiscountPercentage)/100);
    };
    return (subAmt + subAmt * 0.1).toFixed(2);
  }

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.columns}>
        <div className={styles.column}>
          <H3 className={styles.heading}>
            Package Details
            {packageDetailsEditEnabled && (
              <Button type="link" size="small" to="/package-details/" className={styles.link}>
                (edit)
              </Button>
            )}
          </H3>
          {items.map((item, i) => (
            <ul className={styles.list} key={i}>
              <Text size="small" as="li" className={classNames(styles.item, styles.itemSmall)}>
                <strong>Package Type:</strong> {labelFromValue(item.type, PackageTypes)}
              </Text>
              <Text size="small" as="li" className={classNames(styles.item, styles.itemSmall)}>
                <strong>Weight:</strong> {item.weight}kg
              </Text>
              <Text size="small" as="li" className={classNames(styles.item, styles.itemSmall)}>
                <strong>Dimensions:</strong> (L) {item.length}cm x (W) {item.width}cm x (H) {item.height}
                cm
              </Text>
              <Text size="small" as="li" className={classNames(styles.item, styles.itemSmall)}>
                <strong>Quantity:</strong> {item.quantity}
              </Text>
              <Text size="small" as="li" className={classNames(styles.item, styles.itemSmall)}>
                <strong>Contents:</strong> {labelFromValue(item.contents, ContentTypes)}
              </Text>
            </ul>
          ))}
          <ul className={styles.listCourierName}>
            <Text size="small" as="li" className={classNames(styles.item, styles.itemSmall)}>
                <strong>Courier Selected:</strong> {courierServiceName === 'Toll' ? 'Fast Courier' : courierServiceName === 'Toll Palletised Express' ? 'Fast Courier Palletised Express' : courierServiceName}
            </Text>
          </ul>
        </div>
        <div className={styles.column}>
          <H3 className={styles.heading}>
            Quote
            {quoteEditEnabled && (
              <Button type="link" size="small" to="/select-quote/" className={styles.link}>
                (edit)
              </Button>
            )}
          </H3>
          <ul className={styles.list}>
            {/* {noteTitle && (
              <Text size="small" as="li" className={styles.item}>
                <strong>{noteTitle}</strong>
                {noteSubTitle && <p>{noteSubTitle}</p>}
                {noteBody && (
                  <div className={styles.insurance}>
                    <InfoCircle className={styles.insuranceIcon} />
                    <span dangerouslySetInnerHTML={{ __html: noteBody }} />
                  </div>
                )}
              </Text>
            )} */}
            <Text size="small" as="li" className={styles.item}>
              <strong>Est. Delivery: </strong>
              {quoteDelivery}
            </Text>
            <Text size="small" as="li" className={styles.item}>
              <strong>Price: </strong>
              {formatMoney(quotePrice)} (Excl. GST)
            </Text>

            {insuranceFee ? (
              <Text size="small" as="li" className={styles.item}>
                <strong>Warranty: </strong>
                {insuranceFee}
              </Text>
            ) : (
              ''
            )}

            {noPrinter ? (
              <Text size="small" as="li" className={styles.item}>
                <strong>Mannual Connotes: </strong>
                $30.00
              </Text>
            ) : (
              ''
            )}

            {couponDiscountPercentage ? (
              <Text size="small" as="li" className={styles.item}>
                <strong>Discount: </strong>
                {'$'+((Number(couponDiscountPercentage)/100) * ((quotePrice || 0) + (noPrinter ? 30 : 0) + Number(insuranceFee.replace('$', '')))).toFixed(2) + ' (' + couponDiscountPercentage + '%)'}
              </Text>
            ) : (
              ''
            )}

            <Text size="small" as="li" className={styles.item}>
              <strong>Net Price: </strong>${calculateNetAmt()} (Inc. GST)
            </Text>
          </ul>
        </div>
        <div className={styles.column}>
          <H3 className={styles.heading}>
            Address Details
            {addressDetailsEditEnabled && (
              <Button type="link" size="small" to="/shipment-details/" className={styles.link}>
                (edit)
              </Button>
            )}
          </H3>
          <ul className={styles.list}>
            <Text size="small" as="li" className={styles.item}>
              <strong>From: </strong>
              <div className={styles.address}>
                {formatAddress(pickupAddress1, pickupAddress2, pickupSuburb, pickupState, pickupPostcode)}
              </div>
            </Text>
            <Text size="small" as="li" className={styles.item}>
              <strong>To: </strong>
              <div className={styles.address}>
                {formatAddress(
                  destinationAddress1,
                  destinationAddress2,
                  destinationSuburb,
                  destinationState,
                  destinationPostcode
                )}
              </div>
            </Text>
          </ul>
        </div>
      </div>
    </div>
  );
}
