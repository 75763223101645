import create from 'zustand';
import produce from 'immer';
import { devtools } from 'zustand/middleware';
import { FRONTEND_URL } from './config';
import { BuildingType, Item, SenderType, extendedLiabilities, Email, Quote } from './types';

// currentStep: I'm not sure if this 100% perfect, its kind of keeping routes in sync with the store.
// However, it is kind of needed, we don't want a currentStep for login and register for example, so we can't rely
// just on the route

export type Store = {
  isLoggedIn: boolean | null;
  currentStep: number;
  orderId: string | null;
  pickupFirstName: string;
  pickupLastName: string;
  pickupCompanyName: string;
  pickupEmail: string;
  pickupAddress1: string;
  pickupAddress2: string;
  pickupSuburb: string;
  pickupState: string;
  pickupPostcode: string;
  pickupPhone: string;
  pickupBuildingType: BuildingType;
  pickupTailLift: boolean;
  showPickupModal: boolean;
  showDestinationModal: boolean;
  showPackageDetailsModal: boolean;
  destinationDeliveryChoiceID: string;
  destinationFirstName: string;
  destinationLastName: string;
  destinationCompanyName: string;
  destinationEmail: string;
  destinationAddress1: string;
  destinationAddress2: string;
  destinationSuburb: string;
  destinationState: string;
  destinationPostcode: string;
  destinationPhone: string;
  destinationBuildingType: BuildingType;
  dropOffTailLift: boolean;
  dropOffPOBox: boolean;
  pickupTimeWindow: string;
  authorityToLeave: boolean;
  noPrinter: boolean;
  collectionDate: string | '';
  parcelContent: string;
  specialInstructions: string;
  valueOfContent: string;
  extendedLiability: string;
  insuranceValue: string;
  insuranceFee: string;
  acceptBookingDetails: boolean;
  acceptInsuranceConditions: boolean;
  acceptTermConditions: boolean;
  acceptNoDangerousGoods: boolean;
  acceptReadFinancialServiceGuide:boolean;
  quoteId: null | string;
  quotePrice: null | number;
  progressBarPercentage: number;
  quoteDelivery: null | number;
  quoteInsurance: null | string;
  quotePickupCutOffTime: null | string;
  noteTitle: null | string;
  noteSubTitle: null | string;
  noteBody: null | string;
  senderType: SenderType;
  items: Item[];
  insurances: extendedLiabilities[];
  emails: Email[];
  quotes: Quote[];
  payPalPaymentGateway: boolean;
  stripePaymentGateway: boolean;
  coinbasePaymentGateway: boolean;
  courierServiceName: string;
  reCreateOrder: boolean;
  orderRefId: string | null;
  couponCode: string;
  couponDiscountPercentage: any;
  quoteExcludingGstPrice: null | number;
  sendSamePackage: boolean;
  customOrderName: string;
  customOrderPhone: string;
  customOrderEmail: string;
  customOrder: boolean;
  preferenceCapitalTransportSet: boolean;
  preferenceJosiesTransportSet: boolean;
  preferenceFelixTransportSet: boolean;
  preferenceDirectCouriersSet: boolean;
  preferenceBondsCouriersSet: boolean;
  preferenceZoom2uSet: boolean;
  pickupStreetName: string;
  pickupStreetNumber: string;
  destinationStreetName: string;
  destinationStreetNumber: string;
  hideInsuranceFields: boolean;
  earliestCollectionDay: any;
  setIsLoggedIn: (value: boolean) => void;
  setCurrentStep: (step: number) => void;
  setOrderId: (value: string) => void;
  setPickupFirstName: (value: string) => void;
  setPickupLastName: (value: string) => void;
  setPickupCompanyName: (value: string) => void;
  setPickupEmail: (value: string) => void;
  setPickupAddress1: (value: string) => void;
  setPickupAddress2: (value: string) => void;
  setPickupSuburb: (value: string) => void;
  setPickupState: (value: string) => void;
  setPickupPostcode: (value: string) => void;
  setPickupPhone: (value: string) => void;
  setPickupBuildingType: (value: BuildingType) => void;
  setPickupTailLift: (value: boolean) => void;
  setShowPickupModal: (value: boolean) => void;
  setShowDestinationModal: (value: boolean) => void;
  setShowPackageDetailsModal: (value: boolean) => void;
  setDestinationDeliveryChoiceID: (value: string) => void;
  setDestinationFirstName: (value: string) => void;
  setDestinationLastName: (value: string) => void;
  setDestinationCompanyName: (value: string) => void;
  setDestinationEmail: (value: string) => void;
  setDestinationAddress1: (value: string) => void;
  setDestinationAddress2: (value: string) => void;
  setDestinationSuburb: (value: string) => void;
  setDestinationState: (value: string) => void;
  setDestinationPostcode: (value: string) => void;
  setDestinationPhone: (value: string) => void;
  setDestinationBuildingType: (value: BuildingType) => void;
  setDropOffTailLift: (value: boolean) => void;
  setDropOffPOBox: (value: boolean) => void;
  setPickupTimeWindow: (value: string) => void;
  setAuthorityToLeave: (value: boolean) => void;
  setNoPrinter: (value: boolean) => void;
  setCollectionDate: (value: string) => void;
  setParcelContent: (value: string) => void;
  setSpecialInstructions: (value: string) => void;
  setValueOfContent: (value: string) => void;
  setExtendedLiability: (value: string) => void;
  setInsuranceValue: (value: string) => void;
  setInsuranceFee: (value: string) => void;
  setAcceptBookingDetails: (value: boolean) => void;
  setAcceptInsuranceConditions: (value: boolean) => void;
  setAcceptReadFinancialServiceGuide: (value: boolean) => void;
  setAcceptTermConditions: (value: boolean) => void;
  setAcceptNoDangerousGoods: (value: boolean) => void;
  setQuoteId: (value: string) => void;
  setQuotePrice: (value: number) => void;
  setProgressBarPercentage: (value: number) => void;
  setQuoteDelivery: (value: number) => void;
  setQuoteInsurance: (value: string) => void;
  setQuotePickupCutOffTime: (value: string) => void;
  setNoteTitle: (value: string) => void;
  setNoteSubTitle: (value: string) => void;
  setNoteBody: (value: string) => void;
  setSenderType: (senderType: SenderType) => void;
  addItem: () => void;
  pushQuotes: (value: any, flag: boolean) => void;
  addInsurance: (value: any) => void;
  addEmail: () => void;
  removeEmail: (index: number) => void;
  editEmail: (index: number, field: keyof Email, value: Email[keyof Email]) => void;
  removeItem: (index: number) => void;
  editItem: (index: number, field: keyof Item, value: Item[keyof Item]) => void;
  setQuotes: (value: any) => void;
  clearEmptyItems: () => void;
  setPayPalPaymentGateway: (value: boolean) => void;
  setStripePaymentGateway: (value: boolean) => void;
  setCoinbasePaymentGateway: (value: boolean) => void;
  setCourierServiceName: (value: string) => void;
  setReCreateOrder: (value: boolean) => void;
  setOrderRefId: (value: string) => void;
  setCouponCode: (value: string) => void;
  setCouponDiscountPercentage: (value: any) => void;
  setQuoteExcludingGstPrice: (value: number) => void;
  setSendSamePackage: (value: boolean) => void;
  setCustomOrderName: (value: string) => void;
  setCustomOrderPhone: (value: string) => void;
  setCustomOrderEmail: (value: string) => void;
  setCustomOrder: (value: boolean) => void;
  setPickupStreetName: (value: string) => void;
  setPickupStreetNumber: (value: string) => void;
  setDestinationStreetName: (value: string) => void;
  setDestinationStreetNumber: (value: string) => void;
  setHideInsuranceFields: (value: boolean) => void;
  setEarliestCollectionDay: (value: any) => void;
};

export const defaultItem: Item = { type: 'bag', weight: '', length: '', width: '', height: '', quantity: 1, contents: '', calculatedLength: '', calculatedHeight: '', calculatedWidth: '' };
export const defaultEmail: Email = { email: '' };

export const useStore = create<Store>(
  devtools((set) => {
    const produceAndSet = (setter: (state: Store) => void) => {
      return set(produce(setter));
    };

    const payload = new URLSearchParams(window.location.search).get('payload');
    if (payload) {
      window.history.replaceState(null, document.title, window.location.pathname);
    }
    if (window.location.pathname === '/get-quote' || window.location.pathname === '/get-quote/') {
      window.location.replace(FRONTEND_URL);
    }

    let widgetData: any = {};
    try {
      widgetData = JSON.parse(atob(payload!));
    } catch (e) {}

    return {
      isLoggedIn: false,
      currentStep: 0,
      orderId: null,
      pickupFirstName: '',
      pickupLastName: '',
      pickupCompanyName: '',
      pickupEmail: '',
      pickupAddress1: widgetData.pickupAddress1 ?? '',
      pickupAddress2: '',
      pickupSuburb: widgetData.pickupSuburb ?? '',
      pickupState: widgetData.pickupState ?? '',
      pickupPostcode: widgetData.pickupPostcode ?? '',
      pickupPhone: '',
      pickupBuildingType: widgetData.pickupBuildingType ?? '',
      pickupTailLift: false,
      showPickupModal: false,
      showDestinationModal: false,
      showPackageDetailsModal: false,
      destinationDeliveryChoiceID: '',
      destinationFirstName: '',
      destinationLastName: '',
      destinationCompanyName: '',
      destinationEmail: '',
      destinationAddress1: widgetData.destinationAddress1 ?? '',
      destinationAddress2: '',
      destinationSuburb: widgetData.destinationSuburb ?? '',
      destinationState: widgetData.destinationState ?? '',
      destinationPostcode: widgetData.destinationPostcode ?? '',
      destinationPhone: '',
      destinationBuildingType: widgetData.destinationBuildingType ?? '',
      dropOffTailLift: false,
      dropOffPOBox: false,
      pickupTimeWindow: '',
      authorityToLeave: false,
      noPrinter: false,
      collectionDate: '',
      parcelContent: '',
      specialInstructions: '',
      valueOfContent: '',
      extendedLiability: '',
      insuranceValue: '$0',
      insuranceFee: '$0',
      acceptBookingDetails: false,
      acceptInsuranceConditions: false,
      acceptReadFinancialServiceGuide : false,
      acceptTermConditions: false,
      acceptNoDangerousGoods: false,
      quoteId: null,
      quotePrice: null,
      progressBarPercentage: 0,
      quoteDelivery: null,
      quoteInsurance: null,
      quotePickupCutOffTime: null,
      noteTitle: null,
      noteSubTitle: null,
      noteBody: null,
      senderType: 'sender',
      hideInsuranceFields: false,
      earliestCollectionDay: null,
      items: [
        {
          height: widgetData.height ?? defaultItem.height,
          length: widgetData.length ?? defaultItem.length,
          type: widgetData.type ?? defaultItem.type,
          weight: widgetData.weight ?? defaultItem.weight,
          width: widgetData.width ?? defaultItem.width,
          quantity: widgetData.quantity ?? defaultItem.quantity,
          contents: widgetData.contents ?? defaultItem.contents,
          calculatedLength: widgetData.calculatedLength ?? defaultItem.calculatedLength,
          calculatedHeight: widgetData.calculatedHeight ?? defaultItem.calculatedHeight,
          calculatedWidth: widgetData.calculatedWidth ?? defaultItem.calculatedWidth
        }
      ],
      insurances: [],
      emails: [
        {
          email: '',
        },
      ],
      quotes:[],
      payPalPaymentGateway: false,
      stripePaymentGateway: false,
      coinbasePaymentGateway: false,
      courierServiceName: '',
      reCreateOrder: false,
      orderRefId: null,
      couponCode: '',
      couponDiscountPercentage: null,
      quoteExcludingGstPrice: null,
      sendSamePackage: false,
      customOrderName: '',
      customOrderPhone: '',
      customOrderEmail: '',
      pickupStreetName: '',
      pickupStreetNumber: '',
      destinationStreetName: '',
      destinationStreetNumber: '',
      customOrder: false,
      preferenceCapitalTransportSet: false,
      preferenceJosiesTransportSet: false,
      preferenceFelixTransportSet: false,
      preferenceDirectCouriersSet: false,
      preferenceBondsCouriersSet: false,
      preferenceZoom2uSet: false,
      setIsLoggedIn: (isLoggedIn: boolean | null) => set({ isLoggedIn }),
      setCurrentStep: (currentStep: number) => set({ currentStep }),
      setOrderId: (orderId: string) => set({ orderId }),
      setPickupFirstName: (pickupFirstName: string) => set({ pickupFirstName }),
      setPickupLastName: (pickupLastName: string) => set({ pickupLastName }),
      setPickupCompanyName: (pickupCompanyName: string) => set({ pickupCompanyName }),
      setPickupEmail: (pickupEmail: string) => set({ pickupEmail }),
      setPickupAddress1: (pickupAddress1: string) => set({ pickupAddress1 }),
      setPickupAddress2: (pickupAddress2: string) => set({ pickupAddress2 }),
      setPickupSuburb: (pickupSuburb: string) => set({ pickupSuburb }),
      setPickupState: (pickupState: string) => set({ pickupState }),
      setPickupPostcode: (pickupPostcode: string) => set({ pickupPostcode }),
      setPickupPhone: (pickupPhone: string) => set({ pickupPhone }),
      setPickupBuildingType: (pickupBuildingType: BuildingType) => set({ pickupBuildingType }),
      setPickupTailLift: (pickupTailLift: boolean) => set({ pickupTailLift }),
      setShowPickupModal: (showPickupModal: boolean) => set({ showPickupModal }),
      setShowDestinationModal: (showDestinationModal: boolean) => set({ showDestinationModal }),
      setShowPackageDetailsModal: (showPackageDetailsModal: boolean) => set({ showPackageDetailsModal }),
      setDestinationDeliveryChoiceID: (destinationDeliveryChoiceID: string) => set({ destinationDeliveryChoiceID }),
      setDestinationFirstName: (destinationFirstName: string) => set({ destinationFirstName }),
      setDestinationLastName: (destinationLastName: string) => set({ destinationLastName }),
      setDestinationCompanyName: (destinationCompanyName: string) => set({ destinationCompanyName }),
      setDestinationEmail: (destinationEmail: string) => set({ destinationEmail }),
      setDestinationAddress1: (destinationAddress1: string) => set({ destinationAddress1 }),
      setDestinationAddress2: (destinationAddress2: string) => set({ destinationAddress2 }),
      setDestinationSuburb: (destinationSuburb: string) => set({ destinationSuburb }),
      setDestinationState: (destinationState: string) => set({ destinationState }),
      setDestinationPostcode: (destinationPostcode: string) => set({ destinationPostcode }),
      setDestinationPhone: (destinationPhone: string) => set({ destinationPhone }),
      setDestinationBuildingType: (destinationBuildingType: BuildingType) => set({ destinationBuildingType }),
      setDropOffTailLift: (dropOffTailLift: boolean) => set({ dropOffTailLift }),
      setDropOffPOBox: (dropOffPOBox: boolean) => set({ dropOffPOBox }),
      setPickupTimeWindow: (pickupTimeWindow: string) => set({ pickupTimeWindow }),
      setAuthorityToLeave: (authorityToLeave: boolean) => set({ authorityToLeave }),
      setNoPrinter: (noPrinter: boolean) => set({ noPrinter }),
      setCollectionDate: (collectionDate: string) => set({ collectionDate }),
      setParcelContent: (parcelContent: string) => set({ parcelContent }),
      setSpecialInstructions: (specialInstructions: string) => set({ specialInstructions }),
      setValueOfContent: (valueOfContent: string) => set({ valueOfContent }),
      setExtendedLiability: (extendedLiability: string) => set({ extendedLiability }),
      setInsuranceValue: (insuranceValue: string) => set({ insuranceValue }),
      setInsuranceFee: (insuranceFee: string) => set({ insuranceFee }),
      setAcceptBookingDetails: (acceptBookingDetails: boolean) => set({ acceptBookingDetails }),
      setAcceptInsuranceConditions: (acceptInsuranceConditions: boolean) => set({ acceptInsuranceConditions }),
      setAcceptTermConditions: (acceptTermConditions: boolean) => set({ acceptTermConditions }),
      setAcceptReadFinancialServiceGuide: (acceptReadFinancialServiceGuide:boolean) => set({acceptReadFinancialServiceGuide}),
      setAcceptNoDangerousGoods: (acceptNoDangerousGoods: boolean) => set({ acceptNoDangerousGoods }),
      setQuoteId: (quoteId: string) => set({ quoteId }),
      setQuotePrice: (quotePrice: number) => set({ quotePrice }),
      setProgressBarPercentage: (progressBarPercentage: number) => set({ progressBarPercentage }),
      setQuoteDelivery: (quoteDelivery: number) => set({ quoteDelivery }),
      setQuoteInsurance: (quoteInsurance: string) => set({ quoteInsurance }),
      setQuotePickupCutOffTime: (quotePickupCutOffTime: string) => set({ quotePickupCutOffTime }),
      setNoteTitle: (noteTitle: string) => set({ noteTitle }),
      setNoteSubTitle: (noteSubTitle: string) => set({ noteSubTitle }),
      setNoteBody: (noteBody: string) => set({ noteBody }),
      setSenderType: (senderType: SenderType) => set({ senderType }),
      addInsurance: (insurances: any) => set({ insurances }),
      setPayPalPaymentGateway: (payPalPaymentGateway: boolean) => set({ payPalPaymentGateway }),
      setStripePaymentGateway: (stripePaymentGateway: boolean) => set({ stripePaymentGateway }),
      setCoinbasePaymentGateway: (coinbasePaymentGateway: boolean) => set({ coinbasePaymentGateway }),
      setCourierServiceName: (courierServiceName: string) => set({ courierServiceName }),
      setReCreateOrder: (reCreateOrder: boolean) => set({ reCreateOrder }),
      setOrderRefId: (orderRefId: string) => set({ orderRefId }),
      setCouponCode: (couponCode: string) => set({ couponCode }),
      setCouponDiscountPercentage: (couponDiscountPercentage: any) => set({ couponDiscountPercentage }),
      setQuoteExcludingGstPrice: (quoteExcludingGstPrice: number) => set({ quoteExcludingGstPrice }),
      setSendSamePackage: (sendSamePackage: boolean) => set({ sendSamePackage }),
      setCustomOrderName: (customOrderName: string) => set({ customOrderName }),
      setCustomOrderPhone: (customOrderPhone: string) => set({ customOrderPhone }),
      setCustomOrderEmail: (customOrderEmail: string) => set({ customOrderEmail }),
      setCustomOrder: (customOrder: boolean) => set({ customOrder }),
      setPickupStreetName: (pickupStreetName: string) => set({ pickupStreetName }),
      setPickupStreetNumber: (pickupStreetNumber: string) => set({ pickupStreetNumber }),
      setDestinationStreetName: (destinationStreetName: string) => set({ destinationStreetName }),
      setDestinationStreetNumber: (destinationStreetNumber: string) => set({ destinationStreetNumber }),
      setHideInsuranceFields: (hideInsuranceFields: boolean) => set({ hideInsuranceFields }),
      setEarliestCollectionDay: (earliestCollectionDay: boolean) => set({ earliestCollectionDay }),
	  setQuotes: (quotes: any) => set({ quotes }),
      addItem: () =>
        produceAndSet((state: Store) => {
          state.items.push(defaultItem);
        }),
        pushQuotes: (appendNewQuotes: any, disableProgressBar: boolean) =>
        produceAndSet((state: Store) => {
          state.quotes = [...state.quotes, ...appendNewQuotes];
          if(disableProgressBar){
            state.progressBarPercentage+= 100;
          }else{
            state.progressBarPercentage+= 5.1;
          }
          if(state.quotes.length > 0){
            state.quotes = state.quotes.sort((a:any, b:any) => a.priceExcludingGst - b.priceExcludingGst);
            state.preferenceDirectCouriersSet =false;
            state.preferenceBondsCouriersSet =false;
            state.preferenceZoom2uSet =false;
            state.preferenceFelixTransportSet =false;
            state.preferenceJosiesTransportSet =false;
            state.preferenceCapitalTransportSet =false;
            let preferredQuotes:any = [];
            state.quotes.forEach((quote, index) => {
              if(state.preferenceDirectCouriersSet && state.preferenceBondsCouriersSet && state.preferenceZoom2uSet && state.preferenceFelixTransportSet && state.preferenceJosiesTransportSet && state.preferenceCapitalTransportSet){
                return;
              }
              
              if(!state.preferenceCapitalTransportSet && quote.courierName === 'Capital Transport'){
                preferredQuotes.push(quote);
                state.quotes.splice(index, 1);
                state.quotes.splice(0, 0, quote);
                state.preferenceCapitalTransportSet = true;
              }

              if(!state.preferenceJosiesTransportSet && quote.courierName === 'Josies Transport'){
                preferredQuotes.push(quote);
                state.quotes.splice(index, 1);
                state.quotes.splice(0, 0, quote);
                state.preferenceJosiesTransportSet = true;
              }

              if(!state.preferenceFelixTransportSet && quote.courierName === 'Felix Transport'){
                preferredQuotes.push(quote);
                state.quotes.splice(index, 1);
                state.quotes.splice(0, 0, quote);
                state.preferenceFelixTransportSet = true;
              }

              if(!state.preferenceZoom2uSet && quote.courierName === 'Zoom2u'){
                preferredQuotes.push(quote);
                state.quotes.splice(index, 1);
                state.quotes.splice(0, 0, quote);
                state.preferenceZoom2uSet = true;
              }

              if(!state.preferenceBondsCouriersSet && quote.courierName === 'Bonds Couriers'){
                preferredQuotes.push(quote);
                state.quotes.splice(index, 1);
                state.quotes.splice(0, 0, quote);
                state.preferenceBondsCouriersSet = true;
              }

              if(!state.preferenceDirectCouriersSet && quote.courierName === 'Direct Couriers'){
                preferredQuotes.push(quote);
                state.quotes.splice(index, 1);
                state.quotes.splice(0, 0, quote);
                state.preferenceDirectCouriersSet = true;
              }
            });
            preferredQuotes = preferredQuotes.sort((a:any, b:any) => b.priceExcludingGst - a.priceExcludingGst);
            preferredQuotes.forEach((quote:any, index:any) => {
                state.quotes.splice(index, 1);
                state.quotes.splice(0, 0, quote);
            });
          }
        }),
      removeItem: (index: number) =>
        produceAndSet((state: Store) => {
          if (state.items.length <= 1) {
            return;
          }
          state.items.splice(index, 1);
        }),
      editItem: (index: number, field: keyof Item, value: Item[keyof Item]) =>
        produceAndSet((state: Store) => {
          if (state.items?.[index]?.[field] !== undefined) {
            if(value === 'plants' || value === 'artwork' || value === 'household contents/personal effects'){
              state.hideInsuranceFields = true;
              state.extendedLiability = '$0';
            }
            (state.items[index][field] as any) = value;
          }
        }),
      clearEmptyItems: () =>
        produceAndSet((state: Store) => {
          state.items = state.items.filter((item) => item.quantity > 0);
        }),

      addEmail: () =>
        produceAndSet((state: Store) => {
          state.emails.push(defaultEmail);
        }),

      removeEmail: (index: number) =>
        produceAndSet((state: Store) => {
          if (state.emails.length <= 1) {
            return;
          }
          state.emails.splice(index, 1);
        }),

      editEmail: (index: number, field: keyof Email, value: Email[keyof Email]) =>
        produceAndSet((state: Store) => {
          if (state.emails?.[index]?.[field] !== undefined) {
            (state.emails[index][field] as any) = value;
          }
        }),
    };
  })
);
