import { Button } from '../Button/Button';
import { Component } from 'react';
import { H1 } from '../H1/H1';
import { Text } from '../Text/Text';
import { useStore } from '../../store';
import * as api from '../../services/api';
import styles from './ErrorBoundary.module.scss';

export class ErrorBoundary extends Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  recordError(message: string, context: any = {}) {
    try {
      api.post('error', { message, context });
    } catch (e) {
      // silently fail, we have no backup plans if the whole backend is down
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.recordError(error.toString(), errorInfo);
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.onUnhandledRejection);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
  }

  onUnhandledRejection = (event: PromiseRejectionEvent) => {
    event.promise.catch((error) => {
      this.setState(ErrorBoundary.getDerivedStateFromError(error));
    });
  };

  render() {
    if (this.state.hasError) {
      const orderId = useStore.getState().orderId;
      return (
        <>
          <H1>Sorry, it looks like something went wrong.</H1>
          <Text className={styles.text}>We have notified our tech team of the issue. </Text>
          {orderId && (
            <Text className={styles.text}>
              If you would like to contact our support team, please reference this number: <code>{orderId}</code>
            </Text>
          )}
          <Button className={styles.text} onClick={() => window.location.replace('/package-details')}>
            Return Home
          </Button>
        </>
      );
    }
    return <>{this.props.children}</>;
  }
}
