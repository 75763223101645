import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Alert } from '../../components/Alert/Alert';
import { Button } from '../../components/Button/Button';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { H3 } from '../../components/H3/H3';
import { Header } from '../../components/Header/Header';
import { OrderSummary } from '../../components/OrderSummary/OrderSummary';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Panel } from '../../components/Panel/Panel';
import { Status } from '../../types';
import { useStore } from '../../store';
import * as api from '../../services/api';
import { Form } from 'react-bootstrap';
import styles from './AdditionalInfo.module.scss';
import { track } from '../../util';
import swal from 'sweetalert';

export function AdditionalInfo() {
  const orderId = useStore((state) => state.orderId);
  const acceptBookingDetails = useStore((state) => state.acceptBookingDetails);
  const acceptInsuranceConditions = useStore((state) => state.acceptInsuranceConditions);
  const acceptTermConditions = useStore((state) => state.acceptTermConditions);
  const acceptNoDangerousGoods = useStore((state) => state.acceptNoDangerousGoods);
  const acceptReadFinancialServiceGuide = useStore((state) => state.acceptReadFinancialServiceGuide);
  const payPalPaymentGateway = useStore((state) => state.payPalPaymentGateway);
  const stripePaymentGateway = useStore((state) => state.stripePaymentGateway);
  const coinbasePaymentGateway = useStore((state) => state.coinbasePaymentGateway);
  const reCreateOrder = useStore((state) => state.reCreateOrder);
  const couponCode = useStore((state) => state.couponCode);
  const customOrder = useStore((state) => state.customOrder);
  const noPrinter = useStore((state) => state.noPrinter);

  const setCurrentStep = useStore((state) => state.setCurrentStep);
  const setAcceptBookingDetails = useStore((state) => state.setAcceptBookingDetails);
  const setAcceptInsuranceConditions = useStore((state) => state.setAcceptInsuranceConditions);
  const setAcceptReadFinancialServiceGuide = useStore((state) => state.setAcceptReadFinancialServiceGuide);
  const setAcceptTermConditions = useStore((state) => state.setAcceptTermConditions);
  const setAcceptNoDangerousGoods = useStore((state) => state.setAcceptNoDangerousGoods);
  const emails = useStore((state) => state.emails);
  const addEmail = useStore((state) => state.addEmail);
  const removeEmail = useStore((state) => state.removeEmail);
  const editEmail = useStore((state) => state.editEmail);
  const setPayPalPaymentGateway = useStore((state) => state.setPayPalPaymentGateway);
  const setStripePaymentGateway = useStore((state) => state.setStripePaymentGateway);
  const setCoinbasePaymentGateway = useStore((state) => state.setCoinbasePaymentGateway);
  const setOrderId = useStore((state) => state.setOrderId);
  const setReCreateOrder = useStore((state) => state.setReCreateOrder);
  const setCouponCode = useStore((state) => state.setCouponCode);
  const setCouponDiscountPercentage = useStore((state) => state.setCouponDiscountPercentage);
  const [additionalEmailError, setAdditionalEmailError] = useState('');
  const [couponCodeError, setCouponCodeError] = useState('');

  const history = useHistory();
  const [status, setStatus] = useState<Status>('idle');

  const save = () => {
    (async () => {
      setCouponCodeError('');
      setAdditionalEmailError('');
      try {
        let hashId: any;
        hashId = orderId;
        setStatus('in-progress');
        if(reCreateOrder){
          const reCreateOrderRecord = await api.post<any>(`/order/${orderId}/reCreateOrder`, {});
          hashId = reCreateOrderRecord.id;
          setOrderId(reCreateOrderRecord.id);
          setReCreateOrder(false);
        }
        await api.csrf();

        const response = await api.patch<any>(`/order/${hashId}/additional-info`, {
          acceptBookingDetails: acceptBookingDetails,
          acceptInsuranceConditions: acceptInsuranceConditions,
          acceptTermConditions: acceptTermConditions,
          acceptAttachment: false,
          acceptNoDangerousGoods: acceptNoDangerousGoods,
          acceptReadFinancialServiceGuide:acceptReadFinancialServiceGuide,
          emails: emails,
        });
        setPayPalPaymentGateway(response.PayPal);
        setStripePaymentGateway(response.Stripe);
        setCoinbasePaymentGateway(response.Coinbase);
        if(response.coupon_discount_percentage){
          setCouponDiscountPercentage(response.coupon_discount_percentage);
        }
        
        track('app-additional-info', {
          additionalEmails:emails
        });
        if(customOrder){
          history.push(`/order-complete?ref=${hashId}&new=`);
        }else{
          history.push('/payment');
        }
      } catch (e: any) {
          setAdditionalEmailError(e?.response?.data?.error ?? '');
          setStatus('error');
      }
    })();
  };

  const validateCoupon = () => {
    if(couponCode){
      setCouponCodeError('');
      setAdditionalEmailError('');
    (async () => {
      try {
        let hashId: any;
        hashId = orderId;
        setStatus('in-progress');
        await api.csrf();

        const couponResponse = await api.patch<any>(`/order/${hashId}/coupon`, {
          couponCode: couponCode,
        });

        if(couponResponse.coupon_discount_percentage){
          setCouponDiscountPercentage(couponResponse.coupon_discount_percentage);
          await swal({
            title: "Coupon applied successfully!",
            icon: "success",
            confirmButtonText: "Ok",
        } as any);
          setStatus('idle');
        }
      } catch (e: any) {
        if(e?.response?.data?.type){
          setCouponCodeError(e?.response?.data?.error ?? '');
        }
        setStatus('error');
      }
    })()};
  }

  useEffect(() => {
    if (!orderId) {
      history.push('/package-details');
      return;
    }

    setCurrentStep(3);
  }, [setCurrentStep, orderId, history]);

  let lastTerm = 'YES- I have read the prohibited/restricted product list and declare that this shipment does not contain dangerous goods';
  lastTerm = lastTerm.replace('prohibited/restricted product list', '<a href="">prohibited/restricted product list</a>')
  return (
    <>
      <Header />
      <OverlaySpinner enabled={status === 'in-progress'} />
      <OrderSummary
        className={styles.orderSummary}
        packageDetailsEditEnabled={true}
        quoteEditEnabled={true}
        addressDetailsEditEnabled={true}
      />
      <Panel className={styles.panel}>
        <H3>Terms &amp; Conditions</H3>
        {/* <Checkbox
          name="acceptInsuranceConditions"
          className={styles.input}
          value={acceptInsuranceConditions}
          onChange={setAcceptInsuranceConditions}
          label="YES - I have read, understood &amp; agree to the road express transit insurance conditions."
        /> */}
        <Checkbox
          name="acceptBookingDetails"
          className={styles.input}
          value={acceptBookingDetails}
          onChange={setAcceptBookingDetails}
          label="I confirm that all details entered in the booking are accurate and truthful to the best of my knowledge. Extra charges will apply for any incorrectly declared information including but not limited to weights, dimensions, address type and/or package types."
        />
        <Checkbox
          name="acceptTermConditions"
          className={styles.input}
          value={acceptTermConditions}
          onChange={setAcceptTermConditions}
          label={
            <div>
              YES - I have read, understood &amp; agree to the{' '}
              <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/terms-conditions-2" target="_blank" rel="noreferrer">
                Terms &amp; Conditions.
              </a>
            </div>
          }
        />
        <Checkbox
          name="acceptNoDangerousGoods"
          className={styles.input}
          value={acceptNoDangerousGoods}
          onChange={setAcceptNoDangerousGoods}
          label={
            <div>
            YES- I have read the <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/prohibited-restricted-list" target="_blank" rel="noreferrer">
             prohibited/restricted product list
             </a> and declare that this shipment does not contain dangerous goods
             
           </div>
          }
        />
        <Checkbox
          name="acceptReadFinancialServiceGuide"
          className={styles.input}
          value={acceptReadFinancialServiceGuide}
          onChange={setAcceptReadFinancialServiceGuide}
          label={
            <div>
            YES - I/We have read and understood the  
             <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/extended-liability-terms-conditions"> Policy Terms and Conditions</a> and I accept the option I have elected."
              
            </div>
          }
        />
        
        <Checkbox
          name="acceptAll"
          className={styles.input}
          value={acceptInsuranceConditions && acceptBookingDetails && acceptTermConditions && acceptNoDangerousGoods && acceptReadFinancialServiceGuide}
          onChange={() => {
            setAcceptInsuranceConditions(true);
            setAcceptBookingDetails(true);
            setAcceptTermConditions(true);
            setAcceptNoDangerousGoods(true);
            setAcceptReadFinancialServiceGuide(true);
          }}
          label="Agree to all of the above"
        />
      </Panel>
      <div>
        <div className={styles.collection}>
          <div className={styles.collectionDateTime}>
            <div className="pt-4">
              <H3 className="pb-3">Add Coupon code for discount</H3>
              <div className="mb-4">
                <div className="d-flex">
                    <div className={styles.subHeadingText}>
                          Coupon
                    </div>
                    <Form>
                      <Form.Group className ={styles.inputField} controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          type="text"
                          placeholder="OPTIONAL"
                          name="couponCode"
                          onChange={(e) => setCouponCode(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                </div>
              </div>
              <div className={styles.applyOrAddButton}>
                  <Button type="ghost" size="medium" onClick={validateCoupon}>
                    Apply coupon
                  </Button>
                </div>
            </div>
            </div>
          </div>
        </div>
      <div>
        <div className={styles.collection}>
          <div className={styles.collectionDateTime}>
            <div className="pt-4">
              <H3 className="pb-3">Additional Emails to send Labels &amp; Invoices to</H3>
              <div>
                {emails.map((email, i) => (
                  <div className="mb-4">
                    <div className="d-flex">
                      <div className={styles.subHeadingText}>
                        Email
                      </div>
                      <Form>
                        <Form.Group className ={styles.inputField} controlId="formBasicPassword">
                          <Form.Control
                            name="email"
                            value={email.email}
                            onChange={(e) => editEmail(i, 'email', e.target.value)}
                            className="pr-4"
                            type="text"
                            placeholder="OPTIONAL"
                            style={{ color: '#4a5568' }}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                    {emails.length > 1 && (
                      <div className={styles.applyOrAddButton}>
                        <button className={styles.remove} onClick={() => removeEmail(i)}>
                          Remove email
                        </button>
                      </div>
                    )}
                  </div>
                ))}

                <div className={styles.applyOrAddButton}>
                  <Button type="ghost" size="medium" onClick={addEmail}>
                    Add email
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {couponCodeError && (
        <Alert className={styles.alert} type="error">
          {couponCodeError}
        </Alert>
      )}
      {status === 'error' && !additionalEmailError && !couponCodeError && (
        <Alert className={styles.alert} type="error">
          Please ensure to accept the Terms &amp; Conditions
        </Alert>
      )}
      {status === 'error' && additionalEmailError && (
        <Alert className={styles.alert} type="error">
          {additionalEmailError}
        </Alert>
      )}
      <div className={styles.toolbar}>
        <Button onClick={save}>{customOrder ? 'Submit Booking' : 'Confirm Booking'}</Button>
      </div>
    </>
  );
}
