import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Button as CustomButton } from '../../components/Button/Button';
import { Header } from '../../components/Header/Header';
import { InfoCircle } from '../../icons/InfoCircle/InfoCircle';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Status, ValidationErrors } from '../../types';
import { Text } from '../../components/Text/Text';
import { useStore } from '../../store';
import * as api from '../../services/api';
import styles from './QuotesComparisons.module.scss';
import { formatMoney, track } from '../../util';
import { Modal, Button } from 'react-bootstrap';
import { API_URL } from '../../config';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import ReactStars from 'react-stars'
import ProgressBar from 'react-bootstrap/ProgressBar';

type Props = {
  className?: string;
};
export function QuotesComparisons(props: Props) {
  const { className } = props;
  const m = useLocation();
  const orderId = useStore((s) => s.orderId);
  const pickupSuburb = useStore((s) => s.pickupSuburb);
  const pickupState = useStore((s) => s.pickupState);
  const pickupPostcode = useStore((s) => s.pickupPostcode);
  const destinationSuburb = useStore((s) => s.destinationSuburb);
  const destinationState = useStore((s) => s.destinationState);
  const destinationPostcode = useStore((s) => s.destinationPostcode);
  const customOrderName = useStore((s) => s.customOrderName);
  const customOrderPhone = useStore((s) => s.customOrderPhone);
  const customOrderEmail = useStore((s) => s.customOrderEmail);
  const customOrder = useStore((s) => s.customOrder);
  const items = useStore((s) => s.items);
  const quotes = useStore((s) => s.quotes);
  const quoteId = useStore((s) => s.quoteId);
  const courierServiceName = useStore((s) => s.courierServiceName);
  const currentStep = useStore((s) => s.currentStep);
  const reCreateOrder = useStore((state) => state.reCreateOrder);
  const progressBarPercentage = useStore((state) => state.progressBarPercentage);
  const dropOffPOBox = useStore((state) => state.dropOffPOBox);
  const hideInsuranceFields = useStore((state) => state.hideInsuranceFields);
  const earliestCollectionDay = useStore((state) => state.earliestCollectionDay);
  const setQuoteId = useStore((s) => s.setQuoteId);
  const setProgressBarPercentage = useStore((s) => s.setProgressBarPercentage);
  const setQuotes = useStore((s) => s.setQuotes);
  const setCurrentStep = useStore((s) => s.setCurrentStep);
  const setQuotePrice = useStore((s) => s.setQuotePrice);
  const setQuoteInsurance = useStore((s) => s.setQuoteInsurance);
  const setQuoteDelivery = useStore((s) => s.setQuoteDelivery);
  const setNoteTitle = useStore((s) => s.setNoteTitle);
  const setNoteSubTitle = useStore((s) => s.setNoteSubTitle);
  const setNoteBody = useStore((s) => s.setNoteBody);
  const setCourierServiceName = useStore((s) => s.setCourierServiceName);
  const setOrderId = useStore((state) => state.setOrderId);
  const setReCreateOrder = useStore((state) => state.setReCreateOrder);
  const setQuotePickupCutOffTime = useStore((state) => state.setQuotePickupCutOffTime);
  const setCustomOrderName = useStore((state) => state.setCustomOrderName);
  const setCustomOrderPhone = useStore((state) => state.setCustomOrderPhone);
  const setCustomOrderEmail = useStore((state) => state.setCustomOrderEmail);
  const setCustomOrder = useStore((state) => state.setCustomOrder);
  const pushQuotes = useStore((state) => state.pushQuotes);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [aeModalShow, setAEModalShow] = useState(false);
  const handleAEModalClose = () => setAEModalShow(false);
  const [hunterExpressModalShow, setHunterExpressModalShow] = useState(false);
  const handleHunterExpressModalClose = () => setHunterExpressModalShow(false);
  const [northlineModalShow, setNorthlineModalShow] = useState(false);
  const handleNorthlineModalClose = () => setNorthlineModalShow(false);
  const [tntModalShow, setTNTModalShow] = useState(false);
  const handleTNTModalClose = () => setTNTModalShow(false);
  const history = useHistory();
  const [status, setStatus] = useState<Status>('idle');
  const [savingStatus, setSavingStatus] = useState<Status>('idle');
  const addInsurance = useStore((state) => state.addInsurance);
  const currentDay = new Date().getDay();
  const setQuoteExcludingGstPrice = useStore((s) => s.setQuoteExcludingGstPrice);
  const [showAEThirdPartyInfoModal, setShowAEThirdPartyInfoModal] = useState(false);
  const handleCloseAEThirdPartyInfoModal = () => setShowAEThirdPartyInfoModal(false);
  const handleShowAEThirdPartyInfoModal = () => setShowAEThirdPartyInfoModal(true);
  const [aeModalConsent, setAEModalConsent] = useState(false);
  const [northlineModalConsent, setNorthlineModalConsent] = useState(false);
  const [tntModalConsent, setTNTModalConsent] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showQuotes, setShowQuotes] = useState(false);
  const setPickupSuburb = useStore((state) => state.setPickupSuburb);
  const setPickupPostcode = useStore((state) => state.setPickupPostcode);
  const setPickupState = useStore((state) => state.setPickupState);
  const setPickupBuildingType = useStore((state) => state.setPickupBuildingType);
  const setPickupTailLift = useStore((state) => state.setPickupTailLift);
  const setDestinationSuburb = useStore((state) => state.setDestinationSuburb);
  const setDestinationPostcode = useStore((state) => state.setDestinationPostcode);
  const setDestinationState = useStore((state) => state.setDestinationState);
  const setDestinationBuildingType = useStore((state) => state.setDestinationBuildingType);
  const setDropOffTailLift = useStore((state) => state.setDropOffTailLift);
  const setDropOffPOBox = useStore((state) => state.setDropOffPOBox);
  const addItem = useStore((state) => state.addItem);
  const editItem = useStore((state) => state.editItem);
  const setEarliestCollectionDay = useStore((state) => state.setEarliestCollectionDay);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
  useEffect(() => {
    (async () => {
      if (!orderId) {
        const payload = new URLSearchParams(m.search).get('payload');
        if (payload) {
          setStatus('in-progress');
          let widgetData: any = {};
          try {
            widgetData = JSON.parse(atob(payload!));
            setPickupSuburb(widgetData.pickupSuburb);
            setPickupState(widgetData.pickupState);
            setPickupPostcode(widgetData.pickupPostcode);
            setPickupBuildingType(widgetData.pickupBuildingType);
            setPickupTailLift(widgetData.pickupTailLift);
            setDestinationSuburb(widgetData.destinationSuburb);
            setDestinationPostcode(widgetData.destinationPostcode);
            setDestinationState(widgetData.destinationState);
            setDestinationBuildingType(widgetData.destinationBuildingType);
            setDropOffTailLift(widgetData.dropOffTailLift);
            setDropOffPOBox(widgetData.dropOffPOBox);
            setOrderId(widgetData.orderId);
            widgetData.items.forEach((item: any, index: any) => {
              if (index >= 1) {
                addItem();
              }
              editItem(index, 'type', item.type);
              editItem(index, 'weight', item.weight);
              editItem(index, 'length', item.length);
              editItem(index, 'width', item.width);
              editItem(index, 'height', item.height);
              editItem(index, 'quantity', item.quantity);
              editItem(index, 'contents', item.contents);
              editItem(index, 'calculatedLength', item.length);
              editItem(index, 'calculatedWidth', item.width);
              editItem(index, 'calculatedHeight', item.height);
            });
            setStatus('idle');
          } catch (e) { }
        }
      }

      const elm = document.querySelector<HTMLElement>('.progress-bar')!;
      const elm2 = document.querySelector<HTMLElement>('.progress')!;
      const loadingLabel = document.querySelector<HTMLElement>('.loading-label')!;
      elm.style.backgroundColor = '#ffb570';
      if (progressBarPercentage >= 100) {
        setTimeout(() => {
          elm2.style.display = 'none';
          loadingLabel.style.display = 'none';
        }, 1000);
      }
      if (customOrder) {
        return;
      }
      if (status !== 'idle') {
        return;
      }
      if ((quotes.length === 0 || currentStep === 0) && (orderId)) {
        try {
          setQuotes([]);
          setStatus('in-progress');
          const urls = [`/quote/${orderId}/1`, `/quote/${orderId}/2`, `/quote/${orderId}/3`, `/quote/${orderId}/4`, `/quote/${orderId}/5`, `/quote/${orderId}/6`, `/quote/${orderId}/7`, `/quote/${orderId}/8`, `/quote/${orderId}/9`, `/quote/${orderId}/10`, `/quote/${orderId}/11`, `/quote/${orderId}/12`, `/quote/${orderId}/13`, `/quote/${orderId}/14`, `/quote/${orderId}/15`, `/quote/${orderId}/16`, `/quote/${orderId}/17`, `/quote/${orderId}/18`, `/quote/${orderId}/19`, `/quote/${orderId}/20`];

          urls.forEach(url => {
            callAPI(url)
          });

        } catch (e: any) {
          track('app-error-quote-retrieval', { orderId, error: e?.response?.data });
        }
      }
    })();
  }, [
    status,
    history,
    orderId,
    pickupSuburb,
    pickupPostcode,
    destinationSuburb,
    destinationPostcode,
    items,
    pickupState,
    destinationState,
    progressBarPercentage,
  ]);

  useEffect(() => {
    if (currentStep === 2) {
      setShowQuotes(true);
      const elm2 = document.querySelector<HTMLElement>('.progress')!;
      const loadingLabel = document.querySelector<HTMLElement>('.loading-label')!;
      if (progressBarPercentage >= 100) {
        elm2.style.display = 'none';
        loadingLabel.style.display = 'none';
      }
    } else {
      setTimeout(() => {
        setProgressBarPercentage(5);
        setShowQuotes(true);
      }, 1000);
    }
    const payload = new URLSearchParams(m.search).get('payload');
    if (!orderId && !payload) {
      history.push('/package-details');
      return;
    }
    setCurrentStep(1);
  }, [history, orderId, setCurrentStep]);

  useEffect(() => {
    if (quotes.length > 0) {
      setStatus('complete');
    }
    if (quotes.length === 0 && progressBarPercentage >= 100) {
      setTimeout(() => {
        setStatus('complete');
        setCustomOrder(true);
      }, 1000);
    }
  }, [quotes]);

  function getNextMonday(courierServiceName: any) {
    if (courierServiceName === 'Direct Couriers' || courierServiceName === 'Bonds Couriers' || courierServiceName === 'Zoom2u' || courierServiceName === 'Felix Transport' || courierServiceName === 'Josies Transport' || courierServiceName === 'Capital Transport') {
      return <div><div><FontAwesomeIcon icon={faBolt} color="#f76b00" /><span style={{ fontStyle: 'italic', fontSize: '13px', color: '#f76b00' }}>{' Same Day Delivery'}</span></div>{moment().startOf('isoWeek').add(1, 'week').format('dddd Do')}</div>;
    } else {
      return moment().startOf('isoWeek').add(1, 'week').format('dddd Do');
    }

  }

  async function callAPI(url: any) {
    try{
        const newDataArr: any = await api.get<any>(url);
      
        if(newDataArr.data?.length >0 && newDataArr.data[0].courierName === 'MRL Global'){
          setEarliestCollectionDay(newDataArr.data[0].selectedDays);
        }
        pushQuotes(newDataArr.data, false);
    } catch (e: any) {
      track('app-error-quote-retrieval', { orderId, error: e?.response?.data });
    }
    
  }

  async function save(quoteId: string, courierServiceName = '', quotePickupCutOffTime = '') {
    setQuoteId(quoteId);
    setCourierServiceName(courierServiceName);
    setQuotePickupCutOffTime(quotePickupCutOffTime);
    if (courierServiceName === 'AlliedExpress') {
      return setAEModalShow(true);
    }
    if (courierServiceName === 'Hunter Express') {
      return setHunterExpressModalShow(true);
    }
    if (courierServiceName === 'Northline' || courierServiceName === 'Northline Express') {
      return setNorthlineModalShow(true);
    }
    if (courierServiceName === 'TNT') {
      return setTNTModalShow(true);
    }

    try {
      let hashId: any;
      hashId = orderId;
      setSavingStatus('in-progress');
      if (reCreateOrder) {
        const reCreateOrderRecord = await api.post<any>(`/order/${orderId}/reCreateOrder`, {});
        hashId = reCreateOrderRecord.id;
        setOrderId(reCreateOrderRecord.id);
        setReCreateOrder(false);
      }
      await api.csrf();
      const { data } = await api.patch(`/order/${hashId}/select-quote`, { quoteId });
      setQuotePrice(data.priceExcludingGst);
      setQuoteExcludingGstPrice(data.priceExcludingGst);
      setNoteTitle(data.noteTitle);
      setNoteSubTitle(data.noteSubTitle);
      setNoteBody(data.noteBody);
      setQuoteDelivery(data.eta);
      setQuoteInsurance(data.insuranceCategory);
      addInsurance(data.insurance);
      track('app-select-quote', {
        priceExcludingGst: data.priceExcludingGst,
        priceIncludingGst: data.priceIncludingGst,
        insuranceCategory: data.insuranceCategory,
        courierServiceName: courierServiceName,
      });
      history.push('/shipment-details?courierService=' + courierServiceName);
    } catch (e) {
      setSavingStatus('error');
    }
  }

  async function saveAEHunterQuote() {
    try {
      let hashId: any;
      hashId = orderId;
      setSavingStatus('in-progress');
      if (reCreateOrder) {
        const reCreateOrderRecord = await api.post<any>(`/order/${orderId}/reCreateOrder`, {});
        hashId = reCreateOrderRecord.id;
        setOrderId(reCreateOrderRecord.id);
        setReCreateOrder(false);
      }
      await api.csrf();
      const { data } = await api.patch(`/order/${hashId}/select-quote`, { quoteId });
      setQuotePrice(data.priceExcludingGst);
      setQuoteExcludingGstPrice(data.priceExcludingGst);
      setNoteTitle(data.noteTitle);
      setNoteSubTitle(data.noteSubTitle);
      setNoteBody(data.noteBody);
      setQuoteDelivery(data.eta);
      setQuoteInsurance(data.insuranceCategory);
      addInsurance(data.insurance);
      track('app-select-quote', {
        priceExcludingGst: data.priceExcludingGst,
        priceIncludingGst: data.priceIncludingGst,
        insuranceCategory: data.insuranceCategory,
        courierServiceName: courierServiceName,
      });
      history.push('/shipment-details?courierService=' + courierServiceName);
    } catch (e) {
      setSavingStatus('error');
    }
  }

  async function saveCustomQuote() {
    setCourierServiceName('Custom Quote');
    try {
      let hashId: any;
      hashId = orderId;
      setSavingStatus('in-progress');
      await api.csrf();
      const { data } = await api.patch(`/order/${hashId}/custom-quote`, {
        name: customOrderName,
        phone: customOrderPhone,
        email: customOrderEmail,
      });
      addInsurance(data.insurance);
      track('app-custom-order', {
        name: customOrderName,
        email: customOrderEmail,
        phone: customOrderPhone,
        referenceID: hashId,
      });
      history.push('/shipment-details?courierService=' + courierServiceName);
    } catch (e: any) {
      setSavingStatus('error');
      setErrors(e?.response?.data?.errors ?? {});
    }
  }

  function getNextSelectableDay(date: any, selectableDays: any) {
    let nextDay = new Date(date);
    while (!selectableDays.includes(days[nextDay.getDay()])) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay;
  }

  function getCollectionAvailabilityText(quote: any, earliestCollectionDay: any, currentDay: any) {
    if (quote.courierName === 'MRL Global' && earliestCollectionDay[0] !== "Daily") {
      if (earliestCollectionDay.length === 1) {
        if (moment().isAfter(moment(quote.pickupCutOffTime, 'HH:mm'))) {
          return "Tomorrow";
        } else {
          return "Collection Available Today";
        }
      } else if (earliestCollectionDay.length > 0) {
        let today = new Date();
        let todayDayName = days[today.getDay()];
  
        let nextSelectableDates: string[] = [];
        for (let i = 0; i < earliestCollectionDay.length; i++) {
          let day = earliestCollectionDay[i];
          if (day === todayDayName && moment().isAfter(moment(quote.pickupCutOffTime, 'HH:mm'))) {
            nextSelectableDates.push("Next " + day);
          } else {
            nextSelectableDates.push(day);
          }
        }
    
        // Filter out duplicates and sort by the days' order
        nextSelectableDates = Array.from(new Set(nextSelectableDates));
        nextSelectableDates.sort((a, b) => days.indexOf(a.replace("Next ", "")) - days.indexOf(b.replace("Next ", "")));
    
        return nextSelectableDates.join(' and ');
      }
    }
  
    if ((quote.courierName === 'TNT' || quote.courierName === 'Toll' || quote.courierName === 'Star Track' || quote.courierName === 'Couriers Please' || quote.courierName === 'Toll Palletised Express' || quote.courierName === 'Northline' || quote.courierName === 'Northline Express' || quote.courierName === 'CTI Logistics Regional Freight' || quote.courierName === 'MRL Global') && (currentDay !== 6 && currentDay !== 0) && (!moment().isAfter(moment(quote.pickupCutOffTime, 'HH:mm')))) {
      return 'Collection Available Today';
    }
  
    if ((quote.courierName === 'Direct Couriers' || quote.courierName === 'Bonds Couriers' || quote.courierName === 'Zoom2u' || quote.courierName === 'Felix Transport' || quote.courierName === 'Josies Transport' || quote.courierName === 'Capital Transport') && (currentDay !== 6 && currentDay !== 0) && (!moment().isAfter(moment(quote.pickupCutOffTime, 'HH:mm')))) {
      return (
        <div>
          <div>
            <FontAwesomeIcon icon={faBolt} color="#f76b00" />
            <span style={{ fontStyle: 'italic', fontSize: '13px', color: '#f76b00' }}>{' Same Day Delivery'}</span>
          </div>
          {'Collection Available Today'}
        </div>
      );
    }
  
    if (currentDay === 5 || currentDay === 6) {
      return getNextMonday(quote.courierName);
    }
  
    if (quote.courierName === 'Direct Couriers' || quote.courierName === 'Bonds Couriers' || quote.courierName === 'Zoom2u' || quote.courierName === 'Felix Transport' || quote.courierName === 'Josies Transport' || quote.courierName === 'Capital Transport') {
      return (
        <div>
          <div>
            <FontAwesomeIcon icon={faBolt} color="#f76b00" />
            <span style={{ fontStyle: 'italic', fontSize: '13px', color: '#f76b00' }}>{' Same Day Delivery'}</span>
          </div>
          {'Tomorrow'}
        </div>
      );
    }
  
    return 'Tomorrow';
  }

  const repeatQuoteSearch = () => {
      history.push('/package-details');
  }
  let insuranceCategoryUrl = API_URL + "/documents/QM3248-1118-CourierSure-Goods-in-Transit-Marine-Policy-web.pdf";
  return (
    <div className={styles.root}>
      <Header />
      <OverlaySpinner
        message={status === 'in-progress' ? 'Searching our couriers to find you the best quotes' : ''}
        enabled={savingStatus === 'in-progress' || status === 'in-progress'}
      />
      <ProgressBar style={{ height: "0.5rem", marginTop: "10px" }} now={progressBarPercentage ? progressBarPercentage : 0} />
      <span className="loading-label" style={{ fontSize: '14px' }}>Loading...</span>
      {customOrder || status === 'error' ? (
        <div>
          {dropOffPOBox ? (
            <div className='text-center pt-5'>
              <b>We see that you have selected a PO BOX as a destination address</b>
              <Text>
                <p className='pt-2'>Please note that PO BOX deliveries are limited, <a href="#" onClick={repeatQuoteSearch}>click here</a> to search again without a PO BOX delivery.</p>
              </Text>
            </div>
          ) : (
            <div>
              <div className='text-center pt-5'>
              <b>Sorry we couldn't find a quote</b>
              <Text>
                <p className='pt-2'>Please go back to the package details page and check your dimensions to make sure you have the right measurements in centimetres or millimetres.</p>
              </Text>
              <Text>
                <p className='pt-2'>If measurements look ok and we still can't retrieve a quote for you then please submit the form below.</p>
              </Text>
            </div>
              <div className={styles.mainDiv}>
                <div className={styles.customQuote}>
                  <div className={styles.customQuoteBlock}>
                    <div>
                      <div style={{ paddingTop: '15px' }}>
                        <span className={styles.labelHeading}>Name *</span>
                        <Form>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              name="customOrderName"
                              value={customOrderName}
                              onChange={(e) => {
                                setCustomOrderName(e.target.value || '');
                              }}
                              className={styles.input}
                            />
                          </Form.Group>
                          <span className={styles.error}>{errors.name ? errors.name : ''}</span>
                        </Form>
                      </div>
                      <div>
                        <span className={styles.labelHeading}>Email *</span>
                        <Form>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              name="customOrderEmail"
                              value={customOrderEmail}
                              onChange={(e) => {
                                setCustomOrderEmail(e.target.value || '');
                              }}
                              className={styles.input}
                            />
                          </Form.Group>
                          <span className={styles.error}>{errors.email ? errors.email : ''}</span>
                        </Form>
                      </div>
                      <div>
                        <span className={styles.labelHeading}>Phone *</span>
                        <Form>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                              type="text"
                              placeholder="Phone"
                              name="customOrderPhone"
                              value={customOrderPhone}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (!isNaN(+value)) {
                                  setCustomOrderPhone(e.target.value || '');
                                } else {
                                  setCustomOrderPhone(customOrderPhone);
                                }
                              }}
                              className={styles.input}
                            />
                          </Form.Group>
                          <span className={styles.error}>{errors.phone ? errors.phone : ''}</span>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.toolbar}>
                <Button type="submit" onClick={() => {
                  saveCustomQuote();
                }} className={styles.customOrderSubmitBtn}>
                  Submit
                </Button>
              </div>
            </div>
          )}


        </div>
      ) : (
        <div className={styles.table}>
          {(!hideInsuranceFields && (
            <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Transit Warranty</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Complementary transit extended warranty proctection up to $350.{' '}Additional protection can be purchased at checkout.
              Please review the <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/insurance" target="_blank" rel="noreferrer">Extended Liability Warranty page</a> for additional information.
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} className="border-0" style={{ backgroundColor: '#f9964b' }}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          ))}
          
          <Modal
            show={showAEThirdPartyInfoModal}
            onHide={handleCloseAEThirdPartyInfoModal}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Modal.Title>NOTICE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              The package will be collected/delivered by a third party courier managed by Allied Express.
              <br />
              <br />
              Please note third party pick ups / deliveries may be subject to delays.
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseAEThirdPartyInfoModal} className="border-0" style={{ backgroundColor: '#f9964b' }}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className={styles.head}>
            <div className={classNames(hideInsuranceFields ? styles.headingCellWithoutInsuranceSection: styles.headingCell)}>
              <Text weight="700">Carrier</Text>
            </div>
            <div className={classNames(hideInsuranceFields ? styles.headingCellWithoutInsuranceSection: styles.headingCell)}>
              <Text weight="700">Service</Text>
              <Text size="extraSmall" color="grey600" className={styles.smallHeading}>
                Estimated Delivery Time - Business days ONLY
              </Text>
            </div>
            <div className={hideInsuranceFields ? styles.headingCellWithoutInsuranceSection: styles.headingCell} style={{ textAlign: "center" }}>
              <Text weight="700">Price</Text>
              <Text size="extraSmall" color="grey600" className={styles.smallHeading}>
                (Inc. GST)
              </Text>
            </div>
            {!hideInsuranceFields && (
              <div className={styles.headingCell} style={{ textAlign: "center" }}>
                <Text weight="700">Transit Warranty</Text>
              </div>
            )}
            
            <div className={hideInsuranceFields ? styles.headingCellWithoutInsuranceSection: styles.headingCell} style={{ textAlign: "center" }}>
              <Text weight="700">Earliest collection date</Text>
            </div>
            <div className={classNames(hideInsuranceFields ? styles.headingCellWithoutInsuranceSection: styles.headingCell)}></div>
          </div>

          {quotes.map((quote) => (
            <div style={{ display: showQuotes ? 'block' : 'none' }}>
              <div key={quote.id} className={classNames(styles.row, { [styles.selected]: quote.id === quoteId })}>
                <div className={classNames(hideInsuranceFields ? styles.cellWithoutInsuranceSection : styles.cell)}>
                  <img className={styles.logo} alt="Courier Please" src={quote.logo} />
                </div>
                <div className={classNames(hideInsuranceFields ? styles.cellWithoutInsuranceSection : styles.cell)}>
                  <Text family="brand" weight="700">
                    {quote.name === 'Toll Road Express' ? 'Fast Courier Road Express' : quote.name === 'Toll Intermodal Express' ? 'Fast Courier Intermodal Express' : quote.name}
                    {quote.name === 'Allied Express Via Third Party Courier' && (
                      <span style={{ display: "inline-block" }} onClick={handleShowAEThirdPartyInfoModal}>
                        <InfoCircle className={styles.icon} />
                      </span>
                    )}
                  </Text>
                  {quote.subLabel && (
                    <Text className={styles.subLabel} color="grey600" weight="400" size="extraSmall">
                      {quote.subLabel}
                    </Text>
                  )}
                  <Text size="small">{quote.eta}</Text>

                  <ReactStars
                    count={5}
                    value={quote.rating}
                    half={true}
                    size={20}
                    edit={false}
                    color2={'#f76b00'} />
                </div>
                <div className={classNames(hideInsuranceFields ? styles.cellWithoutInsuranceSection : styles.cell, styles.priceCenter)} style={{ textAlign: "center" }}>
                  <p className={styles.priceTag}>{formatMoney(quote.priceIncludingGst)}</p>
                  <p className={styles.gstTag}>Inc. GST</p>
                </div>
                {!hideInsuranceFields && (
                <div className={classNames(styles.cell, styles.compText)}>
                  {quote.insuranceCategory && (
                    <div style={{ display: 'inline-block' }} className={styles.info}>
                      <span style={{ textAlign: "center", display: "block" }} onClick={handleShow}>
                        <InfoCircle className={styles.icon} />
                      </span>
                      <span style={{ fontSize: "14px", color: "rgb(74, 85, 104)" }} >
                        <a style={{ fontSize: "14px", color: "rgb(74, 85, 104)", textDecoration: "none" }} href={quote.insurance_link} target="_blank" rel="noreferrer">
                          Complementary cover<br /> up to $350 </a>
                      </span>
                    </div>
                  )}
                </div>
                )}
                <div className={hideInsuranceFields ? styles.cellWithoutInsuranceSection : styles.cell} style={{ color: '#4a5568', textAlign: "center" }} >
                {getCollectionAvailabilityText(quote, earliestCollectionDay, currentDay)}
                </div>
                <div className={classNames(hideInsuranceFields ? styles.cellWithoutInsuranceSection : styles.cell)}>
                  <CustomButton
                    fullWidth
                    size="medium"
                    onClick={() =>
                      save(quote.id, quote.courierName, quote.pickupCutOffTime)
                    }
                  >
                    Buy Now
                  </CustomButton>
                </div>
              </div>
            </div>
          ))}
          <Modal
            show={aeModalShow}
            onHide={handleAEModalClose}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Modal.Title>NOTICE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please note Allied Express <span style={{ color: '#f76b00' }}>DOES NOT</span> accept{' '}
              <span style={{ color: '#f76b00' }}>USED</span> Engines, transmissions, gearboxes, boat motors or any
              other used motor parts that are <span style={{ color: '#f76b00' }}>AT RISK OF LEAKING</span> during
              transportation. All freight also needs to be appropriately packaged in accordance to our packaging requirements listed here.
              <br />
              <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/packaging-large-heavy-irregular-freight" target="_blank" rel="noreferrer">https://fastcourier.com.au/packaging-large-heavy-irregular-freight</a>
              <br />
              <br />
              If your shipment contains the above, or is inappropriately packaged please make appropriate adjustments prior to shipping.
              <br />
              <br />
              To proceed, please consent to the below
              <br />
              <br />
              <div style={{ display: 'flex' }}>
                <input
                  type="checkbox"
                  checked={aeModalConsent}
                  onChange={() => setAEModalConsent(!aeModalConsent)}
                  style={{ marginTop: '3px', width: '28px', height: '15px', border: '1px solid #a0aec0' }}
                />
                <label style={{ paddingLeft: "10px" }}>I have read the <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/packaging-large-heavy-irregular-freight" target="_blank" rel="noreferrer">packaging requirements</a> and declare that this shipment is appropriately packaged.</label>
              </div>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleAEModalClose();
                  saveAEHunterQuote();
                }}
                className="border-0"
                style={{ backgroundColor: '#f9964b' }}
                disabled={aeModalConsent ? false : true}
              >
                Proceed
              </Button>
              <Button onClick={handleAEModalClose} className="border-0" style={{ backgroundColor: '#f9964b' }}>
                Choose Different Carrier
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={hunterExpressModalShow}
            onHide={handleHunterExpressModalClose}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Modal.Title>NOTICE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please note Hunter Express <span style={{ color: '#f76b00' }}>DOES NOT</span> accept{' '}
              <span style={{ color: '#f76b00' }}>USED</span> Engines,
              transmissions, gearboxes, boat motors or any other used motor parts that are <span style={{ color: '#f76b00' }}>NOT IN THEIR ORIGINAL PACKAGING </span> or are <span style={{ color: '#f76b00' }}>AT RISK OF LEAKING </span>during transportation. If your shipment contains the above, please select a different carrier.
              <br />
              <br />
              For all other shipments, please proceed.
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleHunterExpressModalClose();
                  saveAEHunterQuote();
                }}
                className="border-0"
                style={{ backgroundColor: '#f9964b' }}
              >
                Proceed
              </Button>
              <Button onClick={handleHunterExpressModalClose} className="border-0" style={{ backgroundColor: '#f9964b' }}>
                Choose Different Carrier
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={northlineModalShow}
            onHide={handleNorthlineModalClose}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Modal.Title>NOTICE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please note Northline <span style={{ color: '#f76b00' }}>DOES NOT</span> accept{' '}
              <span style={{ color: '#f76b00' }}>USED</span> Engines, transmissions, gearboxes, boat motors or any
              other used motor parts that are <span style={{ color: '#f76b00' }}>AT RISK OF LEAKING</span> during
              transportation. All freight also needs to be appropriately packaged in accordance to our packaging requirements listed here.
              <br />
              <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/packaging-large-heavy-irregular-freight" target="_blank" rel="noreferrer">https://fastcourier.com.au/packaging-large-heavy-irregular-freight</a>
              <br />
              <br />
              Please note that the maximum loose packages Northline can take are 10 otherwise they need to be palletised.
              <br />
              <br />
              <b>The maximum dimensions (DIMs) that a tailgate can hold is 2.3m length by 1.2m high by 2.3m high, with a max weight of 999kgs. As a result, anything that exceeds these dimensions/weight cannot be delivered using a tailgate.</b>
              <br />
              <br />
              If your shipment contains the above, or is inappropriately packaged please make appropriate adjustments prior to shipping.
              <br />
              <br />
              To proceed, please consent to the below
              <br />
              <br />
              <div style={{ display: 'flex' }}>
                <input
                  type="checkbox"
                  checked={northlineModalConsent}
                  onChange={() => setNorthlineModalConsent(!northlineModalConsent)}
                  style={{ marginTop: '3px', width: '28px', height: '15px', border: '1px solid #a0aec0' }}
                />
                <label style={{ paddingLeft: "10px" }}>I have read the <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/packaging-large-heavy-irregular-freight" target="_blank" rel="noreferrer">packaging requirements</a> and declare that this shipment is appropriately packaged.</label>
              </div>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleNorthlineModalClose();
                  saveAEHunterQuote();
                }}
                className="border-0"
                style={{ backgroundColor: '#f9964b' }}
                disabled={northlineModalConsent ? false : true}
              >
                Proceed
              </Button>
              <Button onClick={handleNorthlineModalClose} className="border-0" style={{ backgroundColor: '#f9964b' }}>
                Choose Different Carrier
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={tntModalShow}
            onHide={handleTNTModalClose}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Modal.Title>NOTICE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please note TNT FEDEX <span style={{ color: '#f76b00' }}>DOES NOT</span> accept{' '}
              <span style={{ color: '#f76b00' }}>USED</span> Engines, transmissions, gearboxes, boat motors or any
              other used motor parts that are <span style={{ color: '#f76b00' }}>AT RISK OF LEAKING</span> during
              transportation. All freight also needs to be appropriately packaged in accordance to our packaging requirements listed here.
              <br />
              <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/packaging-large-heavy-irregular-freight" target="_blank" rel="noreferrer">https://fastcourier.com.au/packaging-large-heavy-irregular-freight</a>
              <br />
              <br />
              If your shipment contains the above, or is inappropriately packaged please make appropriate adjustments prior to shipping.
              <br />
              <br />
              To proceed, please consent to the below
              <br />
              <br />
              <div style={{ display: 'flex' }}>
                <input
                  type="checkbox"
                  checked={tntModalConsent}
                  onChange={() => setTNTModalConsent(!tntModalConsent)}
                  style={{ marginTop: '3px', width: '28px', height: '15px', border: '1px solid #a0aec0' }}
                />
                <label style={{ paddingLeft: "10px" }}>I have read the <a style={{ color: '#f76b00' }} href="https://fastcourier.com.au/packaging-large-heavy-irregular-freight" target="_blank" rel="noreferrer">packaging requirements</a> and declare that this shipment is appropriately packaged.</label>
              </div>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleTNTModalClose();
                  saveAEHunterQuote();
                }}
                className="border-0"
                style={{ backgroundColor: '#f9964b' }}
                disabled={tntModalConsent ? false : true}
              >
                Proceed
              </Button>
              <Button onClick={handleTNTModalClose} className="border-0" style={{ backgroundColor: '#f9964b' }}>
                Choose Different Carrier
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
}
