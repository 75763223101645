import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import "./shipmentDetails.scss"
import { Button } from '../../components/Button/Button';
import { H2 } from '../../components/H2/H2';
import { Header } from '../../components/Header/Header';
import { Input } from '../../components/Input/Input';
import { OrderSummary } from '../../components/OrderSummary/OrderSummary';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { SenderTypes, Status, ValidationErrors } from '../../types';
import { useStore } from '../../store';
import * as api from '../../services/api';
import styles from './ShipmentDetails.module.scss';
import { track } from '../../util';
import classNames from 'classnames';
import { Form, Col, Row, Dropdown, Spinner } from 'react-bootstrap';
import Switch from 'react-switch';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

import {
  DEFAULT_ADDRESS_CHARACTER_LIMIT,
  COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT,
  STAR_TRACK_ADDRESS_CHARACTER_LIMIT,
  TNT_ADDRESS_CHARACTER_LIMIT,
  TNT_PHONE_CHARACTER_LIMIT,
  TOLL_ADDRESS_CHARACTER_LIMIT,
  ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT,
  COURIERS_PLEASE_COMAPNY_NAME_CHARACTER_LIMIT,
  COURIERS_PLEASE_FIRST_NAME_CHARACTER_LIMIT,
  COURIERS_PLEASE_LAST_NAME_CHARACTER_LIMIT,
  TOLL_COMAPNY_NAME_CHARACTER_LIMIT,
  TOLL_FIRST_NAME_CHARACTER_LIMIT,
  TOLL_LAST_NAME_CHARACTER_LIMIT,
  STAR_TRACK_COMAPNY_NAME_CHARACTER_LIMIT,
  STAR_TRACK_FIRST_NAME_CHARACTER_LIMIT,
  STAR_TRACK_LAST_NAME_CHARACTER_LIMIT,
  TOLL_PALLETISED_EXPRESS_FIRST_NAME_CHARACTER_LIMIT,
  TOLL_PALLETISED_EXPRESS_LAST_NAME_CHARACTER_LIMIT,
  TOLL_PALLETISED_EXPRESS_COMAPNY_NAME_CHARACTER_LIMIT,
  TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT,
  TOLL_PALLETISED_EXPRESS_PHONE_CHARACTER_LIMIT,
  BONDS_COURIER_FIRST_NAME_CHARACTER_LIMIT,
  BONDS_COURIER_LAST_NAME_CHARACTER_LIMIT,
  BONDS_COURIER_COMAPNY_NAME_CHARACTER_LIMIT,
  BONDS_COURIER_ADDRESS_CHARACTER_LIMIT,
  BONDS_COURIER_PHONE_CHARACTER_LIMIT,
  CAPITAL_TRANSPORT_FIRST_NAME_CHARACTER_LIMIT,
  CAPITAL_TRANSPORT_LAST_NAME_CHARACTER_LIMIT,
  CAPITAL_TRANSPORT_COMPANY_NAME_CHARACTER_LIMIT,
  CAPITAL_TRANSPORT_ADDRESS_CHARACTER_LIMIT,
  MRL_GLOBAL_ADDRESS_CHARACTER_LIMIT,
  MRL_GLOBAL_PHONE_CHARACTER_LIMIT
} from '../../config';
import { RadioGroup } from '../../components/RadioGroup/RadioGroup';
import moment from 'moment';
import Swal from 'sweetalert2';

type Props = {
  className?: string;
};

export function ShipmentDetails(props: Props) {
  const { className } = props;
  const orderId = useStore((state) => state.orderId);
  const senderType = useStore((state) => state.senderType);
  const pickupFirstName = useStore((state) => state.pickupFirstName);
  const pickupLastName = useStore((state) => state.pickupLastName);
  const pickupCompanyName = useStore((state) => state.pickupCompanyName);
  const pickupEmail = useStore((state) => state.pickupEmail);
  const pickupAddress1 = useStore((state) => state.pickupAddress1);
  const pickupAddress2 = useStore((state) => state.pickupAddress2);
  const pickupSuburb = useStore((state) => state.pickupSuburb);
  const pickupState = useStore((state) => state.pickupState);
  const pickupPostcode = useStore((state) => state.pickupPostcode);
  const pickupPhone = useStore((state) => state.pickupPhone);
  const pickupBuildingType = useStore((state) => state.pickupBuildingType);
  const pickupStreetName = useStore((state) => state.pickupStreetName);
  const pickupStreetNumber = useStore((state) => state.pickupStreetNumber);
  const destinationStreetName = useStore((state) => state.destinationStreetName);
  const destinationStreetNumber = useStore((state) => state.destinationStreetNumber);
  const destinationFirstName = useStore((state) => state.destinationFirstName);
  const destinationLastName = useStore((state) => state.destinationLastName);
  const destinationCompanyName = useStore((state) => state.destinationCompanyName);
  const destinationEmail = useStore((state) => state.destinationEmail);
  const destinationAddress1 = useStore((state) => state.destinationAddress1);
  const destinationAddress2 = useStore((state) => state.destinationAddress2);
  const destinationSuburb = useStore((state) => state.destinationSuburb);
  const destinationState = useStore((state) => state.destinationState);
  const destinationPostcode = useStore((state) => state.destinationPostcode);
  const destinationPhone = useStore((state) => state.destinationPhone);
  const destinationBuildingType = useStore((state) => state.destinationBuildingType);
  const pickupTimeWindow = useStore((state) => state.pickupTimeWindow);
  const collectionDate = useStore((state) => state.collectionDate);
  const parcelContent = useStore((state) => state.parcelContent);
  const specialInstructions = useStore((state) => state.specialInstructions);
  const valueOfContent = useStore((state) => state.valueOfContent);
  const extendedLiability = useStore((state) => state.extendedLiability);
  const authorityToLeave = useStore((state) => state.authorityToLeave);
  const noPrinter = useStore((state) => state.noPrinter);
  const dropOffPOBox = useStore((state) => state.dropOffPOBox);
  const items = useStore((state) => state.items);
  const insurances = useStore((state) => state.insurances);
  const insuranceValue = useStore((state) => state.insuranceValue);
  const insuranceFee = useStore((state) => state.insuranceFee);
  const courierServiceName = useStore((s) => s.courierServiceName);
  const reCreateOrder = useStore((state) => state.reCreateOrder);
  const quotePickupCutOffTime = useStore((state) => state.quotePickupCutOffTime);
  const sendSamePackage = useStore((state) => state.sendSamePackage);
  const hideInsuranceFields = useStore((state) => state.hideInsuranceFields);
  const earliestCollectionDay = useStore((state) => state.earliestCollectionDay);
  const setCurrentStep = useStore((state) => state.setCurrentStep);
  const setPickupFirstName = useStore((state) => state.setPickupFirstName);
  const setPickupLastName = useStore((state) => state.setPickupLastName);
  const setPickupCompanyName = useStore((state) => state.setPickupCompanyName);
  const setPickupEmail = useStore((state) => state.setPickupEmail);
  const setPickupAddress1 = useStore((state) => state.setPickupAddress1);
  const setPickupAddress2 = useStore((state) => state.setPickupAddress2);
  const setPickupPhone = useStore((state) => state.setPickupPhone);
  const setDestinationFirstName = useStore((state) => state.setDestinationFirstName);
  const setDestinationLastName = useStore((state) => state.setDestinationLastName);
  const setDestinationCompanyName = useStore((state) => state.setDestinationCompanyName);
  const setDestinationEmail = useStore((state) => state.setDestinationEmail);
  const setDestinationAddress1 = useStore((state) => state.setDestinationAddress1);
  const setDestinationAddress2 = useStore((state) => state.setDestinationAddress2);
  const setDestinationPhone = useStore((state) => state.setDestinationPhone);
  const setPickupTimeWindow = useStore((state) => state.setPickupTimeWindow);
  const setCollectionDate = useStore((state) => state.setCollectionDate);
  const setParcelContent = useStore((state) => state.setParcelContent);
  const setSpecialInstructions = useStore((state) => state.setSpecialInstructions);
  const setValueOfContent = useStore((state) => state.setValueOfContent);
  const setExtendedLiability = useStore((state) => state.setExtendedLiability);
  const setInsuranceValue = useStore((state) => state.setInsuranceValue);
  const setInsuranceFee = useStore((state) => state.setInsuranceFee);
  const setAuthorityToLeave = useStore((state) => state.setAuthorityToLeave);
  const setNoPrinter = useStore((state) => state.setNoPrinter);
  const setSenderType = useStore((state) => state.setSenderType);
  const setOrderId = useStore((state) => state.setOrderId);
  const setReCreateOrder = useStore((state) => state.setReCreateOrder);
  const setSendSamePackage = useStore((state) => state.setSendSamePackage);
  const setPickupStreetName = useStore((state) => state.setPickupStreetName);
  const setPickupStreetNumber = useStore((state) => state.setPickupStreetNumber);
  const setDestinationStreetName = useStore((state) => state.setDestinationStreetName);
  const setDestinationStreetNumber = useStore((state) => state.setDestinationStreetNumber);
  const [courierServicename, setCourierServiceName] = useState('');
  const [noPrinterError, setNoPrinterError] = useState('');
  const [pickupGoogleAddressSelected, setPickupGoogleAddressSelected] = useState(false);
  const [destinationGoogleAddressSelected, setDestinationGoogleAddressSelected] = useState(false);
  const [collectionMinDate, setCollectionMinDate] = useState('');
  const history = useHistory();
  const [status, setStatus] = useState<Status>('idle');
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [specialInstructionsCounter, setSpecialInstructionsCounter] = useState(30);
  const currentDay = new Date().getDay();
  const [publicHolidays, setPublicHolidays] = useState(['']);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [pickUpPlaceSuggestions,setPickupPlaceSuggestions] = useState<any>([]);
  const [destinationPlaceSuggestions,setDestinationPlaceSuggestions] = useState<any>([]);
  const [loading, setLoading] = useState('');
  const [showAddressDropdown, setShowAddressDropdown] = useState('');
  const [selectionLoading, setSelectionLoading] = useState(''); // State to track address selection loading for both input boxes

  const handleClick = () => {
    // Disable the back button
    window.history.pushState(null, '', window.location.href);

    // Reset the scroll position to the top
    window.scrollTo(0, 0);

    // Handle the popstate event to prevent scrolling
    window.onpopstate = function (event: PopStateEvent) {
      // Prevent the default behavior of the back button
      event.preventDefault();

      // Reset the scroll position to the top
      window.scrollTo(0, 0);

      // Disable the back button again
      window.history.pushState(null, '', window.location.href);
    };
  };

  const PICKUP_TIME_WINDOW = [
    {
      value: 'Please Select',
    },
    {
      value: '9am to 5pm',
    },
    {
      value: '12pm to 5pm',
    },
  ];

  let authorityToLeavedisclaimerStyle = {
    color: '#3c3d40',
    fontSize: '11px',
    display: 'block',
    marginBottom: '20px',
  };
  let noPrinterdisclaimerStyle = {
    color: '#3c3d40',
    fontSize: '11px',
    display: 'block',
  };
  let parscelContentDisclaimer = {
    color: '#3c3d40',
    fontSize: '11px',
    display: 'block',
    marginTop: '7px',
    marginBottom: '0px',
  };
  let extendedLiabilityDisclaimer = {
    color: '#3c3d40',
    fontSize: '11px',
    display: 'block',
    marginTop: '7px',
    marginBottom: '0px',
    paddingBottom: '10px',
  };

  let hideMannualConnoteOptionCouriers = [
    'TNT',
    'AlliedExpress',
    'Hunter Express',
    'Direct Couriers',
    'Zoom2u',
    'Northline',
    'Josies Transport',
    'Felix Transport',
    'Capital Transport',
    'Northline Express',
    'Star Track',
  ];

  const checkSelectedDateIsWeekend = (
    value: Date,
    courierServiceName: string,
    publicHolidays: string[],
    collectionMinDate: any,
    earliestCollectionDays: string[]
  ) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
    const givenDate = new Date(value);
    const month = '' + (givenDate.getMonth() + 1);
    const day = '' + givenDate.getDate();
    const year = givenDate.getFullYear();
  
    const formattedMonth = month.length < 2 ? '0' + month : month;
    const formattedDay = day.length < 2 ? '0' + day : day;
  
    const date = [year, formattedMonth, formattedDay].join('-');
    const selectedDay = givenDate.getDay();
  
    if (courierServiceName === 'MRL Global' && !earliestCollectionDays.includes(days[selectedDay]) && earliestCollectionDays[0] !== 'Daily') {
      setErrors({ collectionDate: [`Only ${earliestCollectionDays.join(', ')} are allowed.`] });
      return;
    }
  
    if (selectedDay === 6 || selectedDay === 0) {
      setErrors({ collectionDate: ['Weekend date is not allowed.'] });
      return;
    } else if (publicHolidays.includes(date)) {
      setErrors({ collectionDate: ['Public holiday date is not allowed.'] });
      return;
    } else if (
      (courierServiceName === 'Toll' || courierServiceName === 'Toll Palletised Express') &&
      new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000) < givenDate
    ) {
      setErrors({ collectionDate: ['Date should not be greater than 7 days.'] });
      return;
    } else if (moment(value).isBefore(moment(collectionMinDate))) {
      setErrors({ collectionDate: ['Date should not be less than ' + collectionMinDate] });
      return;
    } else {
      setErrors({});
    }
    setCollectionDate(day + '-' + month + '-' + year);
  };


  const getTodaydate = (courierServiceName: string, quotePickupCutOffTime: string, earliestCollectionDays: string[]) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
    const getNextSelectableDay = (date: Date, selectableDays: string[]): Date => {
      let nextDay = new Date(date);
      while (!selectableDays.includes(days[nextDay.getDay()])) {
        nextDay.setDate(nextDay.getDate() + 1);
      }
      return nextDay;
    };
  
    let today = new Date();
  
    if (
      [
        'TNT', 'Toll', 'Star Track', 'Couriers Please', 'Direct Couriers', 'Toll Palletised Express', 'Zoom2u',
        'Northline', 'Northline Express', 'Josies Transport', 'CTI Logistics Regional Freight', 'Felix Transport',
        'Bonds Couriers', 'Josies Transport', 'Capital Transport', 'MRL Global'
      ].includes(courierServiceName) &&
      currentDay !== 6 &&
      currentDay !== 0 &&
      !moment().isAfter(moment(quotePickupCutOffTime, 'HH:mm'))
    ) {
      today = new Date();
    } else {
      today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    }
  
    if (courierServiceName === 'MRL Global' && earliestCollectionDays.length > 0 && earliestCollectionDays[0] !== 'Daily') {
      today = getNextSelectableDay(today, earliestCollectionDays);
    }
  
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = today.getFullYear();
    let date = yyyy + '-' + mm + '-' + dd;
  
    if (!collectionMinDate) {
      setCollectionMinDate(date);
    }
  
    return new Date(date);
  };


  //check print labels option applicable or not for courier service
  const checkApplicableToSetNoPrinter = (value: any) => {
    if (
      (courierServiceName === 'AlliedExpress' && value === true) ||
      (courierServiceName === 'Hunter Express' && value === true) ||
      (courierServiceName === 'Direct Couriers' && value === true) ||
      (courierServiceName === 'Zoom2u' && value === true) ||
      (courierServiceName === 'Felix Transport' && value === true) ||
      (courierServiceName === 'Northline' && value === true) ||
      (courierServiceName === 'Northline Express' && value === true) ||
      (courierServiceName === 'Capital Transport' && value === true) ||
      (courierServiceName === 'TNT' && value === true) ||
      (courierServiceName === 'Star Track' && value === true)
    ) {
      if (
        courierServiceName === 'Direct Couriers' ||
        courierServiceName === 'Zoom2u' ||
        courierServiceName === 'Felix Transport'
      ) {
        setNoPrinterError('Courier does not require labels to collect items.');
      } else {
        setNoPrinterError('Option not available for this carrier.');
      }
      return;
    }
    setNoPrinter(value);
  };

  //set address fields max value
  const setMaxValueOfAddress = () => {
    return courierServiceName === 'TNT'
      ? (setPickupAddress1(pickupAddress1.substring(0, TNT_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, TNT_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, TNT_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, TNT_ADDRESS_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll'
      ? (setPickupAddress1(pickupAddress1.substring(0, TOLL_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, TOLL_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, TOLL_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, TOLL_ADDRESS_CHARACTER_LIMIT)))
      : courierServiceName === 'Star Track'
      ? (setPickupAddress1(pickupAddress1.substring(0, STAR_TRACK_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, STAR_TRACK_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, STAR_TRACK_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, STAR_TRACK_ADDRESS_CHARACTER_LIMIT)))
      : courierServiceName === 'Couriers Please'
      ? (setPickupAddress1(pickupAddress1.substring(0, COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT)))
      : courierServiceName === 'AlliedExpress'
      ? (setPickupAddress1(pickupAddress1.substring(0, ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll Palletised Express'
      ? (setPickupAddress1(pickupAddress1.substring(0, TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT)))
      : courierServiceName === 'Bonds Couriers'
      ? (setPickupAddress1(pickupAddress1.substring(0, BONDS_COURIER_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, BONDS_COURIER_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, BONDS_COURIER_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, BONDS_COURIER_ADDRESS_CHARACTER_LIMIT)))
      : courierServiceName === 'Zoom2u' || courierServiceName === 'Felix Transport' || courierServiceName === 'Aramex'
      ? (setPickupAddress1(pickupAddress1),
        setPickupAddress2(pickupAddress2),
        setDestinationAddress1(destinationAddress1),
        setDestinationAddress2(destinationAddress2))
      : courierServiceName === 'Capital Transport'
      ? (setPickupAddress1(pickupAddress1.substring(0, CAPITAL_TRANSPORT_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, CAPITAL_TRANSPORT_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, CAPITAL_TRANSPORT_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, CAPITAL_TRANSPORT_ADDRESS_CHARACTER_LIMIT)))
        : courierServiceName === 'MRL Global'
        ? (setPickupAddress1(pickupAddress1.substring(0, MRL_GLOBAL_ADDRESS_CHARACTER_LIMIT)),
          setPickupAddress2(pickupAddress2.substring(0, MRL_GLOBAL_ADDRESS_CHARACTER_LIMIT)),
          setDestinationAddress1(destinationAddress1.substring(0, MRL_GLOBAL_ADDRESS_CHARACTER_LIMIT)),
          setDestinationAddress2(destinationAddress2.substring(0, MRL_GLOBAL_ADDRESS_CHARACTER_LIMIT)))
      : (setPickupAddress1(pickupAddress1.substring(0, DEFAULT_ADDRESS_CHARACTER_LIMIT)),
        setPickupAddress2(pickupAddress2.substring(0, DEFAULT_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress1(destinationAddress1.substring(0, DEFAULT_ADDRESS_CHARACTER_LIMIT)),
        setDestinationAddress2(destinationAddress2.substring(0, DEFAULT_ADDRESS_CHARACTER_LIMIT)));
  };

  //set first name fields max value
  const setMaxValueOfFirstName = () => {
    return courierServiceName === 'TNT'
      ? (setPickupFirstName(pickupFirstName.substring(0, 15)),
        setDestinationFirstName(destinationFirstName.substring(0, 15)))
      : courierServiceName === 'Couriers Please'
      ? (setPickupFirstName(pickupFirstName.substring(0, COURIERS_PLEASE_FIRST_NAME_CHARACTER_LIMIT)),
        setDestinationFirstName(destinationFirstName.substring(0, COURIERS_PLEASE_FIRST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll'
      ? (setPickupFirstName(pickupFirstName.substring(0, TOLL_FIRST_NAME_CHARACTER_LIMIT)),
        setDestinationFirstName(destinationFirstName.substring(0, TOLL_FIRST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Star Track'
      ? (setPickupFirstName(pickupFirstName.substring(0, STAR_TRACK_FIRST_NAME_CHARACTER_LIMIT)),
        setDestinationFirstName(destinationFirstName.substring(0, STAR_TRACK_FIRST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll Palletised Express'
      ? (setPickupFirstName(pickupFirstName.substring(0, TOLL_PALLETISED_EXPRESS_FIRST_NAME_CHARACTER_LIMIT)),
        setDestinationFirstName(destinationFirstName.substring(0, TOLL_PALLETISED_EXPRESS_FIRST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Bonds Couriers'
      ? (setPickupFirstName(pickupFirstName.substring(0, BONDS_COURIER_FIRST_NAME_CHARACTER_LIMIT)),
        setDestinationFirstName(destinationFirstName.substring(0, BONDS_COURIER_FIRST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Capital Transport'
      ? (setPickupFirstName(pickupFirstName.substring(0, CAPITAL_TRANSPORT_FIRST_NAME_CHARACTER_LIMIT)),
        setDestinationFirstName(destinationFirstName.substring(0, CAPITAL_TRANSPORT_FIRST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'MRL Global'
      ? (setPickupFirstName(pickupFirstName.substring(0, 20)),
        setDestinationFirstName(destinationFirstName.substring(0, 20)))
      : '';
  };

  //set last name fields max value
  const setMaxValueOfLastName = () => {
    return courierServiceName === 'TNT'
      ? (setPickupLastName(pickupLastName.substring(0, 14)),
        setDestinationLastName(destinationLastName.substring(0, 14)))
      : courierServiceName === 'Couriers Please'
      ? (setPickupLastName(pickupLastName.substring(0, COURIERS_PLEASE_LAST_NAME_CHARACTER_LIMIT)),
        setDestinationLastName(destinationLastName.substring(0, COURIERS_PLEASE_LAST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll'
      ? (setPickupLastName(pickupLastName.substring(0, TOLL_LAST_NAME_CHARACTER_LIMIT)),
        setDestinationLastName(destinationLastName.substring(0, TOLL_LAST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Star Track'
      ? (setPickupLastName(pickupLastName.substring(0, STAR_TRACK_LAST_NAME_CHARACTER_LIMIT)),
        setDestinationLastName(destinationLastName.substring(0, STAR_TRACK_LAST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll Palletised Express'
      ? (setPickupLastName(pickupLastName.substring(0, TOLL_PALLETISED_EXPRESS_LAST_NAME_CHARACTER_LIMIT)),
        setDestinationLastName(destinationLastName.substring(0, TOLL_PALLETISED_EXPRESS_LAST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Bonds Couriers'
      ? (setPickupLastName(pickupLastName.substring(0, BONDS_COURIER_LAST_NAME_CHARACTER_LIMIT)),
        setDestinationLastName(destinationLastName.substring(0, BONDS_COURIER_LAST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Capital Transport'
      ? (setPickupLastName(pickupLastName.substring(0, CAPITAL_TRANSPORT_LAST_NAME_CHARACTER_LIMIT)),
        setDestinationLastName(destinationLastName.substring(0, CAPITAL_TRANSPORT_LAST_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'MRL Global'
      ? (setPickupLastName(pickupLastName.substring(0, 20)),
        setDestinationLastName(destinationLastName.substring(0, 20)))
      : '';
  };

  //set company name fields max value
  const setMaxValueOfCompanyName = () => {
    return courierServiceName === 'TNT'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, 30)),
        setDestinationCompanyName(destinationCompanyName.substring(0, 30)))
      : courierServiceName === 'Couriers Please'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, COURIERS_PLEASE_COMAPNY_NAME_CHARACTER_LIMIT)),
        setDestinationCompanyName(destinationCompanyName.substring(0, COURIERS_PLEASE_COMAPNY_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Star Track'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, STAR_TRACK_COMAPNY_NAME_CHARACTER_LIMIT)),
        setDestinationCompanyName(destinationCompanyName.substring(0, STAR_TRACK_COMAPNY_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, TOLL_COMAPNY_NAME_CHARACTER_LIMIT)),
        setDestinationCompanyName(destinationCompanyName.substring(0, TOLL_COMAPNY_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Toll Palletised Express'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, TOLL_PALLETISED_EXPRESS_COMAPNY_NAME_CHARACTER_LIMIT)),
        setDestinationCompanyName(
          destinationCompanyName.substring(0, TOLL_PALLETISED_EXPRESS_COMAPNY_NAME_CHARACTER_LIMIT)
        ))
      : courierServiceName === 'Bonds Couriers'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, BONDS_COURIER_COMAPNY_NAME_CHARACTER_LIMIT)),
        setDestinationCompanyName(destinationCompanyName.substring(0, BONDS_COURIER_COMAPNY_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'Capital Transport'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, CAPITAL_TRANSPORT_COMPANY_NAME_CHARACTER_LIMIT)),
        setDestinationCompanyName(destinationCompanyName.substring(0, CAPITAL_TRANSPORT_COMPANY_NAME_CHARACTER_LIMIT)))
      : courierServiceName === 'MRL Global'
      ? (setPickupCompanyName(pickupCompanyName.substring(0, 30)),
        setDestinationCompanyName(destinationCompanyName.substring(0, 30)))
      : '';
  };

  //set phone number fields max value
  const setMaxValueOfPhone = () => {
    return courierServiceName === 'Toll Palletised Express'
      ? (setPickupPhone(pickupPhone.toString().substring(0, TOLL_PALLETISED_EXPRESS_PHONE_CHARACTER_LIMIT)),
        setDestinationPhone(destinationPhone.toString().substring(0, TOLL_PALLETISED_EXPRESS_PHONE_CHARACTER_LIMIT)))
      : courierServiceName === 'Bonds Couriers'
      ? (setPickupPhone(pickupPhone.toString().substring(0, BONDS_COURIER_PHONE_CHARACTER_LIMIT)),
        setDestinationPhone(destinationPhone.toString().substring(0, BONDS_COURIER_PHONE_CHARACTER_LIMIT)))
      : '';
  };

  //set address fields length value
  const setMaxLengthOfAddress = () => {
    return courierServiceName === 'TNT'
      ? TNT_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'Toll'
      ? TOLL_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'Star Track'
      ? STAR_TRACK_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'Couriers Please'
      ? COURIERS_PLEASE_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'AlliedExpress'
      ? ALLIED_EXPRESS_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'Toll Palletised Express'
      ? TOLL_PALLETISED_EXPRESS_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'Bonds Couriers'
      ? BONDS_COURIER_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'Capital Transport'
      ? CAPITAL_TRANSPORT_ADDRESS_CHARACTER_LIMIT
      : courierServiceName === 'MRL Global'
      ? MRL_GLOBAL_ADDRESS_CHARACTER_LIMIT
      : DEFAULT_ADDRESS_CHARACTER_LIMIT;
  };

  //set first name fields length value
  const setMaxLengthOfFirstName = () => {
    return courierServiceName === 'TNT'
      ? 15
      : courierServiceName === 'Couriers Please'
      ? COURIERS_PLEASE_FIRST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Toll'
      ? TOLL_FIRST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Star Track'
      ? STAR_TRACK_FIRST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Toll Palletised Express'
      ? TOLL_PALLETISED_EXPRESS_FIRST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Bonds Couriers'
      ? BONDS_COURIER_FIRST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Capital Transport'
      ? CAPITAL_TRANSPORT_FIRST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'MRL Global'
      ? 20
      : undefined;
  };

  //set last name fields length value
  const setMaxLengthOfLastName = () => {
    return courierServiceName === 'TNT'
      ? 14
      : courierServiceName === 'Couriers Please'
      ? COURIERS_PLEASE_LAST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Toll'
      ? TOLL_LAST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Star Track'
      ? STAR_TRACK_LAST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Toll Palletised Express'
      ? TOLL_PALLETISED_EXPRESS_LAST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Bonds Couriers'
      ? BONDS_COURIER_LAST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Capital Transport'
      ? CAPITAL_TRANSPORT_LAST_NAME_CHARACTER_LIMIT
      : courierServiceName === 'MRL Global'
      ? 20
      : undefined;
  };

  //set company name fields length value
  const setMaxLengthOfCompanyName = () => {
    return courierServiceName === 'TNT'
      ? 30
      : courierServiceName === 'Couriers Please'
      ? COURIERS_PLEASE_COMAPNY_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Toll'
      ? TOLL_COMAPNY_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Star Track'
      ? STAR_TRACK_COMAPNY_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Toll Palletised Express'
      ? TOLL_PALLETISED_EXPRESS_COMAPNY_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Bonds Couriers'
      ? BONDS_COURIER_COMAPNY_NAME_CHARACTER_LIMIT
      : courierServiceName === 'Capital Transport'
      ? CAPITAL_TRANSPORT_COMPANY_NAME_CHARACTER_LIMIT
      : courierServiceName === 'MRL Global'
      ? 30
      : undefined;
  };

  //set phone number fields length value
  const setMaxLengthOfPhone = () => {
    return courierServiceName === 'Toll Palletised Express'
      ? TOLL_PALLETISED_EXPRESS_PHONE_CHARACTER_LIMIT
      : courierServiceName === 'Bonds Couriers'
      ? BONDS_COURIER_PHONE_CHARACTER_LIMIT
      : courierServiceName === 'TNT'
      ? TNT_PHONE_CHARACTER_LIMIT
      : courierServiceName === 'MRL Global'
      ? MRL_GLOBAL_PHONE_CHARACTER_LIMIT
      : undefined;
  };

  const validatePhone = async (pickupPhone: any, destinationPhone: any) => {
    setStatus('in-progress');
    let error: any = false;
    if (courierServiceName === 'Toll Palletised Express') {
      let errors: any = {};
      if (pickupPhone.toString().length < 10) {
        error = true;
        errors['pickupPhone'] = ['Add an area code or fix number'];
      }
      if (destinationPhone.toString().length < 10) {
        error = true;
        errors['destinationPhone'] = ['Add an area code or fix number'];
      }
      if (error) {
        setTimeout(() => {
          setStatus('error');
          setErrors(errors);
          return false;
        }, 2000);
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const save = () => {
    handleClick();
    setNoPrinterError('');
    (async () => {
      if (
        pickupAddress1 !== '' &&
        destinationAddress1 !== '' &&
        (courierServiceName === 'Zoom2u' || courierServiceName === 'Felix Transport') &&
        (!pickupGoogleAddressSelected || !destinationGoogleAddressSelected)
      ) {
        let errorText = '';
        if (!pickupGoogleAddressSelected) {
          errorText = errorText + 'Pickup ';
        }
        if (!pickupGoogleAddressSelected && !destinationGoogleAddressSelected) {
          errorText = errorText + ' and Destination ';
        } else if (!destinationGoogleAddressSelected) {
          errorText = errorText + 'Destination ';
        }
        Swal.fire({
          title: 'Warning',
          text: errorText + 'address is invalid. Please select from google suggestion addresses.',
          icon: 'warning',
          confirmButtonColor: '#f76b00',
          confirmButtonText: 'OK',
        });
      } else {
        var shipmentDate =
          collectionDate !== '' ? moment(collectionDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : collectionDate;

        const payload = {
          pickupAddress1: pickupAddress1,
          pickupAddress2: pickupAddress2,
          pickupFirstName: pickupFirstName,
          pickupLastName: pickupLastName,
          pickupEmail: pickupEmail,
          pickupPhone: pickupPhone,
          pickupSuburb: pickupSuburb,
          pickupState: pickupState,
          pickupPostcode: pickupPostcode,
          destinationAddress1: destinationAddress1,
          destinationAddress2: destinationAddress2,
          destinationFirstName: destinationFirstName,
          destinationLastName: destinationLastName,
          destinationEmail: destinationEmail,
          destinationPhone: destinationPhone,
          destinationSuburb: destinationSuburb,
          destinationState: destinationState,
          destinationPostcode: destinationPostcode,
          collectionDate: shipmentDate,
          pickupTimeWindow: pickupTimeWindow,
          shipmentContents: parcelContent,
          specialInstructions: specialInstructions,
          insurance: valueOfContent,
          authorityToLeave: authorityToLeave,
          poBox: dropOffPOBox,
          noLabels: noPrinter,
          items: items,
        };
        try {
          const phoneValidateResponse = await validatePhone(pickupPhone, destinationPhone);

          if (phoneValidateResponse) {
            let hashId: any;
            hashId = orderId;
            setStatus('in-progress');
            if (reCreateOrder) {
              const reCreateOrderRecord = await api.post<any>(`/order/${orderId}/reCreateOrder`, {});
              hashId = reCreateOrderRecord.id;
              setOrderId(reCreateOrderRecord.id);
              setReCreateOrder(false);
            }
            await api.csrf();
            await api.patch(`/order/${hashId}/shipment-details`, {
              senderType,
              pickupFirstName,
              pickupLastName,
              pickupCompanyName,
              pickupEmail,
              pickupAddress1,
              pickupAddress2,
              pickupStreetName,
              pickupStreetNumber,
              pickupPhone,
              destinationFirstName,
              destinationLastName,
              destinationCompanyName,
              destinationEmail,
              destinationAddress1,
              destinationAddress2,
              destinationStreetName,
              destinationStreetNumber,
              destinationPhone,
              collectionDate: shipmentDate,
              pickupTimeWindow,
              parcelContent,
              specialInstructions,
              valueOfContent,
              extendedLiability,
              insuranceValue,
              insuranceFee,
              authorityToLeave,
              noPrinter,
            });

            track('app-shipment-details', { payload });
            history.push('/additional-info');
          }
        } catch (e: any) {
          setStatus('error');
          setErrors(e?.response?.data?.errors ?? {});
        }
      }
    })();
  };

  useEffect(() => {
    let currentCurl = window.location.href;
    let serviceName = currentCurl.split('?')[1];
    setCourierServiceName(serviceName);
    if (sendSamePackage) {
      setCollectionDate('');
      setPickupTimeWindow('');
      setSendSamePackage(false);
    }
    if (!orderId) {
      history.push('/package-details');
      return;
    }
    if (
      courierServiceName === 'Zoom2u' ||
      courierServiceName === 'Felix Transport' ||
      courierServiceName === 'Aramex'
    ) {
      setPickupAddress2('');
      setDestinationAddress2('');
    }

    if (courierServiceName !== 'Zoom2u' && courierServiceName !== 'Felix Transport') {
      setMaxValueOfAddress();
    }

    setMaxValueOfCompanyName();
    setMaxValueOfFirstName();
    setMaxValueOfLastName();
    setMaxValueOfPhone();
    setCurrentStep(2);
    //set manual connote option false if courier name exist in hide couriers options
    if(hideMannualConnoteOptionCouriers.includes(courierServiceName)){
      setNoPrinter(false);
    }

    //get public holidays list
   getPublicHolidaysList();

  }, [history, orderId, setCurrentStep]);

  async function getPublicHolidaysList() {
    const publicHolidaysList: any = await api.get<any>('/public-holidays');

    if(publicHolidaysList.data){
      setPublicHolidays(publicHolidaysList.data);
    }
  }

  const handleChange = (value: any, type: string) => {
    if(value.length < 3){
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
 
    const newTimeoutId = setTimeout(() => {
      validateAddress(type, value.length);
    }, 700); 

    setTimeoutId(newTimeoutId);
  };

   async function validateAddress(addressType: string, characters: any) {
    if (addressType === 'pickup') {
      setPickupGoogleAddressSelected(false);
    } else {
      setDestinationGoogleAddressSelected(false);
    }

    if(characters < 3){
      return;
    }
    
    let input = document.getElementById(addressType + 'Address1') as HTMLInputElement;
    
    if (input.value.length < 3) {
      return
    }
    setLoading(addressType); // Set loading state

    const suggestions: any = await api.get<any>(`/google-autocomplete?input=${input.value}`);

    if (addressType === 'pickup') {
      setPickupPlaceSuggestions(suggestions.predictions);
      // Check for dropdown-menu show class and add it if not exists
      // const dropdownMenu = document.querySelector('.dropdown-menu.show');
      // if (!dropdownMenu) {
      //   const pickupDropdown = document.querySelector('.pickUpAddressDropdown .dropdown-menu');
      //   if (pickupDropdown) {
      //     pickupDropdown.classList.add('show');
      //   }
      // }
    } else {
      setDestinationPlaceSuggestions(suggestions.predictions);
      // Check for dropdown-menu show class and add it if not exists
      // const dropdownMenu = document.querySelector('.dropdown-menu.show');
      // if (!dropdownMenu) {
      //   const destinationDropdown = document.querySelector('.destinationAddressDropdown .dropdown-menu'); // Make sure to update the selector as per your structure
      //   if (destinationDropdown) {
      //     destinationDropdown.classList.add('show');
      //   }
      // }
    }

    setLoading('');
  }

  async function selectedAddress(address: any, addressType: any){
      setSelectionLoading(addressType);
      const place: any = await api.get<any>(`place-details?place_id=${address.place_id}`);
      
      var formatted_address = place.result.formatted_address;
      var components = place.result.address_components;
      setPickupPlaceSuggestions([])

      if (addressType === 'pickup') {
        setPickupAddress1(formatted_address);
        setPickupStreetName('');
        setPickupStreetNumber('');
      } else {
        setDestinationAddress1(formatted_address);
        setDestinationStreetName('');
        setDestinationStreetNumber('');
      }

      if (components) {
        let invalidSuburb = false;
        let invalidPostcode = false;
        let streetNumberSet = false;
        let suburb = addressType === 'pickup' ? pickupSuburb : destinationSuburb;
        let postcode = addressType === 'pickup' ? pickupPostcode : destinationPostcode;

        for (var i = 0; i < components.length; i++) {
          if (components[i].types[0] === 'locality') {
            if (
              components[i]['long_name'].toUpperCase() !== suburb &&
              components[i]['short_name'].toUpperCase() !== suburb
            ) {
              invalidSuburb = true;
            }
          }


          if (components[i].types[0] === 'postal_code') {
            if (components[i]['long_name'] !== postcode) {
              invalidPostcode = true;
            }
          }
          //set street name
          if (components[i].types[0] === 'route') {
            if (addressType === 'pickup') {
              setPickupStreetName(components[i]['long_name']);
              var pickupStreetName = document.getElementById(addressType + 'StreetName') as HTMLInputElement;
              pickupStreetName.style.backgroundColor = '#edf2f7';
            } else {
              setDestinationStreetName(components[i]['long_name']);
              var destinationStreetName = document.getElementById(addressType + 'StreetName') as HTMLInputElement;
              destinationStreetName.style.backgroundColor = '#edf2f7';
            }
          }


          //set street number
          if (components[i].types[0] === 'street_number') {
            streetNumberSet = true;
            if (addressType === 'pickup') {
              if(components[0].types[0] === 'subpremise'){
                setPickupStreetNumber(components[0]['long_name']+'/'+components[i]['long_name']);
              }else{
                setPickupStreetNumber(components[i]['long_name']);
              }
              var pickupStreetNumber = document.getElementById(addressType + 'StreetNumber') as HTMLInputElement;
              pickupStreetNumber.style.backgroundColor = '#edf2f7';
              
            } else {
              if(components[0].types[0] === 'subpremise'){
                setDestinationStreetNumber(components[0]['long_name']+'/'+components[i]['long_name']);
              }else{
                setDestinationStreetNumber(components[i]['long_name']);
              }
              
              var destinationStreetNumber = document.getElementById(addressType + 'StreetNumber') as HTMLInputElement;
              destinationStreetNumber.style.backgroundColor = '#edf2f7';
            }
          }

        }

        if (invalidSuburb || invalidPostcode) {
          let errorText = '';
          let showCancelBtn = false;
          if (invalidSuburb) {
            errorText = errorText + 'suburb';
          }
          if (invalidSuburb && invalidPostcode) {
            errorText = errorText + ' and postcode';
          } else if (invalidPostcode) {
            errorText = errorText + 'postcode';
          }

          if (invalidSuburb || invalidPostcode) {
            errorText =
              'The address selected is not in the same ' +
              errorText +
              ' that you requested a quote for. Please search for a different address or go back to the previous step to change the ' +
              errorText +
              '.';
          }

          if (invalidSuburb || invalidPostcode) {
            showCancelBtn = true;
          } else {
            showCancelBtn = false;
          }

          Swal.fire({
            title: 'Warning',
            text: errorText,
            icon: 'warning',
            showCancelButton: showCancelBtn,
            cancelButtonText: 'Change Suburb',
            confirmButtonColor: '#f76b00',
            cancelButtonColor: '#f76b00',
            confirmButtonText: streetNumberSet ? 'Stay Here' : 'OK',
          }).then((result) => {
            if (addressType === 'pickup') {
              setPickupAddress1('');
              setPickupStreetName('');
              setPickupStreetNumber('');
            } else {
              setDestinationAddress1('');
              setDestinationStreetName('');
              setDestinationStreetNumber('');
            }
            if (result.isConfirmed === false) {
              history.push('/package-details');
              return;
            }
          });
        } else {
          if (addressType === 'pickup') {
            setPickupGoogleAddressSelected(true);
          } else {
            setDestinationGoogleAddressSelected(true);
          }
        }
      }

      setSelectionLoading('');
    
  }
  return (
    <>
      <Header />
      <OverlaySpinner enabled={status === 'in-progress'} />
      <OrderSummary
        className={styles.orderSummary}
        packageDetailsEditEnabled={true}
        quoteEditEnabled={true}
        addressDetailsEditEnabled={false}
      />
      <H2 className={styles.header}>Booking Party</H2>
      <RadioGroup
        name="Send Type"
        className={styles.sender}
        label="Are you?"
        onChange={setSenderType}
        value={senderType}
        options={SenderTypes}
      />
      <div className={styles.columns}>
        <div className={styles.column}>
          <H2 className={styles.heading}>Collect from</H2>
          <Input
            className={styles.input}
            layout="horizontal"
            label="First Name"
            name="pickupFirstName"
            value={pickupFirstName}
            onChange={setPickupFirstName}
            placeholder="First Name"
            maxLength={setMaxLengthOfFirstName()}
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Last Name"
            maxLength={setMaxLengthOfLastName()}
            name="pickupLastName"
            value={pickupLastName}
            onChange={setPickupLastName}
            placeholder="Last Name"
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Company Name"
            name="pickupCompanyName"
            maxLength={setMaxLengthOfCompanyName()}
            value={pickupCompanyName}
            onChange={setPickupCompanyName}
            placeholder="Company Name"
            errors={errors}
            required={pickupBuildingType === 'commercial'}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Email"
            name="pickupEmail"
            value={pickupEmail}
            onChange={setPickupEmail}
            placeholder="Email"
            errors={errors}
            required
          />
          <Dropdown className={"pickUpAddressDropdown"}
          show={showAddressDropdown === 'pickup' && pickUpPlaceSuggestions?.length>0}
          onToggle={(e,event)=>{
            if (e) {
              setShowAddressDropdown("pickup");
              }else{
                setShowAddressDropdown("");
            }
          }}
          >
            <Dropdown.Toggle variant="light">
              <div style={{ position: 'relative' }}>
                <Input
                    className={styles.input}
                    layout="horizontal"
                    label="Address"
                    name="pickupAddress1"
                    value={pickupAddress1}
                    maxLength={setMaxLengthOfAddress()}
                    onChange={(e) => {
                      if (
                        courierServiceName === 'Zoom2u' ||
                        courierServiceName === 'Felix Transport' ||
                        courierServiceName === 'Aramex'
                      ) {
                        setPickupAddress1(e);
                        setTimeout(() => {
                          setShowAddressDropdown("pickup"); 
                        }, 10);
                        if (e.length<3) {
                          return 
                        }
                        handleChange(e, 'pickup');
                      } else {
                        setPickupAddress1(e);
                      }
                    }}
                    placeholder="Address"
                    errors={errors}
                    id="pickupAddress1"
                    required
                />
                {loading === 'pickup' && (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ position: 'absolute', right: '15px', top: '34%', color: '#f76b00'  }}
                  />
                )}
                {selectionLoading === 'pickup' && (
                  <div className="dot-loader">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </div>
                )}
              </div>
            </Dropdown.Toggle>
         {pickUpPlaceSuggestions?.length>0 &&    <Dropdown.Menu
            >
              {
                pickUpPlaceSuggestions.map((item:any,index:any)=>{
                  return (<Dropdown.Item key={index}  onClick={()=>{selectedAddress(item, 'pickup')}}>
                    {item?.description}
                    </Dropdown.Item>)
                })
              }
              </Dropdown.Menu> }
            </Dropdown>
          {courierServiceName !== 'Zoom2u' &&
            courierServiceName !== 'Felix Transport' &&
            courierServiceName !== 'Aramex' && (
              <Input
                className={styles.input}
                layout="horizontal"
                label="Address 2"
                name="pickupAddress2"
                value={pickupAddress2}
                maxLength={setMaxLengthOfAddress()}
                onChange={setPickupAddress2}
                placeholder="Address2"
                errors={errors}
              />
            )}
          {(courierServiceName === 'Aramex' || courierServiceName === 'Felix Transport') && (
            <Input
              className={styles.input}
              layout="horizontal"
              label="Street Number"
              name="pickupStreetNumber"
              value={pickupStreetNumber}
              onChange={setPickupStreetNumber}
              placeholder="Street Number"
              errors={errors}
              id="pickupStreetNumber"
              required
            />
          )}
          {(courierServiceName === 'Aramex' || courierServiceName === 'Felix Transport') && (
            <Input
              className={styles.input}
              layout="horizontal"
              label="Street Name"
              name="pickupStreetName"
              value={pickupStreetName}
              onChange={setPickupStreetName}
              placeholder="Street Name"
              errors={errors}
              id="pickupStreetName"
              required
            />
          )}

          <Input
            className={styles.input}
            layout="horizontal"
            label="Suburb"
            name="pickupSuburb"
            value={pickupSuburb}
            disabled
            placeholder="Suburb"
            errors={errors}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="State"
            name="pickupState"
            value={pickupState}
            disabled
            placeholder="State"
            errors={errors}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Postcode"
            name="pickupPostcode"
            value={pickupPostcode}
            disabled
            placeholder="Postcode"
            errors={errors}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Phone"
            name="pickupPhone"
            maxLength={setMaxLengthOfPhone()}
            value={pickupPhone}
            onChange={setPickupPhone}
            placeholder="Phone"
            type="number"
            errors={errors}
            required
          />
        </div>
        <div className={styles.column}>
          <H2 className={styles.heading}>Deliver to</H2>
          <Input
            className={styles.input}
            layout="horizontal"
            label="First Name"
            name="destinationFirstName"
            value={destinationFirstName}
            onChange={setDestinationFirstName}
            placeholder="First Name"
            maxLength={setMaxLengthOfFirstName()}
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Last Name"
            name="destinationLastName"
            value={destinationLastName}
            onChange={setDestinationLastName}
            maxLength={setMaxLengthOfLastName()}
            placeholder="Last Name"
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Company Name"
            name="destinationCompanyName"
            value={destinationCompanyName}
            maxLength={setMaxLengthOfCompanyName()}
            onChange={setDestinationCompanyName}
            placeholder="Company Name"
            errors={errors}
            required={destinationBuildingType === 'commercial'}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Email"
            name="destinationEmail"
            value={destinationEmail}
            onChange={setDestinationEmail}
            placeholder="Email"
            errors={errors}
            required
          />
          

        <Dropdown className={"destinationAddressDropdown"}
        show={showAddressDropdown === 'destination' && destinationPlaceSuggestions?.length>0}
        onToggle={(e,event)=>{
            if (e) {
              setShowAddressDropdown("destination");
              }else{
                setShowAddressDropdown("");
            }
          }}
        >
            <Dropdown.Toggle variant="light">
              <div style={{ position: 'relative' }}>
                <Input
                  className={styles.input}
                  layout="horizontal"
                  label="Address"
                  name="destinationAddress1"
                  value={destinationAddress1}
                  maxLength={setMaxLengthOfAddress()}
                  onChange={(e) => {
                    if (
                      courierServiceName === 'Zoom2u' ||
                      courierServiceName === 'Felix Transport' ||
                      courierServiceName === 'Aramex'
                    ) {
                      setDestinationAddress1(e);
                      setTimeout(() => {
                          setShowAddressDropdown("destination"); 
                        }, 10);
                      if (e.length<3) {
                          return 
                        }
                      handleChange(e, 'destination');
                    } else {
                      setDestinationAddress1(e);
                    }
                  }}
                  placeholder="Address"
                  id="destinationAddress1"
                  errors={errors}
                  required
                />
              {loading === 'destination' && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ position: 'absolute', right: '15px', top: '34%', color: '#f76b00' }}
                />
              )}
              {selectionLoading === 'destination' && (
                  <div className="dot-loader">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </div>
                )}
              </div>
            </Dropdown.Toggle>
              <Dropdown.Menu>
              {
                destinationPlaceSuggestions.map((item:any,index:any)=>{
                  return (<Dropdown.Item key={index}  onClick={()=>{selectedAddress(item, 'destination')}}>
                    {item?.description}
                    </Dropdown.Item>)
                })
              }
              </Dropdown.Menu> 
            </Dropdown>

          {courierServiceName !== 'Zoom2u' &&
            courierServiceName !== 'Felix Transport' &&
            courierServiceName !== 'Aramex' && (
              <Input
                className={styles.input}
                layout="horizontal"
                label="Address 2"
                name="destinationAddress2"
                value={destinationAddress2}
                onChange={setDestinationAddress2}
                maxLength={setMaxLengthOfAddress()}
                placeholder="Address2"
                errors={errors}
              />
            )}
          {(courierServiceName === 'Aramex' || courierServiceName === 'Felix Transport') && (
            <Input
              className={styles.input}
              layout="horizontal"
              label="Street Number"
              name="destinationStreetNumber"
              value={destinationStreetNumber}
              onChange={setDestinationStreetNumber}
              placeholder="Street Number"
              errors={errors}
              id="destinationStreetNumber"
              required
            />
          )}
          {(courierServiceName === 'Aramex' || courierServiceName === 'Felix Transport') && (
            <Input
              className={styles.input}
              layout="horizontal"
              label="Street Name"
              name="destinationStreetName"
              value={destinationStreetName}
              onChange={setDestinationStreetName}
              placeholder="Street Name"
              errors={errors}
              id="destinationStreetName"
              required
            />
          )}
          <Input
            className={styles.input}
            layout="horizontal"
            label="Suburb"
            name="destinationSuburb"
            value={destinationSuburb}
            disabled
            placeholder="Suburb"
            errors={errors}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="State"
            name="destinationState"
            value={destinationState}
            disabled
            placeholder="State"
            errors={errors}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Postcode"
            name="destinationPostcode"
            value={destinationPostcode}
            disabled
            placeholder="Postcode"
            errors={errors}
          />
          <Input
            className={styles.input}
            layout="horizontal"
            label="Phone"
            name="destinationPhone"
            maxLength={setMaxLengthOfPhone()}
            value={destinationPhone}
            onChange={setDestinationPhone}
            placeholder="Phone"
            type="number"
            errors={errors}
            required
          />
        </div>
      </div>

      <div className={classNames(styles.root, className)}>
        <div className={styles.collection}>
          <div className={styles.collectionDateTime}>
            <h2 style={{ color: '#1a202c', font: '700 20px/1 "vagroundedstd", sans-serif' }} className={styles.heading}>
              Collection Date & Time
            </h2>
            <div className={styles.subHeadingText}>When would you like your parcel collected ?</div>
            <div className={styles.collectionDate}>
              <span className={styles.labelHeading}>Date*</span>
              <div className={styles.pickupTimeWindowSelectBox}>
                <DatePicker
                  minDate={getTodaydate(courierServiceName, quotePickupCutOffTime || '', earliestCollectionDay)}
                  onChange={(e: Date) => {
                    if (e) {
                      checkSelectedDateIsWeekend(e, courierServiceName, publicHolidays, collectionMinDate || new Date(), earliestCollectionDay);
                    }
                  }}
                  className={styles.dateInputBox}
                  placeholderText="dd-mm-yyyy"
                  value={collectionDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div></div>
                  <span style={{ textAlign: 'right' }} className={styles.error}>
                    {errors.collectionDate ? errors.collectionDate : ''}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.pickupTimeWindow}>
              <span className={styles.labelHeading}>Pickup Time Window*</span>
              <div className={styles.pickupTimeWindowSelectBox}>
                <select
                  value={pickupTimeWindow}
                  onChange={(e) => {
                    setPickupTimeWindow(e.target.value || '');
                  }}
                  style={{ paddingRight: '1px', color: '#4a5568', outline: 'none', boxShadow: '0 0 10px #fff' }}
                  className="form-select"
                  name="pickupTimeWindow"
                >
                  {PICKUP_TIME_WINDOW.map((option, i) => (
                    <option value={option.value === 'Please Select' ? '' : option.value}>{option.value}</option>
                  ))}
                </select>
                <span className={styles.error}>{errors.pickupTimeWindow ? errors.pickupTimeWindow : ''}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.root, className)}>
        <div className={styles.collection}>
          <div className={styles.collectionDateTime}>
            <h2
              style={{ color: '#1a202c', font: '700 20px/1 "vagroundedstd", sans-serif', marginBottom: '0px' }}
              className={styles.heading}
            >
              Shipment Contents
            </h2>
            {/* <div className={styles.subHeadingText}>Please give us few details about the contents of your parcel.</div> */}
            <div className="pt-4">
              <div>
                <Form>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label className={styles.labelHeading}>
                      Provide a short description of goods being transported{' '}
                      {courierServiceName === 'Bonds Couriers'
                        ? '(Max 30 Characters)'
                        : courierServiceName !== 'AlliedExpress' &&
                          courierServiceName !== 'Aramex' &&
                          courierServiceName !== 'Hunter Express'
                        ? '(Max 80 Characters)'
                        : ''}
                      *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="parcelContent"
                      value={parcelContent}
                      maxLength={
                        courierServiceName === 'Bonds Couriers'
                          ? 30
                          : courierServiceName !== 'AlliedExpress' &&
                            courierServiceName !== 'Aramex' &&
                            courierServiceName !== 'Hunter Express'
                          ? 80
                          : undefined
                      }
                      onChange={(e) => {
                        setParcelContent(e.target.value || '');
                      }}
                      className={styles.input}
                    />
                  </Form.Group>
                  <span style={parscelContentDisclaimer}>
                    Please review our{' '}
                    <a
                      style={{ textDecoration: 'none', color: '#f76b00' }}
                      href="https://fastcourier.com.au/prohibited-restricted-list"
                      target="_blank" rel="noreferrer"
                    >
                      restricted/prohibited items list{' '}
                    </a>
                  </span>
                  <span className={styles.error}>{errors.parcelContent ? errors.parcelContent : ''}</span>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.root, className)}>
        <div className={styles.collection}>
          <div className={styles.collectionDateTime}>
            <h2 style={{ color: '#1a202c', font: '700 20px/1 "vagroundedstd", sans-serif' }} className={styles.heading}>
              Extended Liability Details
            </h2>
            <div>
              <Form>
                <div>
                  <div>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className={styles.labelHeading}>
                        Estimated value of content &nbsp;(Enter value in dollars($)) *
                      </Form.Label>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3 insu-width-input"
                      style={{ padding: '0px 12px' }}
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={1} style={{ padding: '0px', width: '40px' }}>
                        <Form.Control type="text" placeholder="$" readOnly />
                      </Form.Label>
                      <Col style={{ padding: '0px' }} className="insu-width">
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="valueOfContent"
                          value={valueOfContent}
                          onChange={(e) => {
                            setValueOfContent(e.target.value || '');
                          }}
                          className={styles.insInputField}
                        />
                      </Col>
                    </Form.Group>
                    <span className={styles.error}>{errors.valueOfContent ? errors.valueOfContent : ''}</span>
                  </div>
                  {!hideInsuranceFields && (
                    <div>
                    <span className={styles.labelHeading}>Extended Liability</span>*
                    <div className="form-group pt-2">
                      <select
                        name="extendedLiability"
                        value={extendedLiability}
                        onChange={(e) => {
                          setExtendedLiability(e.target.value || '');
                          setInsuranceValue(e.target.selectedOptions[0].getAttribute('insurance-value') || '$0');
                          setInsuranceFee(e.target.selectedOptions[0].getAttribute('insurance-fee') || '$0');
                        }}
                        style={{ paddingRight: '1px', color: '#4a5568', outline: 'none', boxShadow: '0 0 10px #fff' }}
                        className="form-select"
                      >
                        <option insurance-value="" insurance-fee="" value="">
                          Please Select
                        </option>
                        <option insurance-value="$0" insurance-fee="$0" value="0">
                          FREE extended liability up to $350
                        </option>

                        {insurances.map((option, i) => (
                          <option
                            insurance-value={option.item_value}
                            insurance-fee={option.additional_fee}
                            value={option.id}
                          >
                            &#43;{option.additional_fee} for upto {option.item_value.replace('.00', '')} extended
                            liability
                          </option>
                        ))}
                      </select>
                      <span className={styles.error}>{errors.extendedLiability ? errors.extendedLiability : ''}</span>
                      <span style={extendedLiabilityDisclaimer}>
                        Review the{' '} 
                          <a
                            target="_blank"
                            style={{ textDecoration: 'none', color: '#f76b00' }}
                            href="https://fastcourier.com.au/extended-liability-terms-conditions" rel="noreferrer"
                          >
                            Policy Terms and Conditions
                          </a>
                      </span>
                    </div>
                  </div>
                  )}
                  
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.root, className)}>
        <div className={styles.collection}>
          <div className={styles.collectionDateTime}>
            <h2 style={{ color: '#1a202c', font: '700 20px/1 "vagroundedstd", sans-serif' }} className={styles.heading}>
              Additional Instructions
            </h2>
            <div>
              <span
                style={{
                  color: 'rgb(60, 61, 64)',
                  fontSize: '11px',
                  display: 'block',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Please add additional special instructions below (Max 30 Characters)
              </span>
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    type="text"
                    placeholder="OPTIONAL"
                    name="specialInstructions"
                    value={specialInstructions}
                    onChange={(e) => {
                      setSpecialInstructions(e.target.value || '');
                      setSpecialInstructionsCounter(30 - e.target.value.length);
                    }}
                    className={styles.input}
                    maxLength={30}
                  />
                </Form.Group>
                {specialInstructionsCounter < 30 && specialInstructionsCounter > 0 && (
                  <span className={styles.error}>{specialInstructionsCounter} characters left.</span>
                )}
                {specialInstructionsCounter < 0 && (
                  <span className={styles.error}>The special instructions may not be greater than 30 characters.</span>
                )}
                {errors.specialInstructions && <span className={styles.error}>{errors.specialInstructions}</span>}
              </Form>
            </div>
            <div>
              <Form>
                <div>
                  <div>
                    <div className="pb-4">{/* <span className={styles.labelHeading}>Delivery Options</span> */}</div>
                    <div>
                      <label
                        htmlFor="icon-switch"
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                      >
                        <Switch
                          className="react-switch"
                          name="authorityToLeave"
                          onChange={setAuthorityToLeave}
                          checked={authorityToLeave}
                          id="icon-switch"
                          onColor="#f76b00"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          handleDiameter={14}
                          height={16}
                          width={45}
                        />
                        <span style={{ paddingLeft: '20px', color: '#4a5568' }}>Authority to leave at delivery</span>
                      </label>
                      <span style={authorityToLeavedisclaimerStyle}>
                        I authorise the driver to drop off the items on delivery if the premises are unattended.
                      </span>
                    </div>
                    {!hideMannualConnoteOptionCouriers.includes(courierServiceName) && (
                      <div>
                        <label
                          htmlFor="icon-switch"
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                        >
                          <Switch
                            className="react-switch"
                            name="noPrinter"
                            onChange={checkApplicableToSetNoPrinter}
                            checked={noPrinter}
                            id="icon-switch"
                            onColor="#f76b00"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={14}
                            height={16}
                            width={45}
                          />
                          <span style={{ paddingLeft: '20px', color: '#4a5568' }}>
                            I do NOT have a printer to print my labels (+$30 Additional cost)
                          </span>
                        </label>
                        <span style={noPrinterdisclaimerStyle}>
                          I need the courier to bring <b>manual connotes</b> on collection as I do not have a printer.
                        </span>
                        <span className={styles.error}>{noPrinterError ? noPrinterError : ''}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.toolbar}>
        <Button type="primary" onClick={save} disabled={specialInstructionsCounter < 0}>
          Finalise Booking
        </Button>
      </div>
    </>
  );
}
