import classNames from 'classnames';
import React from 'react';

import { Text } from '../Text/Text';
import styles from './Alert.module.scss';

type Type = 'info' | 'warning' | 'success' | 'error';

interface Props {
  className?: string;
  children: React.ReactNode;
  type?: Type;
}

export function Alert(props: Props) {
  const { className, children, type = 'info' } = props;
  return (
    <div className={classNames(styles.root, styles[type], className)}>
      <Text className={styles.text}>{children}</Text>
    </div>
  );
}
