import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { useStore } from '../../store';
import styles from './Stepper.module.scss';

type Props = {
  className?: string;
};

const links = [
  { label: 'Package Details', to: '/package-details' },
  { label: 'Select Quotes', to: '/quotes-comparisons' },
  { label: 'Shipment Details', to: '/shipment-details' },
  { label: 'Additional Info', to: '/additional-info' },
  { label: 'Payment', to: '/payment' },
];

export function Stepper(props: Props) {
  const { className } = props;

  const currentStep = useStore((state) => state.currentStep);
  const customOrder = useStore((state) => state.customOrder);
  const progressBarPercentage = useStore((state) => state.progressBarPercentage);
  return (
    <nav className={classNames(styles.root, className)}>
      {links.map((link, i) => {
        if(link.label === 'Payment' && customOrder){
          return;
        }
        let disabled = currentStep < i;

        if(progressBarPercentage < 100 && currentStep === 1 && i === 0){
          disabled=true;
        }

        if(progressBarPercentage >= 100 && currentStep === 1 && i === 0){
          disabled=false;
        }
        return (
          <NavLink
            key={i}
            onClick={(e) => {
              if (disabled) {
                e.preventDefault();
              }
            }}
            className={classNames(!customOrder? styles.link : styles.customQuoteLink, { [styles.disabled]: disabled })}
            activeClassName={styles.active}
            to={link.to}
          >
            {link.label}
          </NavLink>
        );
      })}
    </nav>
  );
}
