import classNames from 'classnames';
import React from 'react';
import { Link as DomLink } from 'react-router-dom';

import styles from './Link.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  to?: string;
  href?: string;
  size?: 'medium' | 'small';
}

export function Link(props: Props) {
  const { className, children, to, href, size = 'medium' } = props;

  return to ? (
    <DomLink to={to} className={classNames(styles.root, styles[size], className)}>
      {children}
    </DomLink>
  ) : (
    <a href={href} className={classNames(styles.root, styles[size], className)} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}
