import { ReactNode, useEffect } from 'react';

import { useStore } from '../../store';
import * as api from '../../services/api';
import styles from './Bootstrap.module.scss';

type Props = {
  children: ReactNode;
};

export function Bootstrap(props: Props) {
  const { children } = props;
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  function checkCookieExists(cookieName: any) {
    // Split the cookie string into individual cookies
    var cookies = document.cookie.split(';');
  
    // Iterate over the cookies and check if the cookieName is found
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
  
      // Check if the cookie starts with the provided cookieName
      if (cookie.indexOf(cookieName + '=') === 0) {
        // Cookie found
        return true;
      }
    }
  
    // Cookie not found
    return false;
  }

  useEffect(() => {
      setTimeout(async () => {
        var cookieExists = checkCookieExists('XSRF-TOKEN');
        if(!cookieExists){
          await api.csrf();
        }
      }, 2500);
  }, []);

  if (isLoggedIn === null) {
    return null;
  }

  return <main className={styles.root}>{children}</main>;
}
