import classNames from 'classnames';
import React from 'react';
import { Label } from '../Label/Label';

import styles from './Select.module.scss';

type Props<T> = {
  className?: string;
  disabled?: boolean;
  hasBorder?: boolean;
  inverted?: boolean;
  label?: React.ReactNode;
  name?: string;
  onChange: (value: any) => void;
  options: { value: T; label: string | number }[];
  value: T;
};

export function Select<T extends string | number = string>(props: Props<T>) {
  const {
    className,
    disabled = false,
    hasBorder = true,
    inverted = false,
    label = '',
    name,
    onChange,
    options,
    value,
  } = props;

  return (
    <div className={classNames(styles.root, { [styles.inverted]: inverted, [styles.hasBorder]: hasBorder }, className)}>
      <Label inverted={inverted} text={label}>
        <select name={name} disabled={disabled} onChange={(e) => onChange(e.currentTarget.value)} value={value}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Label>
    </div>
  );
}
