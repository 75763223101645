import classNames from 'classnames';
import React from 'react';

import styles from './Checkbox.module.scss';

type Props = {
  className?: string;
  name: string;
  label: React.ReactNode;
  onChange: (value: boolean) => void;
  value: boolean;
};

export function Checkbox(props: Props) {
  const { className, onChange, label, value = false } = props;

  return (
    <div className={classNames(styles.root, { [styles.checked]: value }, className)} onClick={() => onChange(!value)}>
      <div className={styles.square} />
      <div className={styles.icon} />
      <label>{label}</label>
    </div>
  );
}
