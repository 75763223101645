import React, { MouseEvent, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { Input } from '../../components/Input/Input';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Text } from '../../components/Text/Text';
import { Panel } from '../../components/Panel/Panel';
import styles from './CancelConsignment.module.scss';
import * as api from '../../services/api';
import { RebookStatus, ValidationErrors } from '../../types';

type Props = {
  className?: string;
};

export function CancelConsignment(props: Props) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [consignmentCode, setConsignmentCode] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');

  const [apiMessage, setApiMessage] = useState<string>('');

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

      setStatus('in-progress');
      try {
        await api.csrf();
        const response = await api.post<any>(`/contact/cancel-consignment`, {
          consignmentCode: consignmentCode,
          reason: reason,
          contactName: contactName,
          contactEmail: contactEmail,
          status: 'cancelled',
          remarks: reason,
          created_by: 0
        });
        setStatus('idle');
        if(response.status){
          setConsignmentCode('');
          setReason('');
          setContactName('');
          setContactEmail('');
        }
        setApiMessage(response.message);
      } catch (e) {
        setStatus('idle');
        setApiMessage("Something went wrong!");
      }
  };

  return (
    <div className={styles.root}>
      <OverlaySpinner enabled={status === 'in-progress'} />
      <Card color="grey" className={styles.card}>
        <Text color="grey900">
        Do you want to cancel a consignment? If the cancellation is on the day of collection, or a driver has already been allocated, charges may apply. Please ensure all cancelled consignment labels are removed from the items to ensure freight does not travel under the cancelled labels.
        </Text>            
      </Card>

      {apiMessage && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}

      {status === 'idle' && (
        <div>
          <Input
            className={styles.input}
            label="Consignment number / Reference ID"
            name="pickupAddress1"
            value={consignmentCode}
            onChange={setConsignmentCode}
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            label="Reason for cancellation"
            name="reason"
            value={reason}
            onChange={setReason}
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            label="Contact Name"
            name="contactName"
            value={contactName}
            onChange={setContactName}
            errors={errors}
            required
          />
          <Input
            className={styles.input}
            label="Contact email address"
            name="contactEmail"
            value={contactEmail}
            onChange={setContactEmail}
            errors={errors}
            required
          />
        </div>
      )}

      {status === 'idle' && (
        <div className={styles.toolbar} >
          <Button type="primary" onClick={submit}>Submit</Button>
        </div>
      )}

    </div>
  );
}
