import classNames from 'classnames';
import React, { createElement } from 'react';
import { Color } from '../../types';

import styles from './Text.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  as?: string;
  size?: 'medium' | 'small' | 'extraSmall' | '100%';
  weight?: '400' | '700';
  family?: 'main' | 'brand';
  color?: Color;
}

export function Text(props: Props) {
  const {
    className,
    children,
    as = 'div',
    family = 'main',
    size = 'medium',
    color = 'grey800',
    weight = '400',
  } = props;

  return createElement(
    as,
    {
      className: classNames(
        styles.root,
        styles[size],
        styles[color],
        styles[family],
        styles[`weight${weight}`],
        className
      ),
    },
    children
  );
}
