import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Panel.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

export function Panel(props: Props) {
  const { children, className } = props;

  return <div className={classNames(styles.root, className)}>{children}</div>;
}
