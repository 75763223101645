import { Alert } from '../../../components/Alert/Alert';
import { Button } from '../../../components/Button/Button';
import { H1 } from '../../../components/H1/H1';
import { Text } from '../../../components/Text/Text';
import styles from '../Account.module.scss';

export function RegisterSuccess() {
  return (
    <>
      <H1 alignment="center" className={styles.heading}>
        Successfully registered!
      </H1>
      <div className={styles.container}>
        <Alert className={styles.alert} type="success">
          Thank you for registering with Fast Courier.
          <br />
          please log in using the link below.
        </Alert>
        <div className={styles.toolbar}>
          <Text>&nbsp;</Text>
          <Button to="/package-details" block className={styles.button}>
            Create an order
          </Button>
        </div>
      </div>
    </>
  );
}
