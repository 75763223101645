import axios, { AxiosRequestConfig } from 'axios';
import { API_URL, BASE_URL } from '../config';
import { useStore } from '../store';

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  return Promise.reject(error);
});

export async function csrf() {
  await axiosInstance.get('/sanctum/csrf-cookie', { baseURL: BASE_URL });
}

export async function auth() {
  const { data } = await axiosInstance.get('/ping');
  const { firstName, lastName, email, status } = data;
  const store = useStore.getState();

  if (status === true) {
    store.setIsLoggedIn(true);
    store.setPickupFirstName(firstName);
    store.setPickupLastName(lastName);
    store.setPickupEmail(email);
  } else {
    store.setIsLoggedIn(false);
    store.setPickupFirstName('');
    store.setPickupLastName('');
    store.setPickupEmail('');
  }

  return data;
}

export async function login(email: string, password: string) {
  await axiosInstance.post('/login', { email, password }, { baseURL: BASE_URL });
  await auth();
}

export async function forgot(email: string) {
  await axiosInstance.post('/forgot-password', { email }, { baseURL: BASE_URL });
}

export async function reset(email: string, password: string, token: string) {
  await axiosInstance.post('/reset-password', { email, password, token }, { baseURL: BASE_URL });
}

export async function logout() {
  await axiosInstance.post('/logout', {}, { baseURL: BASE_URL });
  await auth();
}

export async function register(firstName: string, lastName: string, email: string, password: string) {
  await axiosInstance.post('/register', { firstName, lastName, email, password }, { baseURL: BASE_URL });
  await auth();
}

export async function get<T>(path: string, config?: AxiosRequestConfig) {
  const { data } = await axiosInstance.get<T>(path, config);
  return data;
}

export async function put<T>(path: string, payload: any) {
  const { data } = await axiosInstance.put<T>(path, payload);
  return data;
}

export async function patch<T>(path: string, payload: any) {
  const { data } = await axiosInstance.patch<T>(path, payload);
  return data;
}

export async function post<T>(path: string, payload: any, config?: AxiosRequestConfig) {
  const { data } = await axiosInstance.post<T>(path, payload, config);
  return data;
}

export async function destroy<T>(path: string) {
  const { data } = await axiosInstance.delete<T>(path);
  return data;
}
