import { useState } from 'react';
import classNames from 'classnames';

import { AddressInput } from '../AddressInput/AddressInput';
import { BuildingType, BuildingTypes, PackageType } from '../../types';
import { defaultItem } from '../../store';
import { H1 } from '../H1/H1';
import { H2 } from '../H2/H2';
import { RadioGroup } from '../RadioGroup/RadioGroup';
import styles from './HorizontalWidget.module.scss';
import { track } from '../../util';
import { FRONTEND_URL } from '../../config';

interface Props {
  appPageUrl: string;
  layout: 'horizontal' | 'vertical';
}

export function NewHorizontalWidget(props: Props) {
  const { appPageUrl, layout } = props;
  const [pickupBuildingType, setPickupBuildingType] = useState<BuildingType>('commercial');
  const [pickupPostcode, setPickupPostcode] = useState('');
  const [pickupState, setPickupState] = useState('');
  const [pickupSuburb, setPickupSuburb] = useState('');
  const [destinationPostcode, setDestinationPostcode] = useState('');
  const [destinationState, setDestinationState] = useState('');
  const [destinationSuburb, setDestinationSuburb] = useState('');
  const [destinationBuildingType, setDestinationBuildingType] = useState<BuildingType>('commercial');
  const [height] = useState<number>(defaultItem.height);
  const [length] = useState<number>(defaultItem.length);
  const [type] = useState<PackageType>(defaultItem.type);
  const [weight] = useState<number>(defaultItem.weight);
  const [width] = useState<number>(defaultItem.width);
  const [quantity] = useState<number>(defaultItem.quantity);

  const prepUrl = () => {
    const payload = btoa(
      JSON.stringify({
        pickupPostcode,
        pickupState,
        pickupSuburb,
        pickupBuildingType,
        destinationPostcode,
        destinationState,
        destinationSuburb,
        destinationBuildingType,
        height,
        length,
        type,
        weight,
        width,
        quantity,
      })
    );

    return `${FRONTEND_URL}/?payload=${payload}`;
  };

  const onClick = () => {
    track('widget-get-quote');
  };

  const isVertical = layout === 'vertical';
  return (
    <section className={classNames(styles.root, styles[layout])}>
      {isVertical ? (
        <H2 alignment="left" includeBorder={false} className={styles.heading} inverted={true}>
          Instant quote. Book now and Save!
        </H2>
      ) : (
        <H1 alignment="center" includeBorder={false} className={styles.heading}>
          Instant quote. Book now and Save!
        </H1>
      )}
      <AddressInput
        className={styles.addressInput}
        errors={{}}
        inverted={false}
        hasBorder={false}
        name=""
        label={isVertical ? '' : 'Collect from'}
        onPostcodeChange={setPickupPostcode}
        onStateChange={setPickupState}
        onSuburbChange={setPickupSuburb}
        placeholder="Collect from"
      />
      <RadioGroup<BuildingType>
        className={styles.input}
        inverted={false}
        label="Building type"
        name="pickupBuildingType"
        onChange={setPickupBuildingType}
        options={BuildingTypes}
        value={pickupBuildingType}
        type="buttons"
      />
      <hr className={styles.hr} />
      <AddressInput
        className={styles.addressInput}
        errors={{}}
        inverted={false}
        hasBorder={false}
        name=""
        label={'Delivering to'}
        onPostcodeChange={setDestinationPostcode}
        onStateChange={setDestinationState}
        onSuburbChange={setDestinationSuburb}
        placeholder="Delivering to"
      />
      <RadioGroup<BuildingType>
        className={styles.input}
        inverted={false}
        label="Building type"
        name="pickupBuildingType"
        onChange={setDestinationBuildingType}
        options={BuildingTypes}
        value={destinationBuildingType}
        type="buttons"
      />
      <div className={styles.toolbar}>
        <a className={styles.button} href={prepUrl()} onClick={onClick}>
          Book Now
        </a>
      </div>
    </section>
  );
}
