import React, { MouseEvent, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { Input } from '../../components/Input/Input';
import { Label } from '../../components/Label/Label';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Text } from '../../components/Text/Text';
import { Panel } from '../../components/Panel/Panel';
import styles from './Redelivery.module.scss';
import * as api from '../../services/api';
import { RebookStatus, ValidationErrors } from '../../types';

type Props = {
  className?: string;
};

export function Redelivery(props: Props) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [consignmentCode, setConsignmentCode] = useState<string>('');
  const [nextDeliveryDate, setNextDeliveryDate] = useState<string>('');
  const [atl, setAtl] = useState<string>('');
  const [deliveryAddress, setDeliveryAddress] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [otherInformation, setOtherInformation] = useState<string>('');
  const [isValidEmail, setValidEmail] = useState<boolean>(false);

  const [apiMessage, setApiMessage] = useState<string>('');

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

      setStatus('in-progress');
      try {
        await api.csrf();
        const response = await api.post<any>(`/contact/redelivery`, {
          consignmentCode: consignmentCode,
          nextDeliveryDate: nextDeliveryDate,
          atl: atl,
          deliveryAddress: deliveryAddress,
          contactName: contactName,
          contactEmail: contactEmail,
          otherInformation: otherInformation,
        });
        setStatus('idle');
        if(response.status){
          setConsignmentCode('');
          setNextDeliveryDate('');
          setAtl('');
          setDeliveryAddress('');
          setContactName('');
          setContactEmail('');
          setOtherInformation('');
        }
        setApiMessage(response.message);
      } catch (e) {
        setStatus('idle');
        setApiMessage("Something went wrong!");
      }
  };

  const getTodaydate = () => {
    let today = new Date(new Date().getTime());
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let date = yyyy + '-' + mm + '-' + dd;
    return date;
  };

  return (
    <div className={styles.root}>
      <OverlaySpinner enabled={status === 'in-progress'} />
      <Card color="grey" className={styles.card}>
        <Text color="grey900">
        Has a delivery been attempted and a notification card left? If so, please complete the fields below to request a redelivery (fees may apply).
        </Text>            
      </Card>

      {apiMessage && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}
      
      {status === 'idle' && (
      <div>
        
        <Input
          className={styles.input}
          label="Consignment number / Reference ID"
          name="consignmentCode"
          value={consignmentCode}
          onChange={setConsignmentCode}
          errors={errors}
          required
        />

        <Label 
          className={styles.label}
          text="Next available day a delivery can be attempted"
          required
        />
        <input
          className={styles.dateInputBox}
          type="date"
          name="nextDeliveryDate"
          value={nextDeliveryDate}
          min={getTodaydate()}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onChange={(e) => {
            setNextDeliveryDate(e.target.value);
          }}
        />
        
        <Input
          className={styles.input}
          label="Do you give an Authority to leave if the receivers are not home (must be under 25kg)?"
          name="atl"
          value={atl}
          onChange={setAtl}
          errors={errors}
          required
        />
        <Input
          className={styles.input}
          label="Confirm the delivery address."
          name="deliveryAddress"
          value={deliveryAddress}
          onChange={setDeliveryAddress}
          errors={errors}
          required
        />
        <Input
          className={styles.input}
          label="Contact Name"
          name="contactName"
          value={contactName}
          onChange={setContactName}
          errors={errors}
          required
        />
        <Input
          className={styles.input}
          label="Contact email address"
          name="contactEmail"
          value={contactEmail}
          onChange={setContactEmail}
          errors={errors}
          required
        />
        <Input
          className={styles.input}
          label="Other Information"
          name="otherInformation"
          value={otherInformation}
          onChange={setOtherInformation}
          errors={errors}
        />
      </div>
      )}

      {status === 'idle' && (
      <div className={styles.toolbar} >
        <Button type="primary" onClick={submit} disabled={isValidEmail}>Submit</Button>
      </div>
      )}

    </div>
  );
}
