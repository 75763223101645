import { useHistory } from 'react-router';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Modal, Button as BButton} from 'react-bootstrap';
import { AddressInput } from '../../components/AddressInput/AddressInput';
import { Alert } from '../../components/Alert/Alert';
import { BuildingType, BuildingTypes, Item, PackageType, PackageTypes, ContentType, ContentTypes, Status, ValidationErrors } from '../../types';
import { Button } from '../../components/Button/Button';
import { extractError, track } from '../../util';
import { H1 } from '../../components/H1/H1';
import { Header } from '../../components/Header/Header';
import { Input } from '../../components/Input/Input';
import { CustomInputGroup } from '../../components/Input/CustomInputGroup';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { RadioGroup } from '../../components/RadioGroup/RadioGroup';
import { Select } from '../../components/Select/Select';
import { Spinner } from '../../components/Spinner/Spinner';
import { useStore } from '../../store';
import * as api from '../../services/api';
import styles from './PackageDetails.module.scss';
import Switch from 'react-switch';
import { InfoCircle } from '../../icons/InfoCircle/InfoCircle';
import moment from 'moment';
import 'moment-timezone'; 

type PatchPackageDetails = { pickupPostcode: string; destinationPostcode: string };

export function PackageDetails() {
  const orderId = useStore((state) => state.orderId);
  const pickupAddress1 = useStore((state) => state.pickupAddress1);
  const pickupAddress2 = useStore((state) => state.pickupAddress2);
  const pickupSuburb = useStore((state) => state.pickupSuburb);
  const pickupState = useStore((state) => state.pickupState);
  const pickupPostcode = useStore((state) => state.pickupPostcode);
  const pickupBuildingType = useStore((state) => state.pickupBuildingType);
  const pickupTailLift = useStore((state) => state.pickupTailLift);
  const destinationAddress1 = useStore((state) => state.destinationAddress1);
  const destinationAddress2 = useStore((state) => state.destinationAddress2);
  const destinationSuburb = useStore((state) => state.destinationSuburb);
  const destinationState = useStore((state) => state.destinationState);
  const destinationPostcode = useStore((state) => state.destinationPostcode);
  const destinationBuildingType = useStore((state) => state.destinationBuildingType);
  const dropOffTailLift = useStore((state) => state.dropOffTailLift);
  const dropOffPOBox = useStore((state) => state.dropOffPOBox);
  const items = useStore((state) => state.items);
  const reCreateOrder = useStore((state) => state.reCreateOrder);
  const customOrder = useStore((state) => state.customOrder);
  const setOrderId = useStore((state) => state.setOrderId);
  const setCurrentStep = useStore((state) => state.setCurrentStep);
  const setPickupSuburb = useStore((state) => state.setPickupSuburb);
  const setPickupPostcode = useStore((state) => state.setPickupPostcode);
  const setPickupState = useStore((state) => state.setPickupState);
  const setPickupBuildingType = useStore((state) => state.setPickupBuildingType);
  const setPickupTailLift = useStore((state) => state.setPickupTailLift);
  const setDestinationSuburb = useStore((state) => state.setDestinationSuburb);
  const setDestinationPostcode = useStore((state) => state.setDestinationPostcode);
  const setDestinationState = useStore((state) => state.setDestinationState);
  const setDestinationBuildingType = useStore((state) => state.setDestinationBuildingType);
  const setDropOffTailLift = useStore((state) => state.setDropOffTailLift);
  const setDropOffPOBox = useStore((state) => state.setDropOffPOBox);
  const setReCreateOrder = useStore((state) => state.setReCreateOrder);
  const editItem = useStore((state) => state.editItem);
  const addItem = useStore((state) => state.addItem);
  const removeItem = useStore((state) => state.removeItem);
  const clearEmptyItems = useStore((state) => state.clearEmptyItems);
  const setCustomOrder = useStore((state) => state.setCustomOrder);
  const setQuotes = useStore((state) => state.setQuotes);
  const setProgressBarPercentage = useStore((state) => state.setProgressBarPercentage);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('test');
  const [modalBody, setModalBody] = useState('test');
  const handleClose = () => setShow(false);


  const history = useHistory();
  const [status, setStatus] = useState<Status>('idle');
  const [errors, setErrors] = useState<ValidationErrors>({});
  const itemError = extractError(errors, 'items');

  const handleShow = (title:any, body:any) => {
    setModalTitle(title);
    setModalBody(body);
    setShow(true);
  };

  const save = () => {
    setQuotes([]);
    setProgressBarPercentage(0);
    (async () => {
      const payload = {
        pickupAddress1: pickupAddress1,
        pickupAddress2: pickupAddress2,
        pickupSuburb: pickupSuburb,
        pickupState: pickupState,
        pickupPostcode: pickupPostcode,
        pickupBuildingType: pickupBuildingType,
        isPickupHandLoad: false,
        isPickupTailLift: pickupTailLift,
        destinationAddress1: destinationAddress1,
        destinationAddress2: destinationAddress2,
        destinationSuburb: destinationSuburb,
        destinationState: destinationState,
        destinationPostcode: destinationPostcode,
        destinationBuildingType: destinationBuildingType,
        isDropOffHandLoad: false,
        isDropOffTailLift: dropOffTailLift,
        isDropOffPOBox: dropOffPOBox,
        items: items,
      };
      
      try {
        setStatus('in-progress');
        await api.csrf();

        let id: any;
        if (orderId) {
          id = orderId;
          if(reCreateOrder){
            const reCreateOrderRecord = await api.post<any>(`/order/${orderId}/reCreateOrder`, {});
            id = reCreateOrderRecord.id;
            setReCreateOrder(false);
          }
        } else {
          const draftOrder = await api.post<any>('/order', {
            order_request_timezone: moment.tz.guess()
          });
          id = draftOrder.id;
        }
        setOrderId(id);
        const data: PatchPackageDetails = await api.patch(`/order/${id}/package-details`, payload);

        setPickupPostcode(data.pickupPostcode);
        setDestinationPostcode(data.destinationPostcode);
        clearEmptyItems();

        track('app-get-quote');
        history.push('/quotes-comparisons');
      } catch (e:any) {
        setStatus('error');
        setErrors(e?.response?.data?.errors ?? {});
        track('app-error-package-details', { payload });
      }
    })();
  };
  async function checkItems(){
    setStatus('in-progress');
    
    setTimeout(() => {
      let itemsLength = items.length;
      let counter = 0;
      items.forEach(async (item: any) => {
        if(item.length === "" || item.width === "" || item.height === "" || item.weight === "" || item.quantity === ""){
          setStatus('empty-package-dimensions-error');
          return false;
        }
        if(item.contents === ""){
          setStatus('empty-contents-error');
          return false;
        }
        if(item.length > 999 || item.width > 999 || item.height > 999){
          setStatus('large-package-dimensions-error');
          return false;
        }
        if(item.length === 0 || item.width === 0 || item.height === 0){
          setStatus('small-package-dimensions-error');
          return false;
        }
        counter+=1;
        
    });
    if(itemsLength === counter){
         save() 
    }
    }, 1000);
    
}

  useEffect(() => {
    setCurrentStep(0);
    if(customOrder){
      setCustomOrder(false);
    }
  }, [setCurrentStep, setCustomOrder]);

  let textStyle = {
    fontSize:"12px", 
    fontFamily:"vagroundedstd, sans-serif",  
    color:"#4a5568",
    fontWeight: 700,
    marginBottom:"0px"
  };

  let switchFont={
    fontFamily:'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
  }
  let switchStye = {
    boxShadow:"0px 1px 5px rgba(0, 0, 0, 0.6)",
    activeBoxShadow:"0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
    height:"12",
    width:"18"
  };
  return (
    <>
      <Header />
      <OverlaySpinner enabled={status === 'in-progress'} />
      <H1 className={styles.heading} alignment="center">
        Location Details
      </H1>
      <div className={styles.row}>
      <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >

            <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
             <p>
            {modalBody}
             </p>
            </Modal.Body>
            <Modal.Footer>
             <BButton onClick={handleClose} style={{ backgroundColor: '#f9964b' }}>Close</BButton>
            </Modal.Footer>
          </Modal>
        <div className={styles.column}>
          <AddressInput
            enableLookup
            errors={errors}
            label="From"
            layout="vertical"
            name="pickupAddress1"
            onPostcodeChange={setPickupPostcode}
            onStateChange={setPickupState}
            onSuburbChange={setPickupSuburb}
            postcode={pickupPostcode}
            state={pickupState}
            suburb={pickupSuburb}
            placeholder="Search for postcode or suburb"
          />
          <RadioGroup<BuildingType>
            className={styles.input}
            label="Building type"
            name="pickupBuildingType"
            onChange={setPickupBuildingType}
            options={BuildingTypes}
            value={pickupBuildingType}
          />
          <div style={{ marginTop: '20px' }}>
            <p style={textStyle}>Pick up options</p>
          </div>
          <div>
            <label
              htmlFor="icon-switch"
              style={{ display: 'inline-block', alignItems: 'center', marginBottom: '10px', width: '255px' }}
            >
              <Switch
                className="react-switch"
                name="pickupTailLift"
                onChange={setPickupTailLift}
                checked={pickupTailLift}
                onColor="#f76b00"
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={14}
                height={16}
                width={45}
              />
              <span style={switchFont} className={styles.switchLabel}>Tail Lift
              <div style={{ display: 'inline-block', width:"18px", cursor:"pointer" }} onClick={() => handleShow('Tail Lift', 'You require a truck with Tail Lift functionality')} className={styles.info}>
                &nbsp;
                <sup>
                  <InfoCircle className={styles.icon} />
                </sup>
              </div>
              </span>
            </label>
          </div>
        </div>
        <div className={styles.column}>
          <AddressInput
            errors={errors}
            label="To"
            layout="vertical"
            name="destinationAddress1"
            onPostcodeChange={setDestinationPostcode}
            onStateChange={setDestinationState}
            onSuburbChange={setDestinationSuburb}
            postcode={destinationPostcode}
            state={destinationState}
            suburb={destinationSuburb}
            placeholder="Search for postcode or suburb"
          />
          <RadioGroup<BuildingType>
            className={classNames(styles.input, styles.inputExtra)}
            label="Building type"
            name="desintationBuildingType"
            onChange={setDestinationBuildingType}
            options={BuildingTypes}
            value={destinationBuildingType}
          />
          <div style={{ marginTop: '20px' }}>
            <p style={textStyle}>Delivery options</p>
          </div>
          <div>
            <label
              htmlFor="icon-switch"
              style={{ display: 'inline-block', alignItems: 'center', width: '255px' }}
            >
              <Switch
                className="react-switch"
                name="dropOffTailLift"
                onChange={setDropOffTailLift}
                checked={dropOffTailLift}
                onColor="#f76b00"
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={14}
                height={16}
                width={45}
              />
              <span style={switchFont} className={styles.switchLabel}>Tail Lift
              <div style={{ display: 'inline-block', width:"18px", cursor:"pointer" }} onClick={() => handleShow('Tail Lift', 'You require a truck with Tail Lift functionality')} className={styles.info}>
                &nbsp;
                <sup>
                  <InfoCircle className={styles.icon} />
                </sup>
              </div>
              </span>
            </label>
          </div>
          <div>
            <label
              htmlFor="icon-switch"
              style={{ display: 'inline-block', alignItems: 'center', marginBottom: '20px', width: '255px' }}
            >
              <Switch
                className="react-switch"
                name="dropOffPOBox"
                onChange={setDropOffPOBox}
                checked={dropOffPOBox}
                onColor="#f76b00"
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={14}
                height={16}
                width={45}
              />
              <span style={switchFont} className={styles.switchLabel}>PO Box
              <div style={{ display: 'inline-block', width:"18px", cursor:"pointer" }} onClick={() => handleShow('PO Box', 'Only StarTrack & Auspost deliver to PO Boxes so you will only be served with quotes from these carriers. All other carriers only deliver to residential/commerical full addresses.')} className={styles.info}>
                &nbsp;
                <sup>
                  <InfoCircle className={styles.icon} />
                </sup>
              </div>
              </span>
            </label>
          </div>
        </div>
      </div>

      <H1 className={styles.packageDetails} alignment="center" style={{marginTop:"0px"}}>
        Package Details
      </H1>
      <div className={styles.items}>
        {items.map((item, i) => (
          <div className={styles.item} key={i}>
            <div className={styles.itemRow}>
              <div className={styles.cell}>
                <Select<PackageType>
                  className={styles.simpleInput}
                  label="Package Type"
                  onChange={(value) => editItem(i, 'type', value)}
                  options={PackageTypes}
                  value={item.type}
                />
              </div>
              <div className={styles.cell}>
                <Input<Item[keyof Item]>
                  className={styles.simpleInput}
                  label="Weight (kgs)"
                  name="weight"
                  type="number"
                  errors={{}}
                  value={item.weight}
                  onChange={(value) => editItem(i, 'weight', value)}
                />
              </div>
              <div className={styles.cell}>
                <CustomInputGroup<Item[keyof Item]>
                  label="Length"
                  name="length"
                  type="number"
                  errors={{}}
                  min={1}
                  value={item.calculatedLength}
                  calculatedValue={item.length}
                  onChange={(value, calculatedValue) => {
                    editItem(i, 'length', calculatedValue)
                    editItem(i, 'calculatedLength', value)
                  }}
                />
              </div>
              <div className={styles.cell}>
                <CustomInputGroup<Item[keyof Item]>
                  label="Width"
                  name="width"
                  type="number"
                  errors={{}}
                  min={1}
                  value={item.calculatedWidth}
                  calculatedValue={item.width}
                  onChange={(value, calculatedValue) => {
                    editItem(i, 'width', calculatedValue)
                    editItem(i, 'calculatedWidth', value)
                  }}
                />
              </div>
              <div className={styles.cell}>
                <CustomInputGroup<Item[keyof Item]>
                  label="Height"
                  name="height"
                  type="number"
                  errors={{}}
                  min={1}
                  value={item.calculatedHeight}
                  calculatedValue={item.height}                  
                  onChange={(value, calculatedValue) => {
                    editItem(i, 'height', calculatedValue)
                    editItem(i, 'calculatedHeight', value)
                  }}
                />
              </div>
              <div className={styles.cell}>
                <Spinner value={item.quantity} onChange={(value) => editItem(i, 'quantity', value)} min={1} max={99}>
                  <Input<Item[keyof Item]>
                    className={styles.simpleInput}
                    label="Quantity"
                    name="quantity"
                    type="number"
                    errors={{}}
                    min={1}
                    max={99}
                    value={item.quantity}
                    onChange={(value) => editItem(i, 'quantity', value)}
                  />
                </Spinner>
              </div>
              <div className={styles.cell}>
                <Select<ContentType>
                  className={styles.simpleInput}
                  label="Contents"
                  onChange={(value) => editItem(i, 'contents', value)}
                  options={ContentTypes}
                  value={item.contents}
                />
              </div>
            </div>
            {items.length > 1 && (
              <button className={styles.remove} onClick={() => removeItem(i)}>
                Remove package
              </button>
            )}
          </div>
        ))}
        <div className={styles.addToolbar}>
          <Button type="ghost" size="medium" disabled={items.length >= 10} onClick={addItem}>
            Add package
          </Button>
        </div>
      </div>
      {status === 'error' && (
        <Alert type="error" className={styles.alert}>
          There is an error submitting your details. Please check the fields above. It seems you have not entered correct dimensions.
          <br />
          {itemError}
        </Alert>
      )}
      {status === 'large-package-dimensions-error' && (
        <Alert type="error" className={styles.alert}>
          Max 999 CM, please check if your <b>dimension</b> measurement is in CM or MM
          <br />
          {itemError}
        </Alert>
      )}
      {status === 'small-package-dimensions-error' && (
        <Alert type="error" className={styles.alert}>
          Min 5 MM, please check if your <b>dimension</b> measurement is correct
          <br />
          {itemError}
        </Alert>
      )}
      {status === 'empty-package-dimensions-error' && (
        <Alert type="error" className={styles.alert}>
          Dimensions should not be empty!
          <br />
          {itemError}
        </Alert>
      )}
      {status === 'empty-contents-error' && (
        <Alert type="error" className={styles.alert}>
          Contents is required!
          <br />
          {itemError}
        </Alert>
      )}
      <div className={styles.toolbar}>
        <Button
          disabled={
            !destinationPostcode ||
            !pickupPostcode ||
            !pickupBuildingType ||
            !destinationBuildingType
          }
          type="primary"
          onClick={checkItems}
        >
          Get Quotes
        </Button>
      </div>
    </>
  );
}
