import classNames from 'classnames';
import { ReactElement } from 'react';
import { Input } from '../Input/Input';

import styles from './Spinner.module.scss';

type Props = {
  onChange: (value: number) => void;
  value: number;
  className?: string;
  children: ReactElement<typeof Input>;
  min?: number;
  max?: number;
};

export function Spinner(props: Props) {
  const { children, onChange, value, className, min, max } = props;

  return (
    <div className={classNames(styles.root, className)}>
      {children}
      <div className={styles.buttons}>
        <button
          className={styles.button}
          onClick={() => {
            if (max && value >= max) {
              return;
            }
            onChange(value + 1);
          }}
        >
          +
        </button>
        <button
          className={styles.button}
          onClick={() => {
            if (min && value <= min) {
              return;
            }
            onChange(value - 1);
          }}
        >
          -
        </button>
      </div>
    </div>
  );
}
