import classNames from 'classnames';

import { ValidationErrors } from '../../types';
import { extractError } from '../../util';
import styles from './ValidationError.module.scss';

type Props = { className?: string; name: string; errors: ValidationErrors };

export function ValidationError(props: Props) {
  const { className, name, errors } = props;

  const error = extractError(errors, name);

  if (!error) {
    return null;
  }

  return <div className={classNames(styles.root, className)}>{error}</div>;
}
