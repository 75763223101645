import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import React, { useEffect, useRef, lazy, Suspense } from 'react';
import { Account } from './screens/Account/Account';
import { AdditionalInfo } from './screens/AdditionalInfo/AdditionalInfo';
import { AuthBar } from './components/AuthBar/AuthBar';
import { Bootstrap } from './components/Bootstrap/Bootstrap';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { Head } from './components/Head/Head';
import { LoggedOutRoute } from './components/Routes/LoggedOutRoute';
import { OrderComplete } from './screens/OrderComplete/OrderComplete';
import { PackageDetails } from './screens/PackageDetails/PackageDetails';
import { Payment } from './screens/Payment/Payment';
import { QuotesComparisons } from './screens/QuotesComparisons/QuotesComparisons';
import { ShipmentDetails } from './screens/ShipmentDetails/ShipmentDetails';
import { Tracking } from './screens/Tracking/Tracking';
import { useStore } from './store';
// import { VerticalWidget } from './components/Widget/VerticalWidget';
import { HorizontalWidget } from './components/Widget/HorizontalWidget';
import { NewHorizontalWidget } from './components/Widget/NewHorizontalWidget';
import styles from './App.module.scss';
import { RegisterSuccess } from './screens/Account/RegisterSuccess/RegisterSuccess';
import { CUSTOMER_PORTAL_URL } from './config';
import { Rebook } from './screens/Rebook/Rebook';
import { Redelivery } from './screens/Redelivery/Redelivery';
import { CancelConsignment } from './screens/CancelConsignment/CancelConsignment';
import { LabelRequest } from './screens/LabelRequest/LabelRequest';
import { Insurance } from './screens/Insurance/Insurance';
import { NoTracking } from './screens/NoTracking/NoTracking';
import { Review } from './screens/Review/Review';
import { ManualConnote } from './screens/ManualConnote/ManualConnote';
import { CustomLinkOrder } from './screens/CustomLinkOrder/CustomLinkOrder';
import { CourierReviews } from './screens/CourierReviews/CourierReviews';

interface Props {
  appPageUrl: string;
  type:
  | 'horizontal'
  | 'vertical'
  | 'app'
  | 'tracking'
  | 'rebook-collection'
  | 'redelivery'
  | 'cancel-consignment'
  | 'label-request'
  | 'insurance-claim'
  | 'no-tracking'
  | 'manual-connote'
  | 'review'
  | 'courier-reviews';
}

const App = (props: Props): JSX.Element => {
  const { appPageUrl, type } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const currentStep = useStore((state) => state.currentStep);
  const WPHeader = lazy(() => import('./components/WPHeader/WPHeader') as any);
  const WPShoppingCart = lazy(() => import('./components/WPShoppingCart/WPShoppingCart') as any);
  const WPFooter = lazy(() => import('./components/WPFooter/WPFooter') as any);
  const WPReviews = lazy(() => import('./components/WPReviews/WPReviews') as any);
 
  useEffect(() => {
    if (window.location.hash === '#/account/login') {
      window.location.href = `${CUSTOMER_PORTAL_URL}/login`;
    }
    if (window.location.hash === '#/account/register') {
      window.location.href = `${CUSTOMER_PORTAL_URL}/sign-up`;
    }
    if (window.location.hash === '#/account/forgot-password') {
      window.location.href = `${CUSTOMER_PORTAL_URL}/forgot-password`;
    }
    if (containerRef.current) {
      const top = containerRef.current.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scroll({ behavior: 'smooth', top });
    }
  }, [currentStep]);

  let children = null;
  switch (type) {
    case 'horizontal':
      children = <NewHorizontalWidget appPageUrl={appPageUrl} layout="horizontal" />;
      break;
    case 'vertical':
      children = <HorizontalWidget appPageUrl={appPageUrl} layout="vertical" />;
      break;
    case 'tracking':
      children = <Tracking />;
      break;
    case 'rebook-collection':
      children = <Rebook />;
      break;
    case 'redelivery':
      children = <Redelivery />;
      break;
    case 'cancel-consignment':
      children = <CancelConsignment />;
      break;
    case 'label-request':
      children = <LabelRequest />;
      break;
    case 'insurance-claim':
      children = <Insurance />;
      break;
    case 'no-tracking':
      children = <NoTracking />;
      break;
    case 'manual-connote':
      children = <ManualConnote />;
      break;
    case 'review':
      children = <Review />;
      break;
    case 'courier-reviews':
      children = <CourierReviews />;
      break;
    case 'app':
      children = (
        <div>
        <Router basename={process.env.REACT_APP_BASE_NAME}>
          <Bootstrap>
          {(window.location.hash !== "" || window.location.pathname === '/') && (
            <Suspense fallback={<div></div>}>
              <WPHeader />
            </Suspense>
          )}
            <AuthBar />
            <div className={styles.container} ref={containerRef}>
              <Switch>
                <Route exact path="/account/register-success" component={RegisterSuccess} />
                <LoggedOutRoute exact path="/account/*" component={Account} />
                <Route exact path="/package-details" component={PackageDetails} />
                <Route exact path="/quotes-comparisons" component={QuotesComparisons} />
                <Route exact path="/shipment-details" component={ShipmentDetails} />
                <Route exact path="/additional-info" component={AdditionalInfo} />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/order-complete" component={OrderComplete} />
                <Route exact path="/rebook-collection" component={Rebook} />
                <Route exact path="/redelivery" component={Redelivery} />
                <Route exact path="/cancel-consignment" component={CancelConsignment} />
                <Route exact path="/label-request" component={LabelRequest} />
                <Route exact path="/insurance-claim" component={Insurance} />
                <Route exact path="/no-tracking" component={NoTracking} />
                <Route exact path="/manual-connote" component={ManualConnote} />
                <Route exact path="/review" component={Review} />
                <Route exact path="/custom-link-order" component={CustomLinkOrder} />
                <Route exact path="/courier-reviews" component={CourierReviews} />
                <Route exact path="*" render={() => <Redirect to="/package-details" />} />
              </Switch>
            </div>
            
          </Bootstrap>
          {(window.location.hash !== "" || window.location.pathname === '/') && (
            <Suspense fallback={<div></div>}>
              <WPShoppingCart />
              <WPReviews />
              <WPFooter />
            </Suspense>
          )}
        </Router>
        </div>
      );
      break;
  }

  return (
    <>
      <Head />
      <div data-version="1.0.1" className={classNames(styles.root, 'FastCourierApp')}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </>
  );
}

export default App;
