import classNames from 'classnames';
import React from 'react';

import { Stepper } from '../Stepper/Stepper';
import styles from './Header.module.scss';

interface Props {
  className?: string;
}

export function Header(props: Props) {
  const { className } = props;

  return (
    <header className={classNames(styles.root, className)}>
      <Stepper />
    </header>
  );
}
