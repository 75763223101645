import classNames from 'classnames';
import React from 'react';
import { Label } from '../Label/Label';

import { Text } from '../Text/Text';
import styles from './RadioGroup.module.scss';

type Props<T> = {
  className?: string;
  inverted?: boolean;
  label?: React.ReactNode;
  type?: 'buttons' | 'radios';
  name: string;
  onChange: (value: T) => void;
  options: { label: string; value: T }[];
  value: T;
};

export function RadioGroup<T extends string | number = string | number>(props: Props<T>) {
  const { className, name, onChange, label, value, options, inverted = false, type = 'radios' } = props;

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.inverted]: inverted,
          [styles.typeRadios]: type === 'radios',
          [styles.typeButtons]: type === 'buttons',
        },
        className
      )}
    >
      {label && <Label text={label} inverted={inverted} />}
      <ul className={styles.radios}>
        {options.map((option, i) => (
          <li className={classNames(styles.radio, { [styles.checked]: option.value === value })} key={i}>
            <label className={styles.label}>
              <input
                name={name}
                type="radio"
                className={styles.input}
                onChange={() => onChange(option.value)}
                checked={option.value === value}
              />
              <div className={styles.background}>
                <div className={styles.tick} />
              </div>
              <Text
                color={(() => {
                  if (type === 'radios') {
                    return inverted ? 'white' : 'grey700';
                  } else {
                    return option.value === value ? 'white' : 'grey700';
                  }
                })()}
                className={styles.text}
              >
                {option.label}
              </Text>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}
