import classNames from 'classnames';
import React from 'react';

import styles from './H1.module.scss';

interface Props {
  alignment?: 'left' | 'center';
  children: React.ReactNode;
  className?: string;
  includeBorder?: boolean;
  inverted?: boolean;
  style?:Object
}

export function H1(props: Props) {
  const { className, children, includeBorder = true, alignment = 'left', inverted = false, style} = props;

  return (
    <h1
      className={classNames(
        styles.root,
        styles[alignment],
        { [styles.inverted]: inverted, [styles.includeBorder]: includeBorder },
        className
      )}
      style={style}
    >
      {children}
    </h1>
  );
}
