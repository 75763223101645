import React, { useEffect, useState } from 'react';
import { Card } from '../../components/Card/Card';
import { H2 } from '../../components/H2/H2';
import { Text } from '../../components/Text/Text';
import { useHistory, Redirect,useLocation } from 'react-router';
import { RebookStatus } from '../../types';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import * as api from '../../services/api';
import { useStore } from '../../store';
import styles from '../Review/Review.module.scss';

export function CustomLinkOrder() {
  const m = useLocation();
  const history = useHistory();
  console.log(history);

  const code = new URLSearchParams(m.search).get('order');
  const token = new URLSearchParams(m.search).get('token');
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [title, setTitle] = useState<string>('');
  const setPickupSuburb = useStore((state) => state.setPickupSuburb);
  const setPickupPostcode = useStore((state) => state.setPickupPostcode);
  const setPickupState = useStore((state) => state.setPickupState);
  const setPickupBuildingType = useStore((state) => state.setPickupBuildingType);
  const setPickupTailLift = useStore((state) => state.setPickupTailLift);
  const setDestinationSuburb = useStore((state) => state.setDestinationSuburb);
  const setDestinationPostcode = useStore((state) => state.setDestinationPostcode);
  const setDestinationState = useStore((state) => state.setDestinationState);
  const setDestinationBuildingType = useStore((state) => state.setDestinationBuildingType);
  const setDropOffTailLift = useStore((state) => state.setDropOffTailLift);
  const setDropOffPOBox = useStore((state) => state.setDropOffPOBox);
  const editItem = useStore((state) => state.editItem);
  const setQuoteId = useStore((s) => s.setQuoteId);
  const setCourierServiceName = useStore((s) => s.setCourierServiceName);
  const setQuotePrice = useStore((s) => s.setQuotePrice);
  const setQuoteInsurance = useStore((s) => s.setQuoteInsurance);
  const setQuoteDelivery = useStore((s) => s.setQuoteDelivery);
  const addInsurance = useStore((state) => state.addInsurance);
  const setQuoteExcludingGstPrice = useStore((s) => s.setQuoteExcludingGstPrice);
  const setOrderId = useStore((state) => state.setOrderId);
  const pushQuotes = useStore((state) => state.pushQuotes);
  const setPickupFirstName = useStore((state) => state.setPickupFirstName);
  const setPickupLastName = useStore((state) => state.setPickupLastName);
  const setPickupCompanyName = useStore((state) => state.setPickupCompanyName);
  const setPickupEmail = useStore((state) => state.setPickupEmail);
  const setPickupAddress1 = useStore((state) => state.setPickupAddress1);
  const setPickupAddress2 = useStore((state) => state.setPickupAddress2);
  const setPickupPhone = useStore((state) => state.setPickupPhone);
  const setDestinationFirstName = useStore((state) => state.setDestinationFirstName);
  const setDestinationLastName = useStore((state) => state.setDestinationLastName);
  const setDestinationCompanyName = useStore((state) => state.setDestinationCompanyName);
  const setDestinationEmail = useStore((state) => state.setDestinationEmail);
  const setDestinationAddress1 = useStore((state) => state.setDestinationAddress1);
  const setDestinationAddress2 = useStore((state) => state.setDestinationAddress2);
  const setDestinationPhone = useStore((state) => state.setDestinationPhone);
  const setPickupTimeWindow = useStore((state) => state.setPickupTimeWindow);
  const setCollectionDate = useStore((state) => state.setCollectionDate);
  const setParcelContent = useStore((state) => state.setParcelContent);
  const setSpecialInstructions = useStore((state) => state.setSpecialInstructions);
  const setValueOfContent = useStore((state) => state.setValueOfContent);
  const setExtendedLiability = useStore((state) => state.setExtendedLiability);
  const setInsuranceValue = useStore((state) => state.setInsuranceValue);
  const setInsuranceFee = useStore((state) => state.setInsuranceFee);
  const setAuthorityToLeave = useStore((state) => state.setAuthorityToLeave);
  const setNoPrinter = useStore((state) => state.setNoPrinter);
  const setSenderType = useStore((state) => state.setSenderType);
  const setCurrentStep = useStore((s) => s.setCurrentStep);
  const addItem = useStore((state) => state.addItem);

  useEffect(() => {
    (async () => {
      if (code === '' && token === '') {
        return;
      }
      setStatus('in-progress');
      try {
        const response = await api.get<any>(`/custom-link-order/${code}/${token}`);
        setStatus('idle');
        if(response.status){
          setStatus('found');
          setReduxStore(response);
          
        }else{
          setStatus('not-found');
          setTitle(response.message)
        }
      } catch (e) {
        setStatus('idle');
      }
    })();
  }, []);

  const setReduxStore = (response: any) => {
    
    setStep1ReduxStore(response);
    
    setStep2ReduxStore(response);

    setStep3ReduxStore(response);

    //redirect on particular step
    if(response.data.status === 'package_details_completed' && response.data.quotes.length > 0){
      setCurrentStep(2);
      history.push('/quotes-comparisons');
    }else if(response.data.status === 'select_quote_completed'){
      setCurrentStep(3);
      history.push('/shipment-details?courierService=' + response.data.selected_quote.courier.name);
    }else if(response.data.status === 'shipment_details_completed'){
      setCurrentStep(4);
      history.push('/additional-info');
    }else{
      setCurrentStep(1);
      history.push('/package-details');
    }
  }

  const setStep1ReduxStore = (response: any) => {
    /**
     * Step - 1 Package Details
    */
    setPickupSuburb(response.data.pickup_suburb);
    setPickupPostcode(response.data.pickup_postcode);
    setPickupState(response.data.pickup_state);
    setPickupBuildingType(response.data.pickup_building_type);
    setPickupTailLift(response.data.is_pickup_tail_lift);
    setDestinationSuburb(response.data.destination_suburb);
    setDestinationPostcode(response.data.destination_postcode);
    setDestinationState(response.data.destination_state);
    setDestinationBuildingType(response.data.destination_building_type);
    setDropOffTailLift(response.data.is_dropoff_tail_lift);
    setDropOffPOBox(response.data.is_dropoff_po_box);
    let items = JSON.parse(response.data.items);
  
    items.forEach((item: any, index: any) =>{
      if(index >= 1){
        addItem();
      }
     editItem(index, 'type', item.type);
     editItem(index, 'weight', item.weight);
     editItem(index, 'length', item.length);
     editItem(index, 'width', item.width);
     editItem(index, 'height', item.height);
     editItem(index, 'quantity', item.quantity);
     editItem(index, 'calculatedLength', item.length);
     editItem(index, 'calculatedWidth', item.width);
     editItem(index, 'calculatedHeight', item.height);
    });
  }

  const setStep2ReduxStore = (response: any) => {
    /**
     * Step - 2 Quote Comparison
     */
    setOrderId(response.data.hash_id);

    if(response.data.quotes.length > 0){
      let customLinkOrderQuotes: any = [];
      let couriers_list = [
                            'Couriers Please',
                            'Star Track',
                            'TNT',
                            'AlliedExpress',
                            'Toll',
                            'Aramex',
                            'Hunter Express',
                            'Toll Palletised Express',
                            'Direct Couriers',
                            'Alpha Freight',
                            'Bonds Couriers',
                            'Zoom2u',
                            'Northline',
                            'Josies Transport',
                            'CTI Logistics Regional Freight',
                            'Felix Transport',
                            'Capital Transport'
                          ];
      response.data.quotes.forEach((quote: any, index: any) => {
          let setQuote = {
            'id': quote.hash_id,
            'courierName': couriers_list[quote.courier_id - 1],
            'eta': quote.eta,
            'logo': quote.logo_path,
            'name': quote.service_name,
            'rating': quote.rating,
            'pickupCutOffTime': quote.pickup_cutoff,
            'priceExcludingGst': quote.price_excluding_gst,
            'priceIncludingGst': quote.price_including_gst,
            'insuranceCategory': quote.insurance_category,
            'insurance_link': 'http://app.fastcourier.test/documents/QM3248-1118-CourierSure-Goods-in-Transit-Marine-Policy-web.pdf',
          };
          customLinkOrderQuotes.push(setQuote);
          
      });

      pushQuotes(customLinkOrderQuotes, true);
    }

    if(response.data.selected_quote){
      setQuoteId(response.data.selected_quote.hash_id);
      setCourierServiceName(response.data.selected_quote.courier.name);
      setQuotePrice(response.data.selected_quote.price_excluding_gst);
      setQuoteExcludingGstPrice(response.data.selected_quote.price_excluding_gst);
      setQuoteDelivery(response.data.selected_quote.eta);
      setQuoteInsurance(response.data.selected_quote.insurance_category);
      addInsurance(response.insurance);
    }
  }

  const setStep3ReduxStore = (response: any) => {
      if(response.data.pickup_first_name){
        setPickupFirstName(response.data.pickup_first_name);
        setPickupLastName(response.data.pickup_last_name);
        setPickupCompanyName(response.data.pickup_company_name);
        setPickupEmail(response.data.pickup_email);
        setPickupAddress1(response.data.pickup_address_1);
        setPickupAddress2(response.data.pickup_address_2);
        setPickupPhone(response.data.pickup_phone);
        setDestinationFirstName(response.data.destination_first_name);
        setDestinationLastName(response.data.destination_last_name);
        setDestinationCompanyName(response.data.destination_company_name);
        setDestinationEmail(response.data.destination_email);
        setDestinationAddress1(response.data.destination_address_1);
        setDestinationAddress2(response.data.destination_address_2);
        setDestinationPhone(response.data.destination_phone);
        setPickupTimeWindow(response.data.pickup_time_windows);
        setCollectionDate(response.data.collection_date);
        setParcelContent(response.data.parcel_content);
        setSpecialInstructions(response.data.special_instructions);
        setValueOfContent(response.data.value_of_content);
        setExtendedLiability(response.data.extended_liability);
        setInsuranceValue(response.data.insurance_value);
        setInsuranceFee(response.data.insurance_fee);
        setAuthorityToLeave(response.data.is_authority_to_leave);
        setNoPrinter(response.data.is_no_printer);
        setSenderType(response.data.sender_type);
    }
  }

  return (
            <div className={styles.root}>
                <OverlaySpinner enabled={status === 'in-progress'} />
                {status === 'not-found' && (
                    <Card color="grey" className={styles.card}>
                    <Text color="grey900" className={styles.note}>{title}</Text>
                    </Card>
                )}
            </div>
        );
}
