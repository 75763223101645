import { FRONTEND_URL } from '../../config';

export function Head() {
  let __html = '';

  if (process.env.NODE_ENV === 'development') {
    __html += `
      @font-face {
        font-family: 'vagroundedstd';
        src: url('${FRONTEND_URL}/fonts/VAGRoundedStd-Thin.woff2') format('woff2'), 
          url('${FRONTEND_URL}/fonts/VAGRoundedStd-Thin.woff') format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'vagroundedstd';
        src: url('${FRONTEND_URL}/fonts/VAGRoundedStd-Light.woff2') format('woff2'),
          url('${FRONTEND_URL}/fonts/VAGRoundedStd-Light.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'vagroundedstd';
        src: url('${FRONTEND_URL}/fonts/VAGRoundedStd-Bold.woff2') format('woff2'), 
          url('${FRONTEND_URL}/fonts/VAGRoundedStd-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'vagroundedstd';
        src: url('${FRONTEND_URL}/fonts/VAGRoundedStd-Black.woff2') format('woff2'),
          url('${FRONTEND_URL}/fonts/VAGRoundedStd-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
      }
    `;
  }

  if (document.body.classList.contains('local-no-wordpress')) {
    console.log('Running in local - no wordpress wrapper');
    __html += `
      .FastCourierApp {
        width: 100%;
      }
    `;
  }

  return <style dangerouslySetInnerHTML={{ __html }} />;
}
