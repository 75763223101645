import React, { MouseEvent, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { Input } from '../../components/Input/Input';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Text } from '../../components/Text/Text';
import { Panel } from '../../components/Panel/Panel';
import styles from './ManualConnote.module.scss';
import * as api from '../../services/api';
import { RebookStatus } from '../../types';
import { Form } from 'react-bootstrap';
import FormData from 'form-data'

export function ManualConnote() {
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [referenceId, setReferenceId] = useState<string>('');
  const [manualConnoteNumber, setManualConnoteNumber] = useState<string>('');
  const [file, setFile] = useState(null);
  const [fileValue, setFileValue] = useState<any>(null);

  const [apiMessage, setApiMessage] = useState<string>('');

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

      setStatus('in-progress');
      try {
        await api.csrf();
        let formData = new FormData();
        formData.append('referenceId',referenceId);
        formData.append('manualConnoteNumber',manualConnoteNumber);
        formData.append('file',file);
        const response = await api.post<any>(`/manual-connote`, formData);
        setStatus('idle');
        if(response.status){
          setReferenceId('');
          setManualConnoteNumber('');
          setFile(null);
          if(fileValue){
            fileValue.target.value = null;
          }
          
        }
        setApiMessage(response.message);
      } catch (e) {
        setStatus('idle');
        setApiMessage("Something went wrong!");
      }
  };


  const onChangeFile = (e: any) => {
    setFile(e.target.files[0]);
    setFileValue(e);
  }

  return (
    <div className={styles.root}>
      <OverlaySpinner enabled={status === 'in-progress'} />
      {apiMessage && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}
      
      {(status === 'idle' || status==='in-progress') && (
      <div>
        
        <Input
          className={styles.input}
          label="Reference ID"
          name="referenceId"
          value={referenceId}
          onChange={setReferenceId}
          errors={{}}
          required
        />
        <Input
          className={styles.input}
          label="Manual Connote Number"
          name="manualConnoteNumber"
          value={manualConnoteNumber}
          onChange={setManualConnoteNumber}
          errors={{}}
          required
        />
        <span className={styles.labelHeading}>Image</span>
        <Form>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control
              type="file"
              name="file"
              className={'form-control'}
              onChange={(e)=>{onChangeFile(e)}}
            />
          </Form.Group>
        </Form>
      </div>
      )}

      {status === 'idle' && (
      <div className={styles.toolbar} >
        <Button type="primary" onClick={submit}>Submit</Button>
      </div>
      )}

    </div>
  );
}
