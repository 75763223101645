import { useHistory } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';

import { Button } from '../../components/Button/Button';
import { H1 } from '../../components/H1/H1';
import { HOME_URL } from '../../config';
import { Link } from '../../components/Link/Link';
import { OrderSummary } from '../../components/OrderSummary/OrderSummary';
import { Text } from '../../components/Text/Text';
import styles from './OrderComplete.module.scss';
import { useStore } from '../../store';
import { CUSTOMER_PORTAL_URL } from '../../config';
import Cookies from 'js-cookie';

export function OrderComplete() {
  const m = useLocation();
  const history = useHistory();
  const orderId = new URLSearchParams(m.search).get('ref');
  const newUser = new URLSearchParams(m.search).get('new');
  const coinbase = new URLSearchParams(m.search).get('coinbase');
  const currency = new URLSearchParams(m.search).get('currency');
  const label = new URLSearchParams(m.search).get('label');
  const invoice = new URLSearchParams(m.search).get('invoice');
  const payment_type = new URLSearchParams(m.search).get('payment_type');
  const customOrder = useStore((state) => state.customOrder);
  const setOrderId = useStore((state) => state.setOrderId);
  const setOrderRefId = useStore((state) => state.setOrderRefId);
  const setSendSamePackage = useStore((state) => state.setSendSamePackage);
  let newUserData = null;
  let coinbasePayment = null;
  let coinbasePaymentCurrency = null;

  const oldUser = Cookies.get('loggedin_user');//get user email

  console.log(oldUser);

  if (oldUser) {
    if (typeof oldUser === 'undefined') {
      if (newUser) {
        try {
          newUserData = JSON.parse(atob(newUser));
        } catch (e) { }
      }
    }
  } else {
    if (newUser) {
      try {
        newUserData = JSON.parse(atob(newUser));
      } catch (e) { }
    }
  }

  if (!orderId) {
    return <Redirect to="/package-details" />;
  } else {
    setSendSamePackage(false);
    setOrderId('');
    setOrderRefId(orderId);
  }
  if (coinbase && coinbase == 'payment_initiated') {
    try {
      coinbasePayment = coinbase;
    } catch (e) { }
  }

  if (currency) {
    try {
      coinbasePaymentCurrency = currency;
    } catch (e) { }
  }
  const setSamePackage = () => {
    setSendSamePackage(true);
    history.push('/package-details');
  };
  return (
    <div className={styles.root}>
      <H1>Success!</H1>
      <OrderSummary
        className={styles.orderSummary}
        addressDetailsEditEnabled={false}
        quoteEditEnabled={false}
        packageDetailsEditEnabled={false}
      />
      {coinbasePayment && coinbasePaymentCurrency && !customOrder && (
        <Text className={styles.text}>
          Your payment with Fast Courier with via {coinbasePaymentCurrency} Coinbase has been initiated.
          <br />
          Your Consignment and Booking will be processed within 30 minutes upon sufficient transaction confirmations on the blockchain.
          <br />
          <br />
          <strong>Reference:</strong> <code>{orderId}</code>
        </Text>
      )}
      {!coinbasePayment && !customOrder && (
        <Text className={styles.text}>
          Your order has been successfully submitted. You will receive an email shortly.
          <br />
          If you do not receive an email, please double check your spam/junk folder.
          <br />
          <br />
          <strong>Reference:</strong> <code>{orderId}</code>
          {!newUserData && !oldUser && (
            <div>
              {label && (
                <div>
                  <div className={styles.parentButtonDiv}>
                    <div className={styles.buttonBar}>
                      <Button onClick={setSamePackage}>Send the same package</Button>
                    </div>

                    <div className={styles.buttonBar}>
                      <Link className={styles.samePackageButton} href={label || ''}><Button>Download Labels</Button></Link>
                    </div>

                  </div>
                  <div className={styles.parentButtonDiv}>
                    <div className={styles.buttonBar}>
                      <Link href={invoice || ''}><Button>Download Invoice</Button></Link>
                    </div>
                  </div>
                </div>

              )}
              {!label && (
                <div className={styles.parentButtonDiv}>
                  <div className={styles.buttonBar}>
                    <Button onClick={setSamePackage}>Send the same package</Button>
                  </div>

                  <div className={styles.buttonBar}>
                    <Link className={styles.samePackageButton} href={invoice || ''}><Button>Download Invoice</Button></Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </Text>
      )}

      {customOrder && (
        <Text className={styles.text}>
          Your order has been successfully submitted..
          <br />
          <br />
          <strong>Reference:</strong> <code>{orderId}</code>
          {!newUserData && (
            <div>
              {label && (
                <div>
                  <div className={styles.parentButtonDiv}>
                    <div className={styles.buttonBar}>
                      <Button onClick={setSamePackage}>Send the same package</Button>
                    </div>
                    <div className={styles.buttonBar}>
                      <Link className={styles.samePackageButton} href={label || ''}><Button>Download Labels</Button></Link>
                    </div>
                  </div>
                  <div className={styles.parentButtonDiv}>
                    <div className={styles.buttonBar}>
                      <Link href={invoice || ''}><Button>Download Invoice</Button></Link>
                    </div>
                  </div>
                </div>

              )}
              {!label && (
                <div className={styles.parentButtonDiv}>
                  <div className={styles.buttonBar}>
                    <Button onClick={setSamePackage}>Send the same package</Button>
                  </div>

                  <div className={styles.buttonBar}>
                    <Link className={styles.samePackageButton} href={invoice || ''}><Button>Download Invoice</Button></Link>
                  </div>
                </div>
              )}

            </div>


          )}
        </Text>
      )}

      {oldUser && (
        <Text className={styles.text}>
          {payment_type !== '2' && (
            <div> It looks like you are already logged in to an account which is associated with {oldUser}. To bind this order with your loggedin account click below.</div>
          )}
          <div className={styles.parentButtonDiv}>
            {payment_type !== '2' && (
              <div className={styles.buttonBar}>
              <Link href={CUSTOMER_PORTAL_URL + "/bind?refId=" + orderId + "&email=" + encodeURIComponent(oldUser)}><Button>Assign order to my account</Button></Link>
            </div>
            )}
            <div className={styles.buttonBar}>
              <Button className={styles.samePackageButton} onClick={setSamePackage}>Send the same package</Button>
            </div>
          </div>
          
          <div className={styles.parentButtonDiv}>
            {label && (
              <div className={styles.buttonBar}>
                <Link href={label || ''}><Button>Download Labels</Button></Link>
              </div>
            )}
            
            <div className={styles.buttonBar}>
              <Link className={label ? styles.samePackageButton : ''} href={invoice || ''}><Button>Download Invoice</Button></Link>
            </div>
          </div>
        </Text>
      )}

      {newUserData && (
        <Text className={styles.text}>
          It looks like this is your first order with us, welcome {newUserData.firstName}!
          <br />
          Click below to quickly register an account to help track future orders.
          <div className={styles.parentButtonDiv}>
            <div className={styles.buttonBar}>
              <Link href={CUSTOMER_PORTAL_URL + "/sign-up?refId=" + orderId}><Button>Quick register</Button></Link>
            </div>
            <div className={styles.buttonBar}>
              <Button className={styles.samePackageButton} onClick={setSamePackage}>Send the same package</Button>
            </div>
          </div>
          <div className={styles.parentButtonDiv}>
            {label && (
              <div className={styles.buttonBar}>
                <Link href={label || ''}><Button>Download Labels</Button></Link>
              </div>
            )}

            <div className={styles.buttonBar}>
              <Link className={label ? styles.samePackageButton : ''} href={invoice || ''}><Button>Download Invoice</Button></Link>
            </div>
          </div>
        </Text>
      )}
      <div className={styles.toolbar}>
        <Link href={HOME_URL}>Return Home</Link>
      </div>
    </div>
  );
}
