import React, { MouseEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Alert } from '../../../components/Alert/Alert';
import { Button } from '../../../components/Button/Button';
import { H1 } from '../../../components/H1/H1';
import { Input } from '../../../components/Input/Input';
import { Link } from '../../../components/Link/Link';
import { OverlaySpinner } from '../../../components/OverlaySpinner/OverlaySpinner';
import { Status } from '../../../types';
import { Text } from '../../../components/Text/Text';
import { track } from '../../../util';
import * as api from '../../../services/api';
import styles from '../Account.module.scss';

export function Register() {
  const m = useLocation();
  const newUser = new URLSearchParams(m.search).get('new');
  let newUserData = null;
  if (newUser) {
    try {
      newUserData = JSON.parse(atob(newUser));
    } catch (e) {}
  }

  const [email, setEmail] = useState(newUserData?.email ?? '');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(newUserData?.firstName ?? '');
  const [lastName, setLastName] = useState(newUserData?.lastName ?? '');
  const [status, setStatus] = useState<Status>('idle');
  const history = useHistory();

  const register = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setStatus('in-progress');
    try {
      await api.csrf();
      await api.register(firstName, lastName, email, password);

      track('app-register', { firstName, lastName, email });
      history.push('/account/register-success');
    } catch (e) {
      setStatus('error');
    }
  };

  return (
    <>
      <OverlaySpinner enabled={status === 'in-progress'} />
      <H1 alignment="center" className={styles.heading}>
        Register
      </H1>
      <div className={styles.container}>
        <form className={styles.form}>
          <Input
            className={styles.input}
            label="First name"
            name="firstName"
            value={firstName}
            onChange={setFirstName}
            errors={{}}
          />
          <Input
            className={styles.input}
            label="Last name"
            name="lastName"
            value={lastName}
            onChange={setLastName}
            errors={{}}
          />
          <Input className={styles.input} label="Email" name="email" value={email} onChange={setEmail} errors={{}} />
          <Input
            className={styles.input}
            label="Password"
            name="password"
            value={password}
            onChange={setPassword}
            type="password"
            errors={{}}
          />
          {status === 'error' && (
            <Alert className={styles.alert} type="error">
              All fields are required. Your password must be a minimum of 8 characters long.
              <br />
              If you have a Fast Courier account, please log in using the link below.
            </Alert>
          )}
          <div className={styles.toolbar}>
            <Text className={styles.text}>
              Already have an account?
              <Link className={styles.link} to="/account/login">
                Log in
              </Link>
            </Text>
            <Button onClick={register} block className={styles.button}>
              Register
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
