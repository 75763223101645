import React, { useEffect, useState } from 'react';

import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { get, csrf } from '../../services/api';
import { H3 } from '../../components/H3/H3';
import { Input } from '../../components/Input/Input';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Panel } from '../../components/Panel/Panel';
import { Status, TrackingEvent } from '../../types';
import { Text } from '../../components/Text/Text';
import styles from './Tracking.module.scss';
import '../../styles/tracking.css';

export function Tracking() {
  const codeParam = new URLSearchParams(document.location.search).get('code');
  const [code, setCode] = useState<string | null>(codeParam ?? '');
  const [codeValue, setCodeValue] = useState<string>(codeParam ?? '');
  const [events, setEvents] = useState<TrackingEvent[]>([]);
  const [status, setStatus] = useState<Status>('idle');
  const [requested, setRequested] = useState(false);
  const [masterStatus, setMasterStatus] = useState<string | null>('');
  const [consignmentCode, setConsignmentCode] = useState<string | null>('');
  const [courierWebsite, setCourierWebsite] = useState<string | null>('');
  const [courierName, setCourierName] = useState<string | null>('');

  useEffect(() => {
    (async () => {
      if (code === '') {
        return;
      }
      setEvents([]);
      setStatus('in-progress');
      // setRequested(true);
      try {
        await csrf();
        const { data }: any = await get(`/tracking?code=${code}`);
        
        setEvents(data.events);
        setConsignmentCode(data.item);

        const events = data.events;

        // Check if there are events and if the last event has the master_status parameter
        if (events.length > 0 && events[events.length - 1].hasOwnProperty('master_status')) {
          
          setMasterStatus(events[events.length - 1].master_status ? events[events.length - 1].master_status : 'On its Way');
        }

        if(events.length > 0){
          setCourierWebsite(events[0].website_link);
          setCourierName(events[0].courier);
        }
        setStatus('complete');
      } catch (e) {
        setStatus('error');
      }
    })();
  }, [code]);

  return (
    <div className={styles.root}>
      <OverlaySpinner enabled={status === 'in-progress'} />
      <Card color="grey">
        <Text color="grey900">
          Already booked a delivery through Fast Courier? Enter your tracking number below to check on its progress:
        </Text>
        <div className={styles.inputWrapper}>
          <Input name="Code" value={codeValue} onChange={setCodeValue} errors={{}} />
          <Button className={styles.button} onClick={() => setCode(codeValue.trim())} rounding="roundedSm">
            Check
          </Button>
        </div>
      </Card>

      {status === 'complete' && events.length === 0 && (
        <Card className={styles.results} color="grey">
          <Panel>
            <H3>Your order hasn't been picked up yet...</H3>
            <Text>We haven't received any updates from your selected courier yet. Please check back shortly</Text>
          </Panel>
        </Card>
      )}
      {status === 'error' && (
        <Card className={styles.results} color="grey">
          <Panel>
            <H3>We couldn't find that code...</H3>
            <Text>
              We couldn't find any details about the provided code. Please check the Fast Courier email for your
              reference code
            </Text>
          </Panel>
        </Card>
      )}

      {status === 'complete' && events.length !== 0 && (
        <div className='pp-component-wrapper overflow-hidden'>
              <div className='p-6'>
                <div>
                  <div className="overflow-hidden transition-[max-height] ease-in-out duration-300">
                  {events.map((item, i) => (
                      <div className="py-3 text-neutral-700 first:text-neutral-900" key={i} style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <div className="sm:flex items-end">
                          <div className="flex-1">
                            <p className="font-medium text-md">{item.master_status ? item.master_status : 'Processing'}</p>
                          </div>
                          <div className="flex-1">
                            <p className="text-neutral-700-courier-name text-xs sm:text-sm sm:text-right">{item.courier}</p>
                          </div>
                        </div>
                        <div className="sm:flex mt-1 text-sm">
                          <div className="flex-1">
                            <p className="hidden sm:block">{item.date} {item.time}</p>
                          </div>
                        </div>
                        <div className="sm:w-3/4 mt-1 flex">
                          <div className="flex w-full sm:w-auto" data-for="tooltip_completed_0" data-tip="true">
                            <div className="flex-none mr-2 sm:pt-px align-items-start">
                              <img alt="Carrier Logo" decoding="async" data-nimg="1" className="w-full h-auto" src={item.img} style={{height: '30px', width: '65px', marginRight: '10px'}}/>
                              <p className="text-xs sm:text-sm" style={{wordBreak: 'break-all'}}>{item.action}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  ))}
                  </div>
                  <div style={{paddingLeft: '15px', paddingTop: '15px'}}>For additional tracking</div>
                  <div style={{paddingLeft: '15px'}}>Use tracking number: <span style={{ color: '#f76b00' }}>{consignmentCode}</span> on <span style={{ color: '#f76b00' }}>{courierName}</span> website: 
                    <a
                      target="_blank"
                      style={{ textDecoration: 'none', color: '#f76b00' }}
                      href={courierWebsite ? courierWebsite : ''} rel="noreferrer"
                    >
                      {courierWebsite}
                    </a>
                  </div>
                </div>
              </div>
        </div>
      )}
    </div>
  );
}
