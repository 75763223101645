import React, { ReactNode } from 'react';
import { Route, RouteComponentProps, RouteChildrenProps, Redirect } from 'react-router-dom';
import { Location } from 'history';
import { useStore } from '../../store';

interface Props {
  location?: Location;
  component?: any;
  render?: (props: RouteComponentProps<any>) => ReactNode;
  children?: ((props: RouteChildrenProps<any>) => ReactNode) | ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

export function LoggedOutRoute(props: Props) {
  const { component: Component, ...rest } = props;

  const isLoggedIn = useStore((s) => s.isLoggedIn);

  return (
    <Route
      path={rest.path}
      exact={rest.exact}
      render={(props: any) => {
        if (isLoggedIn) {
          // Logged in but should not be
          return <Redirect to={{ pathname: '/package-details', state: { from: props.location } }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
}
