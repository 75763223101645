export type BuildingType = 'commercial' | 'residential';

export const BuildingTypes: { label: string; value: BuildingType }[] = [
  { label: 'Commercial', value: 'commercial' },
  { label: 'Residential', value: 'residential' },
];

export type Color = 'grey600' | 'grey700' | 'grey800' | 'grey900' | 'orange500' | 'white';

export type Item = {
  type: PackageType;
  weight: any;
  length: any;
  width: any;
  height: any;
  quantity: number;
  contents: ContentType;
  calculatedLength: any;
  calculatedHeight: any;
  calculatedWidth: any;
};

export type extendedLiabilities = {
  id: string;
  item_value: string;
  additional_fee: string;
};

export type Email = {
  email: string;
};

export type PackageType =
  | 'bag'
  | 'box'
  | 'carton'
  | 'crate'
  | 'document'
  | 'drum'
  | 'envelope'
  | 'item'
  | 'jif'
  | 'pallet'
  | 'pieces'
  | 'roll'
  | 'satchel'
  | 'skid';

  export type ContentType =
  | ''
  | 'alcohol'
  | 'glass/fragile goods'
  | 'other liquids'
  | 'food'
  | 'plants'
  | 'vehicle parts/wheels'
  | 'artwork'
  | 'household contents/personal effects'
  | 'other';


export const PackageTypes: { label: string; value: PackageType }[] = [
  { label: 'Bag', value: 'bag' },
  { label: 'Box', value: 'box' },
  { label: 'Carton', value: 'carton' },
  { label: 'Crate', value: 'crate' },
  { label: 'Document', value: 'document' },
  { label: 'Drum', value: 'drum' },
  { label: 'Envelope', value: 'envelope' },
  { label: 'Item', value: 'item' },
  { label: 'Jif', value: 'jif' },
  { label: 'Pallet', value: 'pallet' },
  { label: 'Pieces', value: 'pieces' },
  { label: 'Roll', value: 'roll' },
  { label: 'Satchel', value: 'satchel' },
  { label: 'Skid', value: 'skid' },
];

export const ContentTypes: { label: string; value: ContentType }[] = [
  { label: '', value: '' },
  { label: 'Alcohol', value: 'alcohol' },
  { label: 'Glass/Fragile goods', value: 'glass/fragile goods' },
  { label: 'Other liquids', value: 'other liquids' },
  { label: 'Food', value: 'food' },
  { label: 'Plants', value: 'plants' },
  { label: 'Vehicle Parts/Wheels', value: 'vehicle parts/wheels' },
  { label: 'Artwork', value: 'artwork' },
  { label: 'Household contents/Personal effects', value: 'household contents/personal effects' },
  { label: 'Other', value: 'other' },
];

export type Quote = {
  courierName: string;
  eta: string;
  id: string;
  rating:number;
  insuranceCategory: string;
  logo: string;
  name: string;
  pickupCutOffTime: string;
  price: number;
  priceExcludingGst: number;
  priceIncludingGst: number;
  subLabel?: string;
  insurance_link?: string;
};

export type Suburb = {
  postcode: string;
  name: string;
  state: string;
};

export type SenderType = 'sender' | 'receiver' | 'third_party';

export const SenderTypes: { label: string; value: SenderType }[] = [
  { label: 'Sender', value: 'sender' },
  { label: 'Receiver', value: 'receiver' },
  { label: 'Third Party', value: 'third_party' },
];

export type Status = 'idle' | 'in-progress' | 'complete' | 'error' | 'large-package-dimensions-error' | 'small-package-dimensions-error' | 'empty-package-dimensions-error' | 'empty-contents-error';

export type TrackingEvent = {
  action: string;
  date: string;
  time: string;
  contractor: string;
  master_status: string;
  courier: string;
  img: string;
};

export type ValidationErrors = {
  [name: string]: string[];
};


export type RebookStatus = 'idle' | 'found' | 'not-found' | 'in-progress' | 'completed' | 'error';

export type ClaimType =
  | 'damage'
  | 'lost';

export const ClaimTypes: { label: string; value: ClaimType }[] = [
  { label: 'Damage Freight', value: 'damage' },
  { label: 'Lost Freight', value: 'lost' },
];

export type ConfirmType =
  | 'yes'
  | 'no';

export const ConfirmTypes: { label: string; value: ConfirmType }[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export type ApiStatus = '' | 'true' | 'false';