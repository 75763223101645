import classNames from 'classnames';
import React from 'react';

import { Label } from '../Label/Label';
import { ValidationError } from '../ValidationError/ValidationError';
import { ValidationErrors } from '../../types';
import styles from './Input.module.scss';

interface Props<T> {
  className?: string;
  disabled?: boolean;
  errors: ValidationErrors;
  icon?: string;
  inputClassName?: string;
  inverted?: boolean;
  hasBorder?: boolean;
  label?: React.ReactNode;
  layout?: 'vertical' | 'horizontal';
  min?: number;
  max?: number;
  name: string;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: T) => void;
  placeholder?: string;
  required?: boolean;
  size?: 'regular' | 'large';
  type?: 'text' | 'number' | 'password';
  value: T;
  id?: string;
}

export function Input<T extends string | number = string>(props: Props<T>) {
  const {
    className,
    disabled = false,
    errors,
    icon,
    inputClassName,
    inverted = false,
    hasBorder = true,
    label = '',
    layout = 'vertical',
    min,
    max,
    name,
    maxLength,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    placeholder = '',
    required = false,
    size = 'regular',
    type = 'text',
    value,
    id,
  } = props;

  return (
    <div
      className={classNames(
        styles.root,
        { [styles.hasIcon]: icon, [styles.inverted]: inverted, [styles.hasBorder]: hasBorder },
        styles[size],
        styles[layout],
        className
      )}
    >
      <Label
        className={styles.label}
        inverted={inverted}
        required={required}
        text={label}
        textClassName={styles.labelText}
      >
        <div className={styles.wrapper}>
          <input
            className={classNames(styles.input, inputClassName)}
            autoCapitalize="none"
            disabled={disabled}
            name={name}
            maxLength={maxLength}
            onFocus={onFocus}
            onBlur={onBlur}
            id={id}
            onChange={({ currentTarget }) => {
              let value;
              if (type === 'number') {
                if (currentTarget.value === '') {
                  value = '';
                } else if (!Number.isNaN(Number(currentTarget.value))) {
                  if ((min && Number(currentTarget.value) <= min) || (max && Number(currentTarget.value) >= max)) {
                    return;
                  }
                  if(currentTarget.name === 'pickupPhone' || currentTarget.name === 'destinationPhone'){
                    value = currentTarget.value;
                  }else if(currentTarget.name !== 'weight'){
                    value = Number(currentTarget.value);
                  }else if(currentTarget.name === 'weight'){
                    let tempValue = parseFloat(currentTarget.value);
                    if(tempValue !== 0){
                      if(tempValue < 0.1){
                        value = 0.1;
                      }else{
                        value = currentTarget.value;
                      }
                    }else{
                      value = currentTarget.value;
                    }
                  }else{
                    value = currentTarget.value;
                  }
                } else {
                  value = '';
                }
              } else {
                value = String(currentTarget.value);
              }
              onChange(value as T);
            }}
            placeholder={placeholder}
            type={type === 'password' ? 'password' : 'text'}
            value={value}
          />
          {icon && <i className={classNames(styles.icon, icon)} />}
          <ValidationError className={styles.error} errors={errors} name={name} />
        </div>
      </Label>
    </div>
  );
}
