import React, { MouseEvent, useEffect, useState } from 'react';
import { Card } from '../../components/Card/Card';
import { H2 } from '../../components/H2/H2';
import { Button } from '../../components/Button/Button';
import { Text } from '../../components/Text/Text';
import styles from './Review.module.scss';
import { RebookStatus } from '../../types';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import * as api from '../../services/api';
import { Panel } from '../../components/Panel/Panel';
import { Form } from 'react-bootstrap';
import { track } from '../../util';
import ReactStars from 'react-stars'

type Props = {
  className?: string;
};

export function Review(props: Props) {
  const codeParam = new URLSearchParams(document.location.search).get('code');
  console.log(codeParam);
  const [code, setCode] = useState<string | null>(codeParam ?? '');
  const [rating, setRating] = useState<number>(0);
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [apiMessage, setApiMessage] = useState<string>('');
  const [reviewText, setReviewText] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [submited, setSubmited] = useState<boolean>(false);
  const [data, setData] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (code === '') {
        return;
      }
      setStatus('in-progress');
      try {
        const response = await api.get<any>(`/review/${code}`);
        setStatus('idle');
        if(response.status){
          setStatus('found')          
        }else{
          setStatus('not-found')
        }
        if(response.email){
          setEmail(response.email);
        }
        setData(response.data);
        setTitle(response.message)
      } catch (e) {
        setStatus('idle');
        setSubmited(true);
        setApiMessage("Something went wrong!");
      }
    })();
  }, [codeParam]);

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

      setStatus('in-progress');
      try {
        await api.csrf();
        const response = await api.post<any>(`/review/${code}`, {
          rating: rating,
          review: reviewText
        });
        setStatus('idle');
        if(response.status){
          setStatus('found')    
          setSubmited(true);
          setData(true);
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();

          let date = yyyy + '-' + mm + '-' + dd;
          
          const payload = {
            email: email,
            rating: rating,
            rawReview: reviewText,
            submisisonDate: date,
            hashID: code
          };
          track('review-submitted', { payload });
        }
        setTitle(response.message)
        setApiMessage("");
      } catch (e) {
        setStatus('idle');
        setApiMessage("Something went wrong!");
      }
  };

  return (
    <div className={styles.root}>
      <OverlaySpinner enabled={status === 'in-progress'} />

      {apiMessage && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}

      {status == 'found' && !submited && !data && (
        <Card color="grey" className={styles.card}>
          <H2 className={styles.heading}>How was your experience</H2>

          <Text className={styles.note}>{rating == 0 && ("Click on the stars below to select the rating") || rating <= 1 && ("Terrible (" + rating + ")") || rating <= 2 && rating > 1 && ("Bad (" + rating + ")") || rating <= 3 && rating > 2 && ("Good (" + rating + ")") || rating <= 4 && rating > 3 && ("Very Good (" + rating + ")") || rating > 4 && ("Excellent (" + rating + ")")}</Text>

          <ReactStars
            className={styles.ratingbar}
            count={5}
            value={rating}
            onChange={setRating}
            size={48}
            half={true}
            color2={'#f76b00'} />

          <Form.Label className={styles.labelHeading}>Review (Optional)</Form.Label>
          <Form.Control
            className={styles.reviewInput}
            as="textarea"
            rows={3}
            type="text"
            name="specialInstructions"
            value={reviewText}
            onChange={(e) => {
              setReviewText(e.target.value || '');
            }}
          />

          <Button type="primary" onClick={submit} disabled={rating <= 0}>Submit</Button>

          <Text color="grey900" className={styles.note}>Note: Once submited you cannot change rating for this order.</Text>

        </Card>
      )}

      {status == 'found' && data && (
        <Card color="grey" className={styles.card}>
          <H2 className={styles.heading}>{title}</H2>
          <Text color="grey900" className={styles.note}>You cannot change the rating for this order.</Text>
        </Card>
      )}

      {status == 'not-found' && (
        <Card color="grey" className={styles.card}>
          <H2 className={styles.heading}>{title}</H2>
          <Text color="grey900" className={styles.note}>We couldn't found any delivered order with given reference ID.</Text>
        </Card>
      )}
    </div>
  );
}
