import React, { MouseEvent, useState } from 'react';

import { Alert } from '../../../components/Alert/Alert';
import { Button } from '../../../components/Button/Button';
import { H1 } from '../../../components/H1/H1';
import { Input } from '../../../components/Input/Input';
import { Link } from '../../../components/Link/Link';
import { Text } from '../../../components/Text/Text';
import * as api from '../../../services/api';
import styles from '../Account.module.scss';

export function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const reset = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setError(false);
    try {
      await api.csrf();
      await api.forgot(email);
      setSent(true);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <>
      <H1 alignment="center" className={styles.heading}>
        Forgot Password?
      </H1>
      <div className={styles.container}>
        {sent ? (
          <Alert className={styles.alert} type="success">
            Email successfully sent, please check your inbox. If you don't receive the email please double check your
            spam/junk folder.
          </Alert>
        ) : (
          <form>
            {error ? (
              <Alert className={styles.alert} type="error">
                There was an error sending your reset email. Please wait 5 minutes, refresh, and try again.
              </Alert>
            ) : (
              <Alert type="info">
                Enter the email address for your account and we’ll email you a link to reset your password.
              </Alert>
            )}
            <Input className={styles.input} name="email" label="Email" value={email} onChange={setEmail} errors={{}} />
            <div className={styles.toolbar}>
              <Text className={styles.text}>
                <Link className={styles.link} to="/account/login">
                  Back to login
                </Link>
              </Text>
              <Button onClick={reset} block className={styles.button}>
                Send reset link
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
