import classNames from 'classnames';
import React, { ReactNode, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Button.module.scss';

export type Type = 'primary' | 'secondary' | 'ghost' | 'link';
type Props = {
  children: ReactNode;
  className?: string;
  href?: string;
  disabled?: boolean;
  block?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  rounding?: 'roundedSm' | 'roundedMd';
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  to?: string;
  type?: Type;
};

export function Button(props: Props) {
  const {
    children: _children,
    className,
    disabled = false,
    block = false,
    fullWidth = false,
    loading = false,
    onClick,
    size = 'large',
    rounding = 'roundedMd',
    href,
    to,
    type = 'primary',
  } = props;

  const _className = classNames(styles.root, className, styles[type], styles[size], styles[rounding], {
    [styles.block]: block,
    [styles.fullWidth]: fullWidth,
    [styles.disabled]: disabled,
  });

  const children = loading ? 'loading' : _children;

  if (href) {
    return (
      <a className={_className} href={href}>
        {children}
      </a>
    );
  } else if (to) {
    return (
      <NavLink className={_className} to={to}>
        {children}
      </NavLink>
    );
  } else {
    return (
      <button className={_className} onClick={onClick}>
        {children}
      </button>
    );
  }
}
