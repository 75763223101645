import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Reviews, useReviews } from '../../review';
import { RebookStatus } from '../../types';
import { Card } from '../../components/Card/Card';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import * as api from '../../services/api';
import { Panel } from '../../components/Panel/Panel';
import { Text } from '../../components/Text/Text';
import styles from './CourierReviews.module.scss';

import ReactStars from 'react-stars'

type Props = {
    className?: string;
};

export function CourierReviews(props: Props) {

    const [message, setMessage] = useState<string>();
    const [courier, setCourier] = useState<string>('');
    const [totalRatings, setTotalRating] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [status, setStatus] = useState<RebookStatus>('idle');
    const [title, setTitle] = useState<string>('');
    const [apiMessage, setApiMessage] = useState<string>('');

    const [sortby, setSortby] = useState<string>('top');

    const [reviewLists, setReviewList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setStatus('in-progress');
            try {
                const response = await api.get<any>(`/couriers/reviews?sortby=${sortby}`);
                setStatus('idle');
                if (response.status) {
                    setStatus('found');

                    setLastPage(response.data.last_page);
                    setCurrentPage(response.data.current_page);
                    setReviewList(response.data.data);// array
                    console.log(response);

                } else {
                    setStatus('not-found')
                    setMessage(response.message);
                }

                setTitle(response.message)

            } catch (e) {
                setStatus('idle');
                setApiMessage("Something went wrong!");
            }
        }
        fetchData();
    }, ['']);

    async function switchPage(page: number, sortby: string) {
        setStatus('in-progress');
        setSortby(sortby)
        try {
            const response = await api.get<any>(`/couriers/reviews?page=${page}&sortby=${sortby}`);
            setStatus('idle');
            if (response.status) {
                setStatus('found');

                setLastPage(response.data.last_page);
                setCurrentPage(response.data.current_page);
                setReviewList(response.data.data);// array

            } else {
                setStatus('not-found')
                setMessage(response.message);
            }

            setTitle(response.message)

        } catch (e) {
            setStatus('idle');
            setApiMessage("Something went wrong!");
        }
    }

    return (
        <div className={styles.root}>
            <OverlaySpinner enabled={status === 'in-progress'} />
            {apiMessage && (
                <Card className={styles.results} color="grey">
                    <Panel>
                        <Text className={styles.text}>{apiMessage}</Text>
                    </Panel>
                </Card>
            )}


            {status == 'found' && (
                <div className={styles.feedbackRow_s}>
                    <h2 className={styles.courierName}>Courier Reviews</h2>
                    <select className={styles.SelectInput_s} defaultValue={sortby} onChange={e => switchPage(1, e.target.value)}>
                        <option value="top">Top Reviews</option>
                        <option value="new">Newest</option>
                        <option value="old">Oldest</option>
                    </select>
                </div>
            )}

            {reviewLists.map((xyz: any) => (

                <div>
                    <div className={styles.dividerMain}>
                        <div className={styles.divider_s}></div>
                        <div className={styles.feedbackRowSecond}>
                            <div className={styles.dividerUserInfo}>
                                <div className={styles.reviewDiv}>
                                    <div className={styles.feedbackRow_s}>
                                        <div className={styles.row_s}>
                                            <div className={styles.cellContent_s}>
                                                <div className={styles.cell_user}>
                                                    <div className={styles.circle_s}>
                                                        <h4 className={styles.verticalCenter}>{xyz.user_name.charAt(0)}</h4>
                                                    </div>

                                                </div>
                                                <h4 className={styles.userName}>{xyz.user_name}</h4>

                                                <div className={styles.row_s}>


                                                    <div className={styles.cell_user}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.dividerCourierInfo}>
                                <div className={styles.reviewDivMain}>
                                    <div className={styles.feedbackRowSecond}>
                                        <div className={styles.rowSecond}>
                                            <div className={styles.cellContentSecond}>
                                                <ReactStars
                                                    className={styles.ratingbar}
                                                    count={5}
                                                    value={xyz.rating}
                                                    size={24}
                                                    half={true}
                                                    edit={false}
                                                    color2={'#f76b00'} />
                                                    {xyz.review && (
                                                        <p className={styles.review}>{xyz.review}</p>
                                                    )}
                                                <div className={styles.rowImage}>
                                                    <div className={styles.cell_user}>
                                                        <img className={styles.logoReview} alt={courier} src={xyz.courier_image} />

                                                    </div>
                                                    <div className={styles.cellTiming}>
                                                        <p>{xyz.elapsed_time}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {lastPage > 1 && (
                <div className={styles.pagination_s}>
                    <div className={styles.divider_s}></div>
                    <div className={styles.row_s}>

                        {currentPage > 1 && (
                            <div className={styles.cell_s}>
                                <Button type="submit" onClick={() => { switchPage(currentPage - 1, sortby); }} className={styles.customOrderSubmitBtn}>Prev</Button>
                            </div>
                        )}

                        {currentPage == 1 && (
                            <div className={styles.cell_s}>
                                <p className={styles.customOrderSubmitBtnSelected}>Prev</p>
                            </div>
                        )}

                        {currentPage == 1 && (
                            <div className={styles.cell_s}>
                                <p className={styles.customOrderSubmitBtnSelected}>1</p>
                            </div>
                        )}

                        {(currentPage - 1) > 0 && (
                            <div className={styles.cell_s}>
                                <Button type="submit" onClick={() => { switchPage(currentPage - 1, sortby); }} className={styles.customOrderSubmitBtn}>{currentPage - 1}</Button>
                            </div>
                        )}

                        {currentPage != 1 && currentPage != lastPage && (
                            <div className={styles.cell_s}>
                                <p className={styles.customOrderSubmitBtnSelected}>{currentPage}</p>
                            </div>
                        )}

                        {(currentPage + 1) <= lastPage && (
                            <div className={styles.cell_s}>
                                <Button type="submit" onClick={() => { switchPage(currentPage + 1, sortby); }} className={styles.customOrderSubmitBtn}>{currentPage + 1}</Button>
                            </div>
                        )}

                        {currentPage == lastPage && (
                            <div className={styles.cell_s}>
                                <p className={styles.customOrderSubmitBtnSelected}>{lastPage}</p>
                            </div>
                        )}

                        {currentPage == lastPage && (
                            <div className={styles.cell_s}>
                                <p className={styles.customOrderSubmitBtnSelected}>Next</p>
                            </div>
                        )}

                        {currentPage < lastPage && (
                            <div className={styles.cell_s}>
                                <Button type="submit" onClick={() => { switchPage(currentPage + 1, sortby); }} className={styles.customOrderSubmitBtn}>Next</Button>
                            </div>
                        )}

                    </div>
                </div>
            )}

        </div >
    );
}
