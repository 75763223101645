import React, { MouseEvent, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { Input } from '../../components/Input/Input';
import { OverlaySpinner } from '../../components/OverlaySpinner/OverlaySpinner';
import { Text } from '../../components/Text/Text';
import { Panel } from '../../components/Panel/Panel';
import styles from './Insurance.module.scss';
import * as api from '../../services/api';
import { ApiStatus, RebookStatus, ValidationErrors, ClaimType, ClaimTypes, ConfirmType, ConfirmTypes } from '../../types';
import { Select } from '../../components/Select/Select';

type Props = {
  className?: string;
};

export function Insurance(props: Props) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [status, setStatus] = useState<RebookStatus>('idle');
  const [consignmentCode, setConsignmentCode] = useState<string>('');
  const [claimType, setClaimType] = useState<ClaimType>('damage');
  const [confirmType, setConfirmType] = useState<ConfirmType>('yes');
  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');

  const [apiMessage, setApiMessage] = useState<string>('');

  const submit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

      setStatus('in-progress');
      try {
        await api.csrf();
        const response = await api.post<any>(`/contact/insurance-claim`, {
          consignmentCode: consignmentCode,
          type: claimType,
          bookingParty: confirmType,
          contactName: contactName,
          contactEmail: contactEmail,
        });
        setStatus('idle');
        if(response.status){
          setConsignmentCode('');
          setContactName('');
          setContactEmail('');
        }
        setApiMessage(response.message);
      } catch (e) {
        setStatus('idle');
        setApiMessage("Something went wrong!");
      }
  };

  return (
    <div className={styles.root}>
      <OverlaySpinner enabled={status === 'in-progress'} />

      {apiMessage && (
        <Card className={styles.results} color="grey">
          <Panel>
            <Text className={styles.text}>{apiMessage}</Text>
          </Panel>
        </Card>
      )}

      {status === 'idle' && (
        <div>
          <div>
            
            <Input
              className={styles.input}
              label="Consignment number / Reference ID"
              name="consignmentCode"
              value={consignmentCode}
              onChange={setConsignmentCode}
              errors={errors}
              required
            />

            <Select<ClaimType>
              className={styles.input}
              label="Type of claim"
              onChange={(value) => setClaimType(value)}
              options={ClaimTypes}
              value={claimType}
            />

            <Select<ConfirmType>
              className={styles.input}
              label="Are you the booking party?"
              onChange={(value) => setConfirmType(value)}
              options={ConfirmTypes}
              value={confirmType}
            />

        <Input
          className={styles.input}
          label="Contact Name"
          name="contactName"
          value={contactName}
          onChange={setContactName}
          errors={errors}
          required
        />

        <Input
          className={styles.input}
          label="Contact email address"
          name="contactEmail"
          value={contactEmail}
          onChange={setContactEmail}
          errors={errors}
          required
        />

          </div>
          <div className={styles.toolbar} >
            <Button type="primary" onClick={submit}>Submit</Button>
          </div>
        </div>
      )}

    </div>
  );
}
