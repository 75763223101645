import React, { MouseEvent, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Alert } from '../../../components/Alert/Alert';
import { Button } from '../../../components/Button/Button';
import { H1 } from '../../../components/H1/H1';
import { Input } from '../../../components/Input/Input';
import { Link } from '../../../components/Link/Link';
import { Text } from '../../../components/Text/Text';
import * as api from '../../../services/api';
import styles from '../Account.module.scss';

export function ResetPassword() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const match = useRouteMatch<{ token: string }>();
  const location = useLocation();
  const history = useHistory();
  const email = new URLSearchParams(location.search).get('email');
  const token = match.params.token;

  const reset = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setError(false);
    try {
      await api.csrf();
      await api.reset(email!, password, token);
      setSuccess(true);
      setTimeout(() => {
        history.push('/account/login');
      }, 3000);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <>
      <H1 alignment="center" className={styles.heading}>
        Reset Password
      </H1>
      <div className={styles.container}>
        {success ? (
          <Alert className={styles.alert} type="success">
            You have successfully reset your password. Redirecting you to the login page
          </Alert>
        ) : (
          <form className={styles.form}>
            <Alert className={styles.alert} type="info">
              Please enter your new password
            </Alert>
            <Input
              className={styles.input}
              label="Password"
              name="password"
              value={password}
              onChange={setPassword}
              type="password"
              errors={{}}
            />
            {error && (
              <Alert className={styles.alert} type="error">
                The password reset has expired or is invalid. Please request a new reset email from the forgot password
                page
              </Alert>
            )}
            <div className={styles.toolbar}>
              <Text className={styles.text}>
                Return to
                <Link className={styles.link} to="/account/login">
                  Log in
                </Link>
              </Text>
              <Button onClick={reset} block className={styles.button}>
                Reset password
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
