import React, { MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Alert } from '../../../components/Alert/Alert';
import { Button } from '../../../components/Button/Button';
import { H1 } from '../../../components/H1/H1';
import { Input } from '../../../components/Input/Input';
import { Link } from '../../../components/Link/Link';
import { OverlaySpinner } from '../../../components/OverlaySpinner/OverlaySpinner';
import { Status } from '../../../types';
import { Text } from '../../../components/Text/Text';
import * as api from '../../../services/api';
import styles from '../Account.module.scss';

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState<Status>('idle');
  const history = useHistory();

  const login = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setStatus('in-progress');
    try {
      await api.csrf();
      await api.login(email, password);
      history.push('/shipment-details');
    } catch (e) {
      setStatus('error');
    }
  };

  return (
    <>
      <OverlaySpinner enabled={status === 'in-progress'} />
      <H1 alignment="center" className={styles.heading}>
        Log in
      </H1>
      <div className={styles.container}>
        <form>
          <Input className={styles.input} name="email" label="Email" value={email} onChange={setEmail} errors={{}} />
          <Input
            className={styles.input}
            label="Password"
            value={password}
            onChange={setPassword}
            name="password"
            type="password"
            errors={{}}
          />
          <Link className={styles.forgot} to="/account/forgot-password">
            Forgot password?
          </Link>
          {status === 'error' && (
            <Alert className={styles.alert} type="error">
              These credentials do not match our records.
            </Alert>
          )}
          <div className={styles.toolbar}>
            <Text>
              Need an account?
              <Link className={styles.link} to="/account/register">
                Register
              </Link>
            </Text>
            <Button onClick={login} block className={styles.button}>
              Log in
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
